/* eslint-disable no-lone-blocks */
/* eslint-disable guard-for-in */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { ReportDisplay } from 'models/reportDisplay'
import { FHIRApiClient } from 'services/fhirApiServices'
import print from 'print-js'
import {
  getAgeOfPatient,
  getComponentFromCoding,
  getComponentFromMentalCodingForMental,
  getComponentVal,
  getComponentValMental,
  getFamilyCondition,
  getFullNameOfPatient,
  getIdentifierValueBySystem,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameOfPatient,
  getAgeOfPatientData,
  getStringValueExtensionsOfUrl,
  getDefaultCodeOfSystemFromCodableConcept,
} from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'
import { getCurrentUserOrgDetails } from 'redux/administration/orgSetUp/orgProfileCompletionSlice/orgProfileCompletionSlice'
import { getCurrentUserUnitReference } from 'services/userDetailsService'
import moment from 'moment'
import {
  AllergyDetails,
  Assessment,
  CaseStudy,
  Diet,
  DietCaseSheet,
  FamilyHistory,
  GeneralExam,
  GroupedFamilyHistoryByRelation,
  GroupedTreatment,
  Medication,
  MensuralHistory,
  Objective,
  ObstetricHistory,
  PastCondition,
  PresentCondition,
  Subjective,
  Treatment,
  TreatmentDetails,
  Vitals,
} from 'models/caseStudy/presetComplaints'
import { ReportClient } from 'services/reportClient'
import { WelloMedication } from 'models/welloMedication'
import {
  getUnitOfDosageFromRequest,
  getWelloMedicationsFromBundleResponsesFromStatement,
} from 'utils/appointment_handle/medications_util'
import { DietForCase } from 'models/diet'
import { IServiceRequest } from '@ahryman40k/ts-fhir-types/lib/R4'
import { GroupedOccupation } from 'models/groupedOccupations'
import {
  getSortedObservationForGeneralExam,
  getSortedObservationForIpd,
} from 'utils/appointment_handle/vitals_util'
import { Print, PrintFormat } from 'models/fhirClinicIpdDetails'
import {
  getObservationContentUnit,
  getObservationContentValue,
  getObservationContentValueDiaComp,
  getObservationContentValueSysComp,
  getRelationFromFamilyResource,
} from './observationHelpers'
import {
  getDietPlansPlanFromBundle,
  getDietPlansPlanFromBundleForCase,
} from './ipdDietHelper'
import { getDietName } from './catalogHelper'
import { getRoomNoForSheet } from './ipdAppointmentHelper'
import {
  getLatestObservations,
  getSortedObservationForAddiction,
  getSortedObservationForHabit,
} from './ipdObservationHelper'

export const getDiagnosticReportsHelper = (
  inputBundle: R4.IBundle
): ReportDisplay[] | undefined => {
  logger.info(
    '-------------------Inside distillation function------------------------'
  )
  const reportDisplay: ReportDisplay[] = []
  const diagnosticReport: any = {}
  const patient: any = {}
  const practitioners: Map<string, R4.IPractitioner> = new Map<
    string,
    R4.IPractitioner
  >()

  if (inputBundle.total) {
    if (inputBundle.total > 0) {
      if (inputBundle.entry) {
        const entries: R4.IBundle_Entry[] = inputBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'DiagnosticReport':
                  diagnosticReport[value.resource.id] =
                    value.resource as R4.IDiagnosticReport
                  break
                case 'Patient':
                  patient[value.resource.id] = value.resource as R4.IPatient
                  logger.info('patient', patient)
                  break

                default:
                  break
              }
            }
          }
        })
        logger.info(
          '-------------------Inside distillation function middle------------------------'
        )
        if (diagnosticReport) {
          for (const key in diagnosticReport) {
            if (key) {
              const currentReport: R4.IDiagnosticReport = diagnosticReport[key]
              let reportName: string = ''
              if (currentReport.basedOn === undefined) {
                if (currentReport.code.coding) {
                  const codingData = currentReport.code.coding[0]
                  if (codingData) {
                    reportName = codingData.display ?? ''
                  }
                }

                const patientId =
                  currentReport.subject?.reference?.split('/')[1] ?? ''
                const patientData: R4.IPatient = patient[patientId ?? '']

                reportDisplay.push({
                  id: currentReport.id ?? '',
                  age: getAgeOfPatient(patientData),
                  fullName: ` ${getNameOfPatient(
                    patientData
                  )} ${getMiddleNameOfPatient(
                    patientData
                  )} ${getLastNameOfPatient(patientData)}`,
                  reportLink: reportName,
                  testName: reportName,
                  diagnosticReport: currentReport,
                  patient: patientData,
                })
              }
            }
          }
          return reportDisplay
        }
      }
    }
  }
  logger.info(
    '-------------------Inside distillation function error------------------------'
  )
  return undefined
}

export async function getTreatmentDetailsReport(
  gender: string,
  forDate: string
) {
  const enRolClient: FHIRApiClient = new FHIRApiClient()
  try {
    const response: any = await enRolClient.doCreateFHIRTransaction(
      `/$generate-treatment-card`,
      {
        resourceType: 'Parameters',
        parameter: [
          {
            name: 'start-date',
            valueString: moment(forDate).startOf('day').utc().format(),
          },
          {
            name: 'end-date',
            valueString: moment(forDate).endOf('day').utc().format(),
          },
          {
            name: 'unit-org-reference',
            valueString: getCurrentUserUnitReference()?.reference,
          },
          {
            name: 'gender',
            valueString: gender,
          },
        ],
      }
    )
    let data: string = ''
    if (response.parameter) {
      const dataIndex = (response.parameter as any[]).findIndex(
        (e) => e.name === 'treatment-card'
      )

      if (dataIndex > -1) {
        data = response.parameter[dataIndex].valueString ?? ''
      }
    }

    const byteCharacters = atob(data)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const file = new Blob([byteArray], { type: 'application/pdf;base64' })
    const fileURL = URL.createObjectURL(file)
    print({
      printable: fileURL,
      type: 'pdf',
      showModal: true,
    })
    return true
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}

export async function getDietDetailsReport(forDate: string) {
  const enRolClient: FHIRApiClient = new FHIRApiClient()
  try {
    const response: any = await enRolClient.doCreateFHIRTransaction(
      `/$generate-diet-card`,
      {
        resourceType: 'Parameters',
        parameter: [
          {
            name: 'start-date',
            valueString: moment(forDate).startOf('day').utc().format(),
          },
          {
            name: 'end-date',
            valueString: moment(forDate).endOf('day').utc().format(),
          },
          {
            name: 'unit-org-reference',
            valueString: getCurrentUserUnitReference()?.reference,
          },
        ],
      }
    )
    let data: string = ''
    if (response.parameter) {
      const dataIndex = (response.parameter as any[]).findIndex(
        (e) => e.name === 'diet-card'
      )

      if (dataIndex > -1) {
        data = response.parameter[dataIndex].valueString ?? ''
      }
    }

    const byteCharacters = atob(data)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const file = new Blob([byteArray], { type: 'application/pdf;base64' })
    const fileURL = URL.createObjectURL(file)
    print({
      printable: fileURL,
      type: 'pdf',
      showModal: true,
    })
    return true
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}

export async function getDischargeDetailsReport(id: string) {
  const enRolClient: FHIRApiClient = new FHIRApiClient()
  try {
    const response: any = await enRolClient.doCreateFHIRTransaction(
      `/$generate-discharge-summary`,
      {
        resourceType: 'Parameters',
        parameter: [
          {
            name: 'service-request-ref',
            valueString: `ServiceRequest/${id}`,
          },
        ],
      }
    )
    let data: string = ''
    if (response.parameter) {
      const dataIndex = (response.parameter as any[]).findIndex(
        (e) => e.name === 'discharge-summary'
      )

      if (dataIndex > -1) {
        data = response.parameter[dataIndex].valueString ?? ''
      }
    }

    const byteCharacters = atob(data)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const file = new Blob([byteArray], { type: 'application/pdf;base64' })
    const fileURL = URL.createObjectURL(file)
    print({
      printable: fileURL,
      type: 'pdf',
      showModal: true,
    })
    return true
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}

export async function getDietReportOfVisit(serviceRequestId: string) {
  const enRolClient: FHIRApiClient = new FHIRApiClient()
  try {
    const response: any = await enRolClient.doCreateFHIRTransaction(
      `/$generate-diet-report`,
      {
        resourceType: 'Parameters',
        parameter: [
          {
            name: 'service-request-ref',
            valueString: `ServiceRequest/${serviceRequestId}`,
          },
        ],
      }
    )
    let data: string = ''
    if (response.parameter) {
      const dataIndex = (response.parameter as any[]).findIndex(
        (e) => e.name === 'patient_diet_report'
      )

      if (dataIndex > -1) {
        data = response.parameter[dataIndex].valueString ?? ''
      }
    }

    const byteCharacters = atob(data)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const file = new Blob([byteArray], { type: 'application/pdf;base64' })
    const fileURL = URL.createObjectURL(file)
    print({
      printable: fileURL,
      type: 'pdf',
      showModal: true,
    })
    return true
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}

export async function getTreatmentReportOfVisit(serviceRequestId: string) {
  const enRolClient: FHIRApiClient = new FHIRApiClient()
  try {
    const response: any = await enRolClient.doCreateFHIRTransaction(
      `/$generate-treatment-report`,
      {
        resourceType: 'Parameters',
        parameter: [
          {
            name: 'service-request-ref',
            valueString: `ServiceRequest/${serviceRequestId}`,
          },
        ],
      }
    )
    let data: string = ''
    if (response.parameter) {
      const dataIndex = (response.parameter as any[]).findIndex(
        (e) => e.name === 'patient_treatment_report'
      )

      if (dataIndex > -1) {
        data = response.parameter[dataIndex].valueString ?? ''
      }
    }

    const byteCharacters = atob(data)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const file = new Blob([byteArray], { type: 'application/pdf;base64' })
    const fileURL = URL.createObjectURL(file)
    print({
      printable: fileURL,
      type: 'pdf',
      showModal: true,
    })
    return true
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}

export async function getCaseStudyData(
  ipdDetails: R4.IServiceRequest,
  serviceRequestId: string,
  encounterId: string,
  patient: R4.IPatient,
  location?: R4.ILocation
) {
  const serviceRequestData: R4.ICondition[] = []
  const prescriptionData: Subjective = {
    presentConditions: [],
    pastConditions: [],
    family: await getFamilyHistory(encounterId, serviceRequestId),
    diet: await getDietHistoryReport(encounterId, serviceRequestId, '41829006'),
    occupation: await getDietHistory(encounterId, serviceRequestId, '21843-8'),
    mensural: '',
    obs: await getDietHistory(encounterId, serviceRequestId, '408470005'),
    habits: await getHabitData(encounterId, serviceRequestId),
    addiction: await getAddictionData(encounterId, serviceRequestId),
    stress: await getStressData(encounterId, serviceRequestId, '21843-8'),
    bowels: await getDietHistory(encounterId, serviceRequestId, '80263-7'),
    appetite: await getDietHistory(encounterId, serviceRequestId, '33911006'),
    micturition: await getDietHistory(
      encounterId,
      serviceRequestId,
      '252041008'
    ),
    sleep: await getSleepData(encounterId, serviceRequestId),
    mensuralHistory: await getMensurationHistory(encounterId, serviceRequestId),
    obsHistory: await getObsHistory(encounterId, serviceRequestId),
    allergyDetails: await getAllergyDetails(encounterId, serviceRequestId),
    medication: await getCurrentMedication(encounterId, serviceRequestId),
  }

  const objectiveDData: Objective = {
    exam: await getGeneralExamData(encounterId, serviceRequestId),
    vitalsData: await getAddedVitals(encounterId, serviceRequestId),
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    encounter: `Encounter/${encounterId}`,
    _sort: '_lastUpdated',
    category: 'chief-complaint',
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    '/Condition',
    serviceRequestId,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    for (let i = 0; i < response.entry.length; i++) {
      serviceRequestData.push(response.entry[i].resource as R4.ICondition)
    }
  }
  prescriptionData.presentConditions = getChiefConditions(serviceRequestData)
  prescriptionData.pastConditions = await getPastHistory(
    encounterId,
    serviceRequestId
  )

  const assessmentData: Assessment = {
    dietCaseSheets: await getDietData(
      ipdDetails,
      serviceRequestId,
      encounterId
    ),
    treatments: await getTherapyDetails(
      ipdDetails,
      serviceRequestId,
      encounterId
    ),
  }

  const name = `${getNameOfPatient(patient) ?? ''}`
  const middleName = getMiddleNameOfPatient(patient) ?? ''
  const lastName = getLastNameOfPatient(patient) ?? ''
  let finalName: string = ''
  if (middleName.length > 0) {
    finalName = `${name.trim()} ${middleName.trim()} ${lastName.trim()}`
  } else {
    finalName = `${name.trim()} ${lastName.trim()}`
  }

  const ageData = getAgeOfPatientData(patient)
  const gender =
    patient.gender === R4.PatientGenderKind._male
      ? 'Male'
      : patient.gender === R4.PatientGenderKind._female
      ? 'Female'
      : 'Unknown'

  const fhirReportClient: ReportClient = new ReportClient()
  const requestBody: CaseStudy = {
    subjective: prescriptionData,
    objective: objectiveDData,
    assessment: assessmentData,
    patientName: finalName,
    ipNo: getIdentifierValueBySystem(
      ipdDetails.identifier ?? [],
      'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
    ),
    age: `${ageData} / ${gender}`,
    room: getRoomNoForSheet(location!) ?? '',
    obsRequired: gender === 'Female',
    date: ipdDetails.occurrencePeriod
      ? ipdDetails.occurrencePeriod.start
        ? moment(ipdDetails.occurrencePeriod.start).format('DD-MM-YYYY')
        : ''
      : '',
    time: ipdDetails.occurrencePeriod
      ? ipdDetails.occurrencePeriod.start
        ? moment(ipdDetails.occurrencePeriod.start).format('hh:mm A')
        : ''
      : '',
  }

  console.log(requestBody)

  const responseReport: any =
    await fhirReportClient.doCreateFHIRResourceRequest(
      `api/pdf/template`,
      requestBody
    )
  const byteCharacters = atob(responseReport.data)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const file = new Blob([byteArray], { type: 'application/pdf;base64' })
  const fileURL = URL.createObjectURL(file)
  window.open(fileURL)
  return prescriptionData
}

async function getPastHistory(
  encounterId: string,
  serviceRequestId: string
): Promise<PastCondition[]> {
  const serviceRequestData: R4.ICondition[] = []
  const condition: PastCondition[] = []

  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    encounter: `Encounter/${encounterId}`,
    _sort: '_lastUpdated',
    category: 'problem-list-item,assessment-finding,encounter-diagnosis',
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    '/Condition',
    serviceRequestId,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    for (let i = 0; i < response.entry.length; i++) {
      serviceRequestData.push(response.entry[i].resource as R4.ICondition)
    }
  }

  if (serviceRequestData.length > 0) {
    serviceRequestData.forEach((eachAction) => {
      condition.push({
        condition: eachAction.code ? eachAction.code.text ?? '' : '',
      })
    })
  }

  return condition
}

async function getAllergyDetails(
  encounterId: string,
  serviceRequestId: string
): Promise<string> {
  const serviceRequestData: R4.IAllergyIntolerance[] = []
  const condition: string[] = []

  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    _sort: '_lastUpdated',
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    `/AllergyIntolerance?allergyIntolerance-encounter=Encounter/${encounterId}`,
    serviceRequestId,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    for (let i = 0; i < response.entry.length; i++) {
      serviceRequestData.push(
        response.entry[i].resource as R4.IAllergyIntolerance
      )
    }
  }

  if (serviceRequestData.length > 0) {
    serviceRequestData.forEach((eachAction) => {
      condition.push(eachAction.code ? eachAction.code.text ?? '' : '')
    })
  }

  return condition.join(', ')
}

async function getCurrentMedication(
  encounterId: string,
  serviceRequestId: string
): Promise<Medication[]> {
  let serviceRequestData: WelloMedication[] = []
  const condition: Medication[] = []

  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    _sort: '_lastUpdated',
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    `/MedicationStatement?context=Encounter/${encounterId}`,
    serviceRequestId,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    serviceRequestData =
      getWelloMedicationsFromBundleResponsesFromStatement(response)
  }

  if (serviceRequestData.length > 0) {
    serviceRequestData.forEach((e, i: number) => {
      console.log(e)
      condition.push({
        slNo: `${i + 1}`,
        name:
          e.medication && e.medication.code
            ? e.medication.code.text ?? ''
            : e.name && e.name.length > 0
            ? e.name
            : e.medicationCode
            ? e.medicationCode.display ?? ''
            : '',
        dosage:
          e.dosageQuantity && e.dosageQuantityType
            ? `${e.dosageQuantity} ${getUnitOfDosageFromRequest(
                e.dosageQuantityType
              )}`
            : `${e.dosageQuantity}`,
        timeOfTheDay: e.timeCodes && e.timeCodes ? e.timeCodes : '',
      })
    })
  }

  return condition
}

async function getDietHistory(
  encounterId: string,
  serviceRequestId: string,
  code: string
): Promise<string> {
  const serviceRequestData: R4.IObservation[] = []
  const condition: string[] = []

  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    category: 'social-history,survey',
    _count: 500,
    status: 'final',
    code,
    encounter: `Encounter/${encounterId}`,
    _sort: '-_lastUpdated',
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    '/Observation',
    serviceRequestId,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    for (let i = 0; i < response.entry.length; i++) {
      serviceRequestData.push(response.entry[i].resource as R4.IObservation)
    }
  }
  const finalObservations = getLatestObservations(serviceRequestData)
  if (finalObservations.length > 0) {
    const eachAction = finalObservations[0]

    const valueCodeableConceptData = eachAction.valueCodeableConcept
    if (valueCodeableConceptData) {
      condition.push(valueCodeableConceptData.text ?? '')
    } else {
      condition.push(getObservationContent(eachAction))
    }
  }

  return condition.join(', ')
}

async function getDietHistoryReport(
  encounterId: string,
  serviceRequestId: string,
  code: string
): Promise<string> {
  const serviceRequestData: R4.IObservation[] = []
  const condition: string[] = []

  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    category: 'social-history,survey',
    _count: 20,
    status: 'final,preliminary',
    code: '41829006',
    encounter: `Encounter/${encounterId}`,
    _sort: '-_lastUpdated',
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    '/Observation',
    serviceRequestId,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    for (let i = 0; i < response.entry.length; i++) {
      serviceRequestData.push(response.entry[i].resource as R4.IObservation)
    }
  }
  const finalObservations = getLatestObservations(serviceRequestData)
  if (finalObservations.length > 0) {
    const valueCodeableConceptData = finalObservations[0].valueCodeableConcept
    if (valueCodeableConceptData) {
      condition.push(valueCodeableConceptData.text ?? '')
    } else {
      condition.push(getObservationContent(finalObservations[0]))
    }
  }

  return condition.join(', ')
}

async function getStressData(
  encounterId: string,
  serviceRequestId: string,
  code: string
): Promise<string> {
  const serviceRequestData: R4.IObservation[] = []
  const condition: string[] = []

  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    category: 'social-history,survey',
    _count: 500,
    status: 'final',
    code,
    encounter: `Encounter/${encounterId}`,
    _sort: '-_lastUpdated',
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    '/Observation',
    serviceRequestId,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    for (let i = 0; i < response.entry.length; i++) {
      serviceRequestData.push(response.entry[i].resource as R4.IObservation)
    }
  }

  const finalObservations = getLatestObservations(serviceRequestData)

  if (finalObservations.length > 0) {
    const eachAction = finalObservations[0]

    if (
      getComponentFromMentalCodingForMental(
        eachAction.component ?? [],
        '405052004'
      )
    ) {
      condition.push(
        `Mental Stress - ${getComponentValMental(
          eachAction.component ?? [],
          '405052004'
        )} / 10`
      )
    }
    if (getComponentFromCoding(eachAction.component ?? [], '55539008')) {
      condition.push(
        `Physical Stress Level - ${getComponentVal(
          eachAction.component ?? [],
          '55539008'
        )} / 10`
      )
    }
  }

  return condition.join(', ')
}

async function getHabitData(
  encounterId: string,
  serviceRequestId: string
): Promise<string> {
  const serviceRequestData: R4.IObservation[] = []
  const condition: string[] = []

  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    category: 'activity',
    _count: 500,
    status: 'final',
    code: '74009-2,74008-4,10000-1,65515-9,28148-5,10000-2,65968-0,65561-3',
    encounter: `Encounter/${encounterId}`,
    _sort: '-_lastUpdated',
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    '/Observation',
    serviceRequestId,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    for (let i = 0; i < response.entry.length; i++) {
      serviceRequestData.push(response.entry[i].resource as R4.IObservation)
    }
  }

  const finalObservations = getLatestObservations(serviceRequestData)
  const finalHabitData = getSortedObservationForHabit(finalObservations)

  if (finalHabitData.length > 0) {
    finalHabitData.forEach((eachAction) => {
      const valueCodeableConceptData = eachAction.valueCodeableConcept
      const codeData = eachAction.code.coding ?? []
      if (valueCodeableConceptData) {
        if (codeData.length > 0) {
          if (codeData[0].code && codeData[0].code !== '65968-0') {
            if (codeData[0].code && codeData[0].code !== '65561-3') {
              condition.push(
                `${codeData[0].display ?? ''} : ${
                  valueCodeableConceptData.text ?? ''
                }`
              )
            }
          }
        } else {
          condition.push(`${valueCodeableConceptData.text ?? ''}`)
        }
      } else {
        condition.push(getObservationContent(eachAction))
      }
    })
  }

  return condition.join(', ')
}

async function getSleepData(
  encounterId: string,
  serviceRequestId: string
): Promise<string> {
  const serviceRequestData: R4.IObservation[] = []
  const condition: string[] = []

  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    category: 'activity',
    _count: 500,
    status: 'final',
    code: '65968-0,65561-3',
    encounter: `Encounter/${encounterId}`,
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    '/Observation',
    serviceRequestId,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    for (let i = 0; i < response.entry.length; i++) {
      serviceRequestData.push(response.entry[i].resource as R4.IObservation)
    }
  }
  const finalObservations = getLatestObservations(serviceRequestData)
  if (finalObservations.length > 0) {
    serviceRequestData.forEach((eachAction) => {
      const valueCodeableConceptData = eachAction.valueCodeableConcept
      const codeData = eachAction.code.coding ?? []
      if (valueCodeableConceptData) {
        if (codeData.length > 0) {
          if (codeData[0].code && codeData[0].code === '65968-0') {
            condition.push(`Day Time : ${valueCodeableConceptData.text ?? ''}`)
          }
          if (codeData[0].code && codeData[0].code === '65561-3') {
            condition.push(
              `Night Time : ${valueCodeableConceptData.text ?? ''}`
            )
          }
        } else {
          condition.push(`${valueCodeableConceptData.text ?? ''}`)
        }
      } else {
        condition.push(getObservationContent(eachAction))
      }
    })
  }

  return condition.join(', ')
}

async function getMensurationHistory(
  encounterId: string,
  serviceRequestId: string
): Promise<MensuralHistory> {
  const serviceRequestData: R4.IObservation[] = []
  const condition: string[] = []

  const mensHistory: MensuralHistory = {
    dateOfCycle: '',
    cycleInterval: '',
    mensuralFlow: '',
    cycleType: '',
    lengthCycle: '',
    characterOfBleeding: '',
    otherCharOfBleeding: '',
    menarche: '',
    menopausalStatus: '',
    menopausalSymptoms: '',
    otherSymptoms: '',
  }

  const mensDateData: string[] = []
  const cycleTypeData: string[] = []
  const cycleIntervalData: string[] = []
  const mensFlowData: string[] = []
  const cycleLength: string[] = []
  const charOfBleeding: string[] = []
  const otherBleeding: string[] = []
  const menarcheData: string[] = []
  const menopausalStatusData: string[] = []
  const menopausalSymptomsData: string[] = []
  const otherSymptoms: string[] = []

  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    _count: 10,
    status: 'final',
    code: 'C0025329',
    encounter: `Encounter/${encounterId}`,
    _sort: '-_lastUpdated',
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    '/Observation',
    serviceRequestId,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    for (let i = 0; i < response.entry.length; i++) {
      serviceRequestData.push(response.entry[i].resource as R4.IObservation)
    }
  }
  const finalObservations = getLatestObservations(serviceRequestData)
  if (finalObservations.length > 0) {
    const eachAction = finalObservations[0]

    const componentData = eachAction.component ?? []
    if (componentData.length > 0) {
      componentData.forEach((component) => {
        const codeData = component.code
        const codingData = codeData.coding ?? []
        if (codingData.length > 0) {
          const observation = codingData.filter(
            (o) => o.code && o.code === '21840007'
          )
          const cycleType = codingData.filter(
            (o) => o.code && o.code === '248965005'
          )
          const cycleInterval = codingData.filter(
            (o) => o.code && o.code === '161716008'
          )

          const cycleLengthArr = codingData.filter(
            (o) => o.code && o.code === '161715007'
          )

          const mensFlow = codingData.filter(
            (o) => o.code && o.code === '248970003'
          )

          const charBleeding = codingData.filter(
            (o) => o.code && o.code === '364312007'
          )

          const otherBleedingData = codingData.filter(
            (o) => o.code && o.code === 'LA25476-5'
          )

          const menarcheArray = codingData.filter(
            (o) => o.code && o.code === '20016009'
          )

          const menopausalStatusArray = codingData.filter(
            (o) => o.code && o.code === '276477006'
          )

          const otherMenopausal = codingData.filter(
            (o) => o.code && o.code === '74964007'
          )

          const symp = codingData.filter((o) => o.code && o.code === '21801002')

          if (symp.length > 0) {
            if (component.valueCodeableConcept) {
              menopausalSymptomsData.push(
                component.valueCodeableConcept.text ?? ''
              )
            }
          }

          if (otherMenopausal.length > 0) {
            if (component.valueCodeableConcept) {
              otherSymptoms.push(component.valueCodeableConcept.text ?? '')
            }
          }

          if (menopausalStatusArray.length > 0) {
            if (component.valueCodeableConcept) {
              menopausalStatusData.push(
                component.valueCodeableConcept.text ?? ''
              )
            }
          }

          if (menarcheArray.length > 0) {
            if (component.valueQuantity) {
              menarcheData.push(
                `${component.valueQuantity.value ?? ''} ${
                  component.valueQuantity.unit ?? ''
                } `
              )
            }
          }

          if (otherBleedingData.length > 0) {
            if (component.valueString) {
              otherBleeding.push(component.valueString)
            }
          }

          if (observation.length > 0) {
            if (component.valueDateTime) {
              mensDateData.push(
                moment(component.valueDateTime).format('DD-MM-YYYY')
              )
            }
          }
          if (cycleType.length > 0) {
            if (component.valueCodeableConcept) {
              cycleTypeData.push(component.valueCodeableConcept.text ?? '')
            }
          }
          if (cycleInterval.length > 0) {
            if (component.valueCodeableConcept) {
              cycleIntervalData.push(component.valueCodeableConcept.text ?? '')
            }
          }

          if (cycleLengthArr.length > 0) {
            if (component.valueQuantity) {
              cycleLength.push(
                `${component.valueQuantity.value ?? ''} ${
                  component.valueQuantity.unit ?? ''
                } `
              )
            }
          }

          if (mensFlow.length > 0) {
            if (component.valueCodeableConcept) {
              mensFlowData.push(component.valueCodeableConcept.text ?? '')
            }
          }

          if (charBleeding.length > 0) {
            if (component.valueCodeableConcept) {
              charOfBleeding.push(component.valueCodeableConcept.text ?? '')
            }
          }
        }
      })
    }
  }

  mensHistory.dateOfCycle = mensDateData.join(', ')
  mensHistory.cycleType = cycleTypeData.join(', ')
  mensHistory.cycleInterval = cycleIntervalData.join(', ')
  mensHistory.lengthCycle = cycleLength.join(', ')
  mensHistory.mensuralFlow = mensFlowData.join(', ')
  mensHistory.characterOfBleeding = charOfBleeding.join(', ')
  mensHistory.otherCharOfBleeding = otherBleeding.join(', ')
  mensHistory.menarche = menarcheData.join(', ')
  mensHistory.menopausalStatus = menopausalStatusData.join(', ')
  mensHistory.otherSymptoms = otherSymptoms.join(', ')
  mensHistory.menopausalSymptoms = menopausalSymptomsData.join(', ')
  return mensHistory
}

async function getFamilyHistory(
  encounterId: string,
  serviceRequestId: string
): Promise<GroupedFamilyHistoryByRelation[]> {
  const serviceRequestData: R4.IFamilyMemberHistory[] = []
  let groupedData: GroupedFamilyHistoryByRelation[] = []

  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    _count: 20,
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    `/FamilyMemberHistory?event-partOf=ServiceRequest/${serviceRequestId}`,
    serviceRequestId,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    for (let i = 0; i < response.entry.length; i++) {
      serviceRequestData.push(
        response.entry[i].resource as R4.IFamilyMemberHistory
      )
    }
  }

  if (serviceRequestData.length > 0) {
    serviceRequestData.forEach((eachAction) => {
      const relationData = getRelationFromFamilyResource(eachAction)

      if (relationData) {
        if (groupedData.length > 0) {
          for (let j = 0; j < groupedData.length; j++) {
            if (relationData === groupedData[j].relation) {
              groupedData[j].conditions.push(
                eachAction.condition
                  ? getFamilyCondition(eachAction.condition)
                  : ''
              )
            } else {
              groupedData.push({
                relation: relationData ?? '',
                conditions: [
                  eachAction.condition
                    ? getFamilyCondition(eachAction.condition)
                    : '',
                ],
              })
              break
            }
          }
        } else {
          groupedData.push({
            relation: relationData ?? '',
            conditions: [
              eachAction.condition
                ? getFamilyCondition(eachAction.condition)
                : '',
            ],
          })
        }
      }
    })
  }
  groupedData = groupedData.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.relation === value.relation)
  )

  const finalGroupedOccupationData: GroupedFamilyHistoryByRelation[] = []
  for (let i = 0; i < groupedData.length; i++) {
    finalGroupedOccupationData.push({
      relation: groupedData[i].relation,
      conditions: groupedData[i].conditions.filter(
        (value, index, self) => index === self.findIndex((t) => t === value)
      ),
    })
  }

  return finalGroupedOccupationData
}

async function getAddictionData(
  encounterId: string,
  serviceRequestId: string
): Promise<string> {
  const serviceRequestData: R4.IObservation[] = []
  const condition: string[] = []

  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    category: 'social-history,survey',
    _count: 20,

    code: '64218-1,96103-7,10000-3,68518-0',
    status: 'final,preliminary',
    encounter: `Encounter/${encounterId}`,
    _sort: '-_lastUpdated',
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    '/Observation',
    serviceRequestId,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    for (let i = 0; i < response.entry.length; i++) {
      serviceRequestData.push(response.entry[i].resource as R4.IObservation)
    }
  }
  const finalObservations = getLatestObservations(serviceRequestData)
  const groupedObs = getSortedObservationForAddiction(finalObservations)
  if (groupedObs.length > 0) {
    groupedObs.forEach((eachAction) => {
      const valueCodeableConceptData = eachAction.valueCodeableConcept
      const codeData = eachAction.code.coding ?? []
      if (valueCodeableConceptData) {
        if (codeData.length > 0) {
          condition.push(
            `${codeData[0].display ?? ''} : ${
              valueCodeableConceptData.text ?? ''
            }`
          )
        } else {
          condition.push(`${valueCodeableConceptData.text ?? ''}`)
        }
      } else {
        condition.push(getObservationContent(eachAction))
      }
    })
  }

  return condition.join(', ')
}

export async function getPoliceReportOfVisit(serviceRequestId: string) {
  const enRolClient: FHIRApiClient = new FHIRApiClient()
  try {
    const response: any = await enRolClient.doPostFHIRForFileDownLoad(
      `/$generate-police-verification-report`,
      {
        resourceType: 'Parameters',
        parameter: [
          {
            name: 'service-request-ref',
            valueString: `ServiceRequest/${serviceRequestId}`,
          },
        ],
      }
    )
    const file = response as Blob
    const fileURL = URL.createObjectURL(file)
    const newWindow = window.open(fileURL, '_blank')
    setTimeout(() => {
      newWindow!.document.title = 'my new title'
    }, 1000)
    return true
  } catch (error) {
    console.error(error)
  } finally {
  }
  return false
}

function getChiefConditions(conditions: R4.ICondition[]): PresentCondition[] {
  const prSelectedCondition: PresentCondition[] = []
  if (conditions.length > 0) {
    conditions.forEach((eachAction) => {
      let durationData: string = ''
      const onsetAgeData = eachAction.onsetAge
      if (onsetAgeData) {
        const unitData = onsetAgeData.unit ?? ''
        const val = onsetAgeData.value ?? 0
        if (unitData === 'd') {
          if (val > 1) {
            durationData = `${val} Days`
          } else {
            durationData = `${val} Day`
          }
        }
        if (unitData === 'wk') {
          if (val > 1) {
            durationData = `${val} Weeks`
          } else {
            durationData = `${val} Week`
          }
        }
        if (unitData === 'a') {
          if (val > 1) {
            durationData = `${val} Years`
          } else {
            durationData = `${val} Year`
          }
        }
        if (unitData === 'mo') {
          if (val > 1) {
            durationData = `${val} Months`
          } else {
            durationData = `${val} Months`
          }
        }
      } else if (eachAction.onsetDateTime) {
        durationData = `${moment(eachAction.onsetDateTime).format(
          'DD-MM-YYYY'
        )} Months`
      }

      prSelectedCondition.push({
        condition: eachAction.code ? eachAction.code.text ?? '' : '',
        duration: durationData,
      })
    })
  }

  return prSelectedCondition
}
function getObservationContent(eachAction: R4.IObservation): string {
  throw new Error('Function not implemented.')
}

async function getObsHistory(
  encounterId: string,
  serviceRequestId: string
): Promise<ObstetricHistory> {
  const serviceRequestData: R4.IObservation[] = []

  const mensHistory: ObstetricHistory = {
    sexuallYStatus: '',
    gravida: '',
    termPregnancies: '',
    preTermDelivery: '',
    parity: '',
    modeOfChildBirth: '',
    abortion: '',
    ecticPregnancy: '',
    useOfContraception: '',
  }

  const mensDateData: string[] = []
  const cycleTypeData: string[] = []
  const cycleIntervalData: string[] = []
  const mensFlowData: string[] = []
  const cycleLength: string[] = []
  const charOfBleeding: string[] = []
  const otherBleeding: string[] = []
  const menarcheData: string[] = []
  const menopausalStatusData: string[] = []
  const menopausalSymptomsData: string[] = []
  const otherSymptoms: string[] = []
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    _count: 10,
    status: 'final',
    code: 'C0425963',
    encounter: `Encounter/${encounterId}`,
    _sort: '-_lastUpdated',
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    '/Observation',
    serviceRequestId,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    for (let i = 0; i < response.entry.length; i++) {
      serviceRequestData.push(response.entry[i].resource as R4.IObservation)
    }
  }
  const finalObservations = getLatestObservations(serviceRequestData)
  if (finalObservations.length > 0) {
    const eachAction = finalObservations[0]

    const componentData = eachAction.component ?? []

    if (componentData.length > 0) {
      componentData.forEach((component) => {
        const codeData = component.code
        const codingData = codeData.coding ?? []
        if (codingData.length > 0) {
          const observation = codingData.filter(
            (o) => o.code && o.code === '228453005'
          )

          if (observation.length > 0) {
            if (component.valueCodeableConcept) {
              mensDateData.push(component.valueCodeableConcept.text ?? '')
            }
          }

          const cycleType = codingData.filter(
            (o) => o.code && o.code === '161732006'
          )
          if (cycleType.length > 0) {
            if (component.valueInteger) {
              cycleTypeData.push(`${component.valueInteger}`)
            }
          }

          const cycleInterval = codingData.filter(
            (o) => o.code && o.code === '23667007'
          )

          if (cycleInterval.length > 0) {
            if (component.valueInteger) {
              cycleIntervalData.push(`${component.valueInteger}`)
            }
          }

          const cycleLengthArr = codingData.filter(
            (o) => o.code && o.code === '698717006'
          )

          if (cycleLengthArr.length > 0) {
            if (component.valueInteger) {
              cycleLength.push(`${component.valueInteger}`)
            }
          }

          const mensFlow = codingData.filter(
            (o) => o.code && o.code === '364325004'
          )

          const charBleeding = codingData.filter(
            (o) => o.code && o.code === '360149000'
          )
          const abortion = codingData.filter(
            (o) => o.code && o.code === '363681007'
          )

          const preg = codingData.filter((o) => o.code && o.code === '34801009')

          const menopausalStatusArray = codingData.filter(
            (o) => o.code && o.code === '276477006'
          )

          const otherMenopausal = codingData.filter(
            (o) => o.code && o.code === '389095005'
          )

          const symp = codingData.filter((o) => o.code && o.code === '21801002')

          if (symp.length > 0) {
            if (component.valueCodeableConcept) {
              menopausalSymptomsData.push(
                component.valueCodeableConcept.text ?? ''
              )
            }
          }

          if (otherMenopausal.length > 0) {
            if (component.valueCodeableConcept) {
              otherSymptoms.push(component.valueCodeableConcept.text ?? '')
            }
          }

          if (menopausalStatusArray.length > 0) {
            if (component.valueCodeableConcept) {
              menopausalStatusData.push(
                component.valueCodeableConcept.text ?? ''
              )
            }
          }

          if (preg.length > 0) {
            if (component.valueInteger) {
              menarcheData.push(`${component.valueInteger}`)
            }
          }

          if (abortion.length > 0) {
            if (component.valueInteger) {
              otherBleeding.push(`${component.valueInteger}`)
            }
          }

          if (mensFlow.length > 0) {
            if (component.valueInteger) {
              mensFlowData.push(`${component.valueInteger}`)
            }
          }

          if (charBleeding.length > 0) {
            if (component.valueCodeableConcept) {
              charOfBleeding.push(component.valueCodeableConcept.text ?? '')
            }
          }
        }
      })
    }
  }
  mensHistory.sexuallYStatus = mensDateData.join(', ')
  mensHistory.gravida = cycleTypeData.join(', ')
  mensHistory.termPregnancies = cycleIntervalData.join(', ')
  mensHistory.preTermDelivery = cycleLength.join(', ')
  mensHistory.parity = mensFlowData.join(', ')
  mensHistory.abortion = otherBleeding.join(', ')
  mensHistory.modeOfChildBirth = charOfBleeding.join(', ')
  mensHistory.ecticPregnancy = menarcheData.join(', ')
  mensHistory.useOfContraception = otherSymptoms.join(', ')

  return mensHistory
}

async function getGeneralExamData(
  encounterId: string,
  serviceRequestId: string
): Promise<GeneralExam> {
  const serviceRequestData: R4.IObservation[] = []

  const mensHistory: GeneralExam = {
    pallor: '',
    icterus: '',
    cyanosis: '',
    edema: '',
    clubbing: '',
    obese: '',
    lymphNodes: '',
    malNourished: '',
    others: '',
  }

  const mensDateData: string[] = []
  const malNourished: string[] = []
  const cycleIntervalData: string[] = []
  const mensFlowData: string[] = []
  const cycleLength: string[] = []
  const charOfBleeding: string[] = []
  const otherBleeding: string[] = []
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    _count: 10,
    status: 'final',
    category: 'exam',
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    `/Observation?based-on=ServiceRequest/${serviceRequestId}`,
    serviceRequestId,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    for (let i = 0; i < response.entry.length; i++) {
      serviceRequestData.push(response.entry[i].resource as R4.IObservation)
    }
  }
  const finalObservations = getLatestObservations(serviceRequestData)
  const finalData = getSortedObservationForGeneralExam(finalObservations)
  if (finalData.length > 0) {
    finalData.forEach((eachAction) => {
      const componentData = eachAction.code.coding ?? []
      if (componentData.length > 0) {
        componentData.forEach((component) => {
          const codeData = component.code
          if (codeData && codeData.length > 0) {
            if (codeData === '1237486008') {
              const valC = eachAction.valueCodeableConcept
              if (valC) {
                const valCoding = valC.coding ?? []
                if (valCoding.length > 0) {
                  mensDateData.push(valCoding[0].display ?? '')
                }
              }
            }

            if (codeData === '1759002') {
              const valC = eachAction.valueCodeableConcept
              if (valC) {
                const valCoding = valC.coding ?? []
                if (valCoding.length > 0) {
                  const code = valCoding[0].code ?? ''
                  if (code.length > 0 && code === '248325000')
                    malNourished.push('Yes')
                }
              }
            }

            if (codeData === '18165001') {
              const valC = eachAction.valueCodeableConcept
              if (valC) {
                const valCoding = valC.coding ?? []
                if (valCoding.length > 0) {
                  cycleIntervalData.push(valCoding[0].display ?? '')
                }
              }
            }

            if (codeData === '3415004') {
              const valC = eachAction.valueCodeableConcept
              if (valC) {
                const valCoding = valC.coding ?? []
                if (valCoding.length > 0) {
                  mensFlowData.push(valCoding[0].display ?? '')
                }
              }
            }
            if (codeData === '4373005') {
              const valC = eachAction.valueCodeableConcept
              if (valC) {
                const valCoding = valC.coding ?? []
                if (valCoding.length > 0) {
                  cycleLength.push(valCoding[0].display ?? '')
                }
              }
            }

            if (codeData === '30746006') {
              const valC = eachAction.valueCodeableConcept
              if (valC) {
                const valCoding = valC.coding ?? []
                if (valCoding.length > 0) {
                  charOfBleeding.push(valCoding[0].display ?? '')
                }
              }
            }

            if (codeData === '165184000') {
              const valC = eachAction.valueCodeableConcept
              if (valC) {
                const valCoding = valC.coding ?? []
                if (valCoding.length > 0) {
                  const code = valCoding[0].code ?? ''
                  if (code.length > 0 && code === '271590003')
                    otherBleeding.push('Yes')
                }
              }
            }
          }
        })
      }
    })
  }
  mensHistory.pallor = mensDateData.join(', ')
  mensHistory.malNourished = malNourished.join(', ')
  mensHistory.icterus = cycleIntervalData.join(', ')
  mensHistory.cyanosis = mensFlowData.join(', ')
  mensHistory.clubbing = cycleLength.join(', ')
  mensHistory.edema = ''
  mensHistory.lymphNodes = charOfBleeding.join(', ')
  mensHistory.obese = otherBleeding.join(',')
  mensHistory.others = ''
  return mensHistory
}

async function getAddedVitals(
  encounterId: string,
  serviceRequestId: string
): Promise<Vitals> {
  const serviceRequestData: R4.IObservation[] = []

  const mensHistory: Vitals = {
    temp: '',
    pulseRate: '',
    height: '',
    weight: '',
    rrRate: '',
    bmi: '',
    sysBp: '',
    diaBp: '',
  }

  const mensDateData: string[] = []
  const malNourished: string[] = []
  const cycleIntervalData: string[] = []
  const mensFlowData: string[] = []
  const cycleLength: string[] = []
  const charOfBleeding: string[] = []
  const otherBleeding: string[] = []
  const bmiArray: string[] = []
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    _count: 10,
    category: 'vital-signs',
    status: 'final',
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    `/Observation?based-on=ServiceRequest/${serviceRequestId}`,
    serviceRequestId,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    for (let i = 0; i < response.entry.length; i++) {
      serviceRequestData.push(response.entry[i].resource as R4.IObservation)
    }
  }
  const finalObservations = getLatestObservations(serviceRequestData)

  const finalData = getSortedObservationForIpd(finalObservations)
  if (finalData.length > 0) {
    finalData.forEach((eachAction) => {
      const componentData = eachAction.code.coding ?? []
      if (componentData.length > 0) {
        componentData.forEach((component) => {
          const codeData = component.code
          if (codeData && codeData.length > 0) {
            if (codeData === '8310-5') {
              mensDateData.push(
                `${getObservationContentValue(
                  eachAction
                )} ${getObservationContentUnit(eachAction)}`
              )
            }

            if (codeData === '8867-4') {
              malNourished.push(
                `${getObservationContentValue(
                  eachAction
                )} ${getObservationContentUnit(eachAction)}`
              )
            }

            if (codeData === '8302-2') {
              cycleIntervalData.push(
                `${getObservationContentValue(
                  eachAction
                )} ${getObservationContentUnit(eachAction)}`
              )
            }

            if (codeData === '29463-7') {
              mensFlowData.push(
                `${getObservationContentValue(
                  eachAction
                )} ${getObservationContentUnit(eachAction)}`
              )
            }
            if (codeData === '9279-1') {
              cycleLength.push(
                `${getObservationContentValue(
                  eachAction
                )} ${getObservationContentUnit(eachAction)}`
              )
            }

            if (codeData === '85354-9') {
              charOfBleeding.push(
                `${getObservationContentValueSysComp(
                  eachAction
                )} ${getObservationContentUnit(eachAction)}`
              )
            }

            if (codeData === '85354-9') {
              otherBleeding.push(
                `${getObservationContentValueDiaComp(
                  eachAction
                )} ${getObservationContentUnit(eachAction)}`
              )
            }
          }
        })
      }
    })
  }
  mensHistory.temp = mensDateData.join(', ')
  mensHistory.pulseRate = malNourished.join(', ')
  mensHistory.height = cycleIntervalData.join(', ')
  mensHistory.weight = mensFlowData.join(', ')
  mensHistory.rrRate = cycleLength.join(', ')
  mensHistory.sysBp = charOfBleeding.join(', ')
  mensHistory.diaBp = otherBleeding.join(', ')
  mensHistory.bmi = await getBmiData(encounterId, serviceRequestId)
  return mensHistory
}

async function getBmiData(
  encounterId: string,
  serviceRequestId: string
): Promise<string> {
  const serviceRequestData: R4.IObservation[] = []
  const condition: string[] = []

  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    _count: 10,
    status: 'final',
    code: '39156-5',
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    `/Observation?based-on=ServiceRequest/${serviceRequestId}`,
    serviceRequestId,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    for (let i = 0; i < response.entry.length; i++) {
      serviceRequestData.push(response.entry[i].resource as R4.IObservation)
    }
  }
  const finalObservations = getLatestObservations(serviceRequestData)
  const finalData = getSortedObservationForIpd(finalObservations)
  if (finalData.length > 0) {
    finalObservations.forEach((eachAction) => {
      condition.push(
        `${getObservationContentValue(eachAction)} ${getObservationContentUnit(
          eachAction
        )}`
      )
    })
  }

  return condition.join(', ')
}

async function getDietData(
  ipdDetails: R4.IServiceRequest,
  encounterId: string,
  serviceRequestId: string
): Promise<DietCaseSheet[]> {
  let serviceRequestData: DietForCase[] = []
  const condition: DietCaseSheet[] = []

  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    _count: 500,
    encounter: `Encounter/${serviceRequestId}`,
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    `/NutritionOrder?status=active,completed`,
    serviceRequestId,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    serviceRequestData = getDietPlansPlanFromBundleForCase(
      response,
      ipdDetails,
      encounterId,
      undefined
    )
  }
  if (serviceRequestData.length > 0) {
    serviceRequestData.forEach((eachAction) => {
      if (
        getDietName(eachAction.six ?? []).length > 0 ||
        getDietName(eachAction.eight ?? []).length > 0 ||
        getDietName(eachAction.twelve ?? []).length > 0 ||
        getDietName(eachAction.two ?? []).length > 0 ||
        getDietName(eachAction.five ?? []).length > 0 ||
        getDietName(eachAction.seven ?? []).length > 0 ||
        getDietName(eachAction.nine ?? []).length > 0
      )
        condition.push({
          id: eachAction.id,
          date: moment(eachAction.date).format('DD-MM-YYYY'),
          earlyMorning: getDietName(eachAction.six ?? []),
          breakfast: getDietName(eachAction.eight ?? []),
          midMorning: getDietName(eachAction.twelve ?? []),
          lunch: getDietName(eachAction.two ?? []),
          snack: getDietName(eachAction.five ?? []),
          dinner: getDietName(eachAction.seven ?? []),
          nine: getDietName(eachAction.nine ?? []),
        })
    })
  }

  return condition
}

async function getTherapyDetails(
  ipdDetails: R4.IServiceRequest,
  encounterId: string,
  serviceRequestId: string
): Promise<GroupedTreatment[]> {
  const serviceRequestData: IServiceRequest[] = []
  const finalTreatment: Treatment[] = []
  const finalGroupedData: GroupedTreatment[] = []
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    _count: 100,
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    `/ServiceRequest?_category=225365006&based-on=ServiceRequest/${ipdDetails.id!}&_sort=occurrence&status=active,completed`,
    '',
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    for (let i = 0; i < response.entry.length; i++) {
      serviceRequestData.push(response.entry[i].resource as R4.IServiceRequest)
    }
  }

  if (serviceRequestData.length > 0) {
    serviceRequestData.forEach((eachAction) => {
      const codingData = eachAction.code
      if (codingData) {
        const codingArray = codingData.coding ?? []
        if (codingArray.length > 0) {
          const treatMent: TreatmentDetails = {
            therapyName: codingArray[0].display ?? '',
          }
          const data = eachAction.occurrencePeriod
          if (data) {
            const dateDta = data.start ?? ''
            if (dateDta.length > 0) {
              const normalDate = `${moment(dateDta).format(
                'YYYY-MM-DD'
              )}T00:00:00.000Z`
              finalTreatment.push({
                date: normalDate,
                treatmentDetails: treatMent,
              })
            }
          }
        }
      }
    })
  }
  if (finalTreatment.length > 0) {
    if (finalTreatment.length > 0) {
      for (let i = 0; i < finalTreatment.length; i++) {
        if (finalGroupedData.length === 0) {
          if (finalTreatment[i].date) {
            finalGroupedData.push({
              date: finalTreatment[i].date,
              treatmentDetailsData: [finalTreatment[i].treatmentDetails],
            })
          }
        } else {
          const indexData = finalGroupedData.findIndex((x) =>
            moment(x.date).isSame(finalTreatment[i].date)
          )
          if (indexData > -1) {
            finalGroupedData[indexData].treatmentDetailsData.push(
              finalTreatment[i].treatmentDetails
            )
          } else {
            finalGroupedData.push({
              date: finalTreatment[i].date,
              treatmentDetailsData: [finalTreatment[i].treatmentDetails],
            })
          }
        }
      }
    }
  }
  const formattedData: GroupedTreatment[] = []

  if (finalGroupedData.length > 0) {
    finalGroupedData.forEach((eachAction) => {
      formattedData.push({
        date: moment(eachAction.date).format('DD-MM-YYY'),
        treatmentDetailsData: eachAction.treatmentDetailsData,
      })
    })
  }

  return formattedData
}

export async function getIPDExportedData(ipdDetails: Print): Promise<boolean> {
  const fhirReportClient: ReportClient = new ReportClient()

  const report: boolean = true

  const responseReport: any =
    await fhirReportClient.doCreateFHIRResourceRequest(
      `api/pdf/exportTemplate`,
      ipdDetails
    )
  const byteCharacters = atob(responseReport.data)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const file = new Blob([byteArray], { type: 'application/pdf;base64' })
  const fileURL = URL.createObjectURL(file)
  window.open(fileURL)
  return report
}
