import {
  Box,
  CircularProgress,
  Typography,
  useTheme,
  IconButton,
  Button,
} from '@material-ui/core'
import { Visibility } from '@material-ui/icons'

import { CPGForTable } from 'models/administration/cpgDetailsForTable'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  getCPSubscriptionsListForWPAdmin,
  getCPSubscriptionsOrgAndUnitAdmin,
  getOfferingCPSubscriptionsOrgAndUnitAdmin,
  offerSubscriptionInUnit,
  removeOfferSubscriptionInUnit,
} from 'utils/admisnistration/cpg/cpgListHelper'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from 'views/components/common/deleteConfirmationDialog'

import { kBackgroundDefault, kBackgroundPaper } from 'configs/styles/muiThemes'
import {
  getCrossFunctionInvitationsOfLab,
  getCrossFunctionInvitationsOfUnit,
  getInvitedMembersForTaskOfUnit,
  updateInvitationResponseForInvitation,
} from 'utils/admisnistration/crossFunctional/crossFunctinalUtils'
import { InvitedMemberForCareFunction } from 'models/administration/InvitedMemberDetail'
import { IncomingCrossFunctionInvitation } from 'models/administration/IncomingCrossFunctionInvitaion'

import { IncomingCareTeamInvitationList } from './incomingCrossFunctionalInvitationsTable'

interface Props {
  isReadonly?: boolean
}

export const IncomingCrossFunctionInvitationPage: React.FC<Props> = ({
  isReadonly: split,
}: Props) => {
  const [fetchStatus, setFetchStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const [updateInvitationResponse, setUpdateInvitationResponse] =
    useState<NetworkRequestStatus>({
      initial: true,
      requesting: false,
    })

  const [key, setKey] = useState<string>('initial')

  const [invitedMembers, setInvitedMembers] =
    useState<IncomingCrossFunctionInvitation[]>()

  function fetchIncomingInvitationList() {
    setFetchStatus({ requesting: true })
    getCrossFunctionInvitationsOfLab().then((e) => {
      if (e !== false) {
        setFetchStatus({
          requesting: false,
          requestSuccess: true,
        })
        setKey(Math.random().toString())
        setInvitedMembers(undefined)
        setInvitedMembers(e as IncomingCrossFunctionInvitation[])
      } else {
        setFetchStatus({
          requesting: false,
          requestSuccess: false,
        })
      }
    })
  }

  function updateCurrentInvitationResponse(
    invitationId: IncomingCrossFunctionInvitation,
    response: boolean
  ) {
    setUpdateInvitationResponse({ requesting: true })
    updateInvitationResponseForInvitation(invitationId, response).then((e) => {
      if (e !== false) {
        setUpdateInvitationResponse({
          requesting: false,
          requestSuccess: true,
        })
        fetchIncomingInvitationList()
      } else {
        setUpdateInvitationResponse({
          requesting: false,
          requestSuccess: false,
        })
      }
    })
  }

  useEffect(() => {
    fetchIncomingInvitationList()
  }, [])

  return (
    <Box
      display='flex'
      flexDirection='column'
      width='100%'
      height='100%'
      key={key}
    >
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        height='100%'
        bgcolor={kBackgroundPaper}
      >
        <Box
          display='flex'
          flexDirection='row'
          width='100%'
          height='100%'
          flexGrow={1}
          justifyContent='space-between'
        >
          {fetchStatus.requesting && (
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              pt={1}
              pb={1}
              justifyContent='center'
            >
              <CircularProgress />
            </Box>
          )}
          {!fetchStatus.requesting &&
            invitedMembers &&
            invitedMembers.length > 0 && (
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                flexGrow={1}
                pt={1}
                height='100%'
              >
                <IncomingCareTeamInvitationList
                  medicationsList={invitedMembers}
                  disableAction={updateInvitationResponse.requesting}
                  onAcceptClick={(e) => {
                    updateCurrentInvitationResponse(e, true)
                  }}
                  onRejectClick={(e) => {
                    updateCurrentInvitationResponse(e, false)
                  }}
                />
              </Box>
            )}
          {!fetchStatus.requesting &&
            (!invitedMembers || invitedMembers.length === 0) && (
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                flexGrow={1}
                pt={1}
                height='100%'
                justifyContent='center'
                alignItems='center'
              >
                <Typography>No invitations available</Typography>
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  )
}
