/* eslint-disable no-await-in-loop */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { IHealthcareService } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import _ from 'lodash'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import {
  CrossOrgSearchResponse,
  GroupedReferralService,
  LabOfferingDetail,
  LabOfferingDetailForReferral,
  LabReferralService,
  LabReferralServiceGrouped,
} from 'models/labOfferDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { LabOfferingSearchStatus } from 'redux/lab/offerings/labOfferingSearchHandler/labOfferingSearchStatusTypes'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserUnitDetails,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import { CURRENT_VISIT_REFERENCE } from 'utils/appConstants'
import {
  getValueNameValueExtensionsOfUrl,
  getValueRefValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'
import {
  getHvServiceForReferralList,
  getLabOfferingDetailsForReferral,
  getLabOfferingDetailsFromBundle,
} from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { logger } from 'utils/logger'
import { getLoincCodeFromPlanDef } from 'utils/patientHelper/cds_helper'
import { getHealthCareServices } from './referralLabOfferingSearchSlice'

const initialState: LabOfferingSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const referralLabOfferingSliceMultiple = createSlice({
  name: 'referralLabOfferingSliceMultiple',
  initialState,
  reducers: {
    searchingDoctorDetails(
      state,
      action: PayloadAction<LabOfferingSearchStatus>
    ) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = false

      state.labOfferings = undefined
      state.healthCareService = undefined
      state.groupedServices = undefined
    },

    searchResults(state, action: PayloadAction<LabOfferingSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = true
      state.healthCareService = action.payload.healthCareService
      state.labOfferings = action.payload.labOfferings
      state.groupedServices = action.payload.groupedServices
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<LabOfferingSearchStatus>
    ) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.healthCareService = undefined
      state.labOfferings = undefined
      state.groupedServices = undefined
    },

    errorWhileSearching(state, action: PayloadAction<LabOfferingSearchStatus>) {
      state.error = true
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false
      state.healthCareService = undefined
      state.labOfferings = undefined
      state.groupedServices = undefined
    },

    resetLabOffering(state, action: PayloadAction<LabOfferingSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.healthCareService = undefined
      state.labOfferings = undefined
      state.groupedServices = undefined
    },
  },
})

export const searchLabOfferingsMultiple =
  (
    preAddedLabTes: R4.IPlanDefinition[],
    // appointmentId: string,
    searchString?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: LabOfferingSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      referralLabOfferingSliceMultiple.actions.searchingDoctorDetails(state)
    )
    try {
      const enRolClient: EnrolCient = new EnrolCient()

      const visitContext = sessionStorage.getItem(CURRENT_VISIT_REFERENCE)
      if (visitContext !== null) {
        const response: any = await enRolClient.doGetResource(
          `cross-org-services/get-available-labs-for-units-for-tests?unitId=${getCurrentUserUnitDetails()
            .id!}&testLoincCodes[]=${searchString}`
        )

        if (response !== undefined) {
          const data = response as CrossOrgSearchResponse[]
          if (data.length > 0) {
            const finalService: LabReferralService[] = []
            const labOfferingList: LabOfferingDetailForReferral[] =
              getLabOfferingDetailsForReferral(data) ?? []
            for (let i = 0; i < labOfferingList.length; i++) {
              const unitArr: R4.IHealthcareService[] =
                await getHealthCareServices(
                  labOfferingList[i].planDefinition,
                  visitContext,
                  labOfferingList[i].org.id!
                )

              if (unitArr.length > 0) {
                finalService.push({
                  name: labOfferingList[i].org.name ?? '',
                  healthService: unitArr[0],
                  planDefinition: labOfferingList[i].planDefinition,
                })
              }
            }

            if (finalService.length > 0) {
              finalService.sort((a, b) =>
                (a.name ? (a.name.length > 0 ? a.name : '') : '').localeCompare(
                  b.name ? b.name : ''
                )
              )

              const finalGrouped: GroupedReferralService[] = []
              for (let i = 0; i < preAddedLabTes.length; i++) {
                const hvServiceArray: LabReferralServiceGrouped[] = []
                for (let j = 0; j < finalService.length; j++) {
                  if (
                    getLoincCodeFromPlanDef(preAddedLabTes[i]) ===
                    getLoincCodeFromPlanDef(finalService[j].planDefinition)
                  ) {
                    const providedByDetails =
                      finalService[j].healthService.providedBy
                    if (
                      providedByDetails &&
                      providedByDetails.reference &&
                      providedByDetails.reference.split('/').length > 0
                    ) {
                      hvServiceArray.push({
                        name: finalService[j].name,
                        healthService: finalService[j].healthService,
                      })
                    }
                  }
                }
                if (hvServiceArray.length > 0) {
                  hvServiceArray.push({
                    name: 'No Preference',
                    healthService: {
                      resourceType: 'HealthcareService',
                      name: 'No Preference',
                    },
                  })
                }

                finalGrouped.push({
                  planDef: preAddedLabTes[i],
                  healthService: hvServiceArray.sort((a, b) =>
                    (a.name
                      ? a.name.length > 0
                        ? a.name
                        : ''
                      : ''
                    ).localeCompare(b.name ? b.name : '')
                  ),
                })
              }
              console.log(
                finalGrouped.sort((a, b) =>
                  (a.planDef.title
                    ? a.planDef.title.length > 0
                      ? a.planDef.title
                      : ''
                    : ''
                  ).localeCompare(b.planDef.title ? b.planDef.title : '')
                )
              )

              state.labOfferings = labOfferingList
              state.groupedServices = finalGrouped
              state.healthCareService = finalService
              state.resultsAvailable = true
              state.searching = false
              state.error = false
              dispatch(
                referralLabOfferingSliceMultiple.actions.searchResults(state)
              )
              return
            }
            const finalGroupedExtra: GroupedReferralService[] = []
            for (let i = 0; i < preAddedLabTes.length; i++) {
              finalGroupedExtra.push({
                planDef: preAddedLabTes[i],
                healthService: [],
              })
            }
            console.log(
              finalGroupedExtra.sort((a, b) =>
                (a.planDef.title
                  ? a.planDef.title.length > 0
                    ? a.planDef.title
                    : ''
                  : ''
                ).localeCompare(b.planDef.title ? b.planDef.title : '')
              )
            )

            state.resultsAvailable = true
            state.searching = false
            state.error = false
            state.groupedServices = finalGroupedExtra
            state.noResultsAvailable = false
            dispatch(
              referralLabOfferingSliceMultiple.actions.noDataFoundForSearch(
                state
              )
            )
            return
          }
          state.resultsAvailable = false
          state.searching = false
          state.error = false
          state.noResultsAvailable = true
          dispatch(
            referralLabOfferingSliceMultiple.actions.noDataFoundForSearch(state)
          )
          return
        }
        state.resultsAvailable = false
        state.searching = false
        state.error = false
        state.noResultsAvailable = true
        dispatch(
          referralLabOfferingSliceMultiple.actions.noDataFoundForSearch(state)
        )
        return
      }
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = true
      state.errorMessage = 'Visit context not found'
      dispatch(
        referralLabOfferingSliceMultiple.actions.noDataFoundForSearch(state)
      )
      return
    } catch (error) {
      logger.error(error)
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(
        referralLabOfferingSliceMultiple.actions.errorWhileSearching(state)
      )
    }
  }

export const resetReferralOfferingSearch = () => (dispatch: AppDispatch) => {
  dispatch(
    referralLabOfferingSliceMultiple.actions.resetLabOffering(initialState)
  )
}

export function getPractitionerRoleObject(item: R4.IPractitionerRole) {
  const val: PractitionerWithRole = {
    id: item.id ?? '',
    name: item.practitioner?.display ?? '',
    gender: '',
    roleObject: item,
    fullName: '',
    color: '',
    primaryContact: '',
    status: '',
    phone: '',
    enabled: true,
  }
  return val
}

export default referralLabOfferingSliceMultiple.reducer
