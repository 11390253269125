import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import React, { useState } from 'react'
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  getCodeOfSystemCodings,
  getCodeOfSystemFromCodableConceptList,
  getColorBasedOnNumber,
} from 'utils/fhirResourcesHelper'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getTimeOfDayDisplayForStatement,
  getTimeOfDayDisplayMeal,
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import {
  getObservationCodeText,
  getObservationContent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import {
  getNotesFromAllergy,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { kDialogueBackground } from 'configs/styles/muiThemes'

interface OccupationTileProps {
  obsData?: R4.IObservation[]
  type?: string
}

export const AdmissionDashboardHeader: React.FC<OccupationTileProps> = ({
  obsData,
  type,
}: OccupationTileProps) => {
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      border={4}
      style={{
        boxShadow: '0px 0px 4px #ccc',
        margin: '2px 0px',
        borderRadius: '1px',
        borderColor: '#F8F8F8',
        width: '100%',

        backgroundColor: '#F8F8F8',
      }}
    >
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        height={40}
        justifyContent='flex-start'
        alignItems='center'
      >
        <Box
          justifyContent='center'
          alignContent='center'
          display='flex'
          height='10%'
          alignItems='center'
          width='5%'
        />
        <Box
          justifyContent='flex-start'
          alignContent='center'
          display='flex'
          height='10%'
          alignItems='center'
          px={2}
          width='15%'
        >
          <Typography
            variant='subtitle1'
            style={{
              textTransform: 'capitalize',
              fontFamily: 'Open Sans',

              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            Patient Details
          </Typography>
        </Box>

        <Box
          justifyContent='center'
          alignContent='center'
          display='flex'
          height='10%'
          alignItems='center'
          px={2}
          width='10%'
        >
          <Typography
            variant='subtitle1'
            style={{
              textTransform: 'capitalize',
              fontFamily: 'Open Sans',

              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            ID Proof Details
          </Typography>
        </Box>

        <Box
          justifyContent='center'
          alignContent='center'
          display='flex'
          height='10%'
          alignItems='center'
          px={2}
          width='15%'
        >
          <Typography
            variant='subtitle1'
            style={{
              textTransform: 'capitalize',
              fontFamily: 'Open Sans',

              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            Attendant Details
          </Typography>
        </Box>
        <Box
          justifyContent='center'
          alignContent='center'
          display='flex'
          height='10%'
          alignItems='center'
          px={2}
          width='10%'
        >
          <Typography
            variant='subtitle1'
            style={{
              textTransform: 'capitalize',
              fontFamily: 'Open Sans',

              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            ID Proof Details
          </Typography>
        </Box>

        <Box
          justifyContent='center'
          alignContent='center'
          display='flex'
          height='10%'
          alignItems='center'
          px={2}
          width='20%'
        >
          <Typography
            variant='subtitle1'
            style={{
              textTransform: 'capitalize',
              fontFamily: 'Open Sans',

              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            Admission Details
          </Typography>
        </Box>

        <Box
          justifyContent='center'
          alignContent='center'
          display='flex'
          height='10%'
          alignItems='center'
          px={2}
          width='15%'
        >
          <Typography
            variant='subtitle1'
            style={{
              textTransform: 'capitalize',
              fontFamily: 'Open Sans',

              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            Passport Details
          </Typography>
        </Box>

        <Box
          justifyContent='center'
          alignContent='center'
          display='flex'
          height='10%'
          alignItems='center'
          px={2}
          width='10%'
        >
          <Typography
            variant='subtitle1'
            style={{
              textTransform: 'capitalize',
              fontFamily: 'Open Sans',

              color: 'black',

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            Visa Details
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
