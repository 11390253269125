import _, { debounce } from 'lodash'
import React, { useCallback } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import parse from 'autosuggest-highlight/parse'

import { R4 } from '@ahryman40k/ts-fhir-types'
import { getCodesOfValueSetFromTerminologyService } from 'utils/valusetsHelpers'
import match from 'autosuggest-highlight/match'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Tooltip,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  IconButton,
  Checkbox,
} from '@material-ui/core'
import Info from '@material-ui/icons/Info'
import {
  getDisplay,
  getHoverText,
} from 'utils/fhirResoureHelpers/organizationHelpers'
import { getDisplayForQuestion, titleCase } from 'utils/fhirResourcesHelper'
import { Conditions } from 'models/conditions'
import { WelloFormItemLabel } from '../LeftMenu/WelloFormItemLabel'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

export interface ValueSetSearchProps {
  id: string
  url: string
  title: string
  conditionData: Conditions[]
  onSelectAllChanges: (coding: R4.ICoding[]) => void

  preSelectedOptions?: R4.ICoding
  preEnteredText?: string

  onOptionSelected: (
    event: any,
    index: number,
    selectedOption?: R4.ICoding
  ) => void

  onOptionThroughEnter: (
    event: boolean,
    index: number,
    selectedOption?: R4.ICoding
  ) => void
  helperText?: string
  disabled: boolean
  fuzzySearch?: boolean
  alwaysShowAllOptions?: boolean
  error?: boolean
}

export const ValueSetSelectSingleWithCheckbox: React.FC<ValueSetSearchProps> =
  ({
    id,
    url,
    title,
    conditionData,
    onSelectAllChanges,
    preSelectedOptions,
    preEnteredText,
    onOptionSelected,
    onOptionThroughEnter,
    helperText,
    disabled,
    fuzzySearch = false,
    alwaysShowAllOptions = false,
    error,
  }) => {
    const classes = useStyles()
    const [value, setValue] = React.useState<R4.ICoding | null>(
      preSelectedOptions ?? null
    )
    const [inputValue, setInputValue] = React.useState('')
    const [options, setOptions] = React.useState<R4.ICoding[]>([])
    const [allOptions, SetAllOptions] = React.useState<R4.ICoding[]>([])
    const [optionsRemaining, setOptionsRemaining] = React.useState<
      R4.ICoding[]
    >([])
    const [loading, setLoading] = React.useState(false)
    const [open, setOpen] = React.useState<boolean>(!_.isEmpty(preEnteredText))

    function isSameAsSelectedOption(option: string) {
      if (value && value.code === option) {
        return true
      }
      return false
    }

    const loadOptions = useCallback(
      debounce((inputText, callback) => {
        getCodesOfValueSetFromTerminologyService({
          valueSetUrl: url,
          fuzzySearch,
          searchString: inputText,
        }).then((response: R4.ICoding[]) => {
          callback(response)
        })
      }, 1000),
      []
    )

    React.useEffect(() => {
      setLoading(true)
      loadOptions(inputValue, (response: R4.ICoding[]) => {
        setLoading(false)
        SetAllOptions(response)
        setOptions(response.slice().splice(0, 5))
        setOptionsRemaining(response.slice().splice(5))
      })
    }, [inputValue])

    return (
      <Box display='flex' flexDirection='column' flexGrow={1}>
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='flex-start'
          py={0.5}
          px={0.5}
        >
          <Typography variant='subtitle1' style={{ color: 'black' }}>
            {' '}
            {title}
          </Typography>
        </Box>

        {loading && (
          <Box
            flexDirection='row'
            display='flex'
            alignSelf='center'
            justifyContent='center'
            px={1}
          >
            <CircularProgress size={25} />
          </Box>
        )}
        {loading === false && allOptions.length > 0 && (
          <Box display='flex' flexDirection='row' width='100%' paddingTop={1}>
            <Box paddingLeft={0.5}>
              <Checkbox
                edge='start'
                onChange={(event) => {
                  if (event.target.checked === true) {
                    onSelectAllChanges(allOptions)
                  } else {
                    onSelectAllChanges([
                      {
                        code: '406524005',
                        system: 'http://snomed.info/sct',
                        display: 'Reason for Visit',
                      },
                    ])
                  }
                }}
                id='reason'
                style={{
                  paddingTop: 0,
                }}
                checked={conditionData.length === allOptions.length}
                size='small'
                color='primary'
                inputProps={{
                  'aria-labelledby': 'consent',
                }}
              />
            </Box>
            <Box>
              <Typography variant='subtitle2' color='initial'>
                Select All
              </Typography>
            </Box>
          </Box>
        )}
        <Box width='100%' display='flex' flexDirection='row'>
          {loading === false && options.length > 0 && (
            <Box width='50%'>
              {options.map((coding: R4.ICoding, qindex: number) => (
                <Box
                  display='flex'
                  flexDirection='row'
                  key={coding.code}
                  width='100%'
                >
                  <Box paddingLeft={0.5}>
                    <Checkbox
                      edge='start'
                      id={`quest${qindex}`}
                      checked={
                        conditionData.length > 0
                          ? conditionData.filter(
                              (d: Conditions) =>
                                d.checked && d.data.code === coding.code
                            ).length > 0
                          : false
                      }
                      onChange={(event) => {
                        if (coding) {
                          onOptionSelected(event, qindex, coding)
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                          if (coding) {
                            if (conditionData.length > 0) {
                              const data = conditionData.filter(
                                (d: Conditions) =>
                                  d.checked && d.data.code === coding.code
                              )
                              if (data.length > 0) {
                                onOptionThroughEnter(false, qindex, coding)
                              }
                              if (data.length === 0) {
                                onOptionThroughEnter(true, qindex, coding)
                              }
                            } else {
                              onOptionThroughEnter(true, qindex, coding)
                            }
                          }
                        }
                      }}
                      style={{
                        paddingTop: 0,
                      }}
                      size='small'
                      color='primary'
                      aria-checked='false'
                      inputProps={{
                        'aria-labelledby': 'consent',
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography variant='subtitle2' color='initial'>
                      {getDisplayForQuestion(coding.code ?? '')}
                      {/* {titleCase(coding.display ?? '')} */}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          )}

          {loading === false && optionsRemaining.length > 0 && (
            <Box width='50%' display='flex' flexDirection='column'>
              <Box display='flex' flexDirection='row' width='100%'>
                <Box paddingLeft={0.5}>
                  <Checkbox
                    edge='start'
                    onChange={(event) => {}}
                    id='reason'
                    style={{
                      paddingTop: 0,
                    }}
                    checked={true}
                    disabled={true}
                    size='small'
                    color='primary'
                    inputProps={{
                      'aria-labelledby': 'consent',
                    }}
                  />
                </Box>
                <Box>
                  <Typography variant='subtitle2' color='initial'>
                    Reason for Visit
                  </Typography>
                </Box>
              </Box>
              {optionsRemaining.map((coding: R4.ICoding, qindex: number) => (
                <Box
                  display='flex'
                  flexDirection='row'
                  key={coding.code}
                  width='100%'
                >
                  {coding.code !== '406524005' && (
                    <Box paddingLeft={0.5}>
                      <Checkbox
                        edge='start'
                        checked={
                          conditionData.length > 0
                            ? conditionData.filter(
                                (d: Conditions) =>
                                  d.checked && d.data.code === coding.code
                              ).length > 0
                            : false
                        }
                        onChange={(event) => {
                          if (coding) {
                            onOptionSelected(event, qindex, coding)
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.code === 'Enter') {
                            if (coding) {
                              if (conditionData.length > 0) {
                                const data = conditionData.filter(
                                  (d: Conditions) =>
                                    d.checked && d.data.code === coding.code
                                )
                                if (data.length > 0) {
                                  onOptionThroughEnter(false, qindex, coding)
                                }
                                if (data.length === 0) {
                                  onOptionThroughEnter(true, qindex, coding)
                                }
                              } else {
                                onOptionThroughEnter(true, qindex, coding)
                              }
                            }
                          }
                        }}
                        id={`questNext${qindex}`}
                        style={{
                          paddingTop: 0,
                        }}
                        size='small'
                        color='primary'
                        inputProps={{
                          'aria-labelledby': 'consent',
                        }}
                      />
                    </Box>
                  )}
                  {coding.code !== '406524005' && (
                    <Box>
                      <Typography variant='subtitle2' color='initial'>
                        {getDisplayForQuestion(coding.code ?? '')}
                      </Typography>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          )}
        </Box>
        {/* <Autocomplete
        id={id}
        getOptionLabel={(option) =>
          typeof option === 'string'
            ? option
            : _.capitalize(option.display) ?? ''
        }
        open={open}
        onOpen={() => {
          setInputValue(inputValue.length === 0 ? ' ' : inputValue)

          setOpen(true)
        }}
        autoFocus={!_.isEmpty(preEnteredText)}
        onClose={() => {
          setOpen(false)
        }}
        filterOptions={(x) => x}
        options={options}
        disabled={disabled}
        autoComplete
        loading={loading}
        getOptionSelected={(option, val) => option.code === val.code}
        filterSelectedOptions={!alwaysShowAllOptions}
        disableClearable={true}
        inputValue={inputValue}
        value={value ?? undefined}
        onChange={(event: any, newValue: R4.ICoding | null) => {
          setOptions(newValue ? [newValue, ...options] : options)
          setValue(newValue)
          onOptionSelected(newValue === null ? undefined : newValue)
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            id={`${id}_ edit`}
            variant='outlined'
            error={!!error}
            fullWidth
            inputProps={{ ...params.inputProps, maxLength: '256' }}
            size='small'
            helperText={helperText}
          />
        )}
        renderOption={(option) => {
          const matches = match(_.capitalize(option.display ?? ''), inputValue)
          const parts = parse(_.capitalize(option.display ?? ''), matches)

          return (
            <div>
              <Tooltip
                title={getHoverText(option.display ?? '')}
                placement='right-end'
              >
                <div>
                  <Button
                    endIcon={<Info />}
                    component='li'
                    // {...option.props}
                    fullWidth
                    size='small'
                    color='primary'
                  >
                    {option.display ?? ''}
                  </Button>
                </div>
              </Tooltip>
            </div>
          )
        }}
      /> */}
      </Box>
    )
  }
