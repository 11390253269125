import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { CloseOutlined } from '@material-ui/icons'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import { PurposeOfUse } from 'models/purposeOfUse'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { fetchIPDAppointmentDetails } from 'redux/clinic/ipdDetails/ipdAppointmentHandler/ipdAppointmentHandlerSlice'
import { requestForLocation } from 'redux/clinic/ipdmanagement/bedSearch/roomBedSearchSlice'
import { RootState } from 'redux/rootReducer'
import {
  isDoctor,
  isOrgAdmin,
  isPhysiotherapist,
} from 'services/userDetailsService'
import SimpleBar from 'simplebar-react'
import {
  getExtensionValueOfUrlPatient,
  getGenderOfPatient,
  getInnerExtension,
  getValueRefValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'
import {
  isDischarged,
  isDischargeInitiated,
} from 'utils/fhirResoureHelpers/invoice_helper'
import { isGenderFemale } from 'utils/fhirResoureHelpers/ipdHelper'
import { PatientNameInIPDWidget } from '../clinic_home_page/patientNameForIpd'
import { ProfilePicWithAgeAndGender } from '../clinic_home_page/profilePicWithAgeAndGender'
import { DischargeDiagnosisOfPT } from '../consultation/assessment/dischargeDiagnosisOfPT'
import { SystemDiagnosisManagement } from '../consultation/assessment/gastroIntestinalSystemDiagnosisNew'
import { Allergies } from '../consultation/history/allergies'
import { MedicalConditions } from '../consultation/history/medical_conditions'
import { Medication } from '../consultation/history/medication'
import { Procedures } from '../consultation/history/procedures'
import { PatientEvidenceResult } from '../consultation/plan/patientEvidenceResult'
import { Addictions } from '../ipdconusltation/history/additctions'
import { Appetite } from '../ipdconusltation/history/Appetite/appetite'
import { Diet } from '../ipdconusltation/history/Diet'
import { FamilyMedicalConditions } from '../ipdconusltation/history/familyMedicalConditions'
import { Habits } from '../ipdconusltation/history/habits'
import { MensurationDetails } from '../ipdconusltation/history/mensuration/mensurations'
import { Micturition } from '../ipdconusltation/history/Micturition/micturition'
import { Obstrics } from '../ipdconusltation/history/obstrics/obstricsList'
import { IPDChiefComplaint } from '../ipdconusltation/subjective/IPDchiefComplaintsDetails'
import { AssessmentTile } from '../ipdviewData/assessmentTile'
import { DietTile } from '../ipdviewData/dietTile'
import { Feedback } from '../ipdviewData/feedback'
import { PatientDetailsTile } from '../ipdviewData/patientDetailsTile'
import { PTtreatmentTile } from '../ipdviewData/ptTreatmentTIle'
import { SelfDeclaration } from '../ipdviewData/selfDeclarationTIle'
import { TravelHistory } from '../ipdviewData/travelHistory'
import { TreatmentTile } from '../ipdviewData/treatmentTile'
import { VisaDetailsTile } from '../ipdviewData/visaDetilasTile'
import { VitalsTile } from '../ipdviewData/vitalsTile'
import { IPDDietReportButton } from './dietReportOfIPDButton'
import { DischargeReportButton } from './dischargeReportButton'
import { TreatmentReportOfIPDButton } from './treatmentReportOfIPDButton'

//   import { WelloFormItemLabel, WelloLoadingIndicator } from 'wello-web-components'
//   import { RestrictionsOfSelectedOfferings } from '../common/restriction_detail'
//   import { PaymentTile } from '../orderCreationManagement/paymentTile'
//   import { RefundTile } from '../orderCreationManagement/refundTile'
//   import { OrderAppointmentDetails } from './orderAppointmentDetails'
//   import { OrderCancellationHandler } from './orderCancellationHandler'
//   import { OrderReScheduleHandler } from './orderRescheduleHandler'
//   import { OrderTimeLine } from './orderTimeLine'

interface Props {
  ipdDetailsDataFinal: R4.IServiceRequest
  type: string
  isOdd?: boolean
  onCloseClicked: () => void
  dense?: boolean
  date?: Date
}

export const IPDSplitDetails: React.FC<Props> = ({
  ipdDetailsDataFinal,
  type,
  isOdd = false,
  onCloseClicked,
  dense = false,
  date,
}: Props) => {
  const ipdActiveAppointmentSlice = useSelector(
    (state: RootState) => state.ipdActiveAppointmentSlice
  )

  const roomBedSearchSlice = useSelector(
    (state: RootState) => state.roomBedSearchSlice
  )
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [status, setStatus] = useState<any>()
  const [reason, setReason] = useState<string>()
  const [columns, setColumns] = useState<string[]>([])
  const [showBilling, setBillingHandler] = useState<boolean>(false)
  const [showRescheduleHandler, setShowRescheduleHandler] =
    useState<boolean>(false)
  //   const [labOrderDetail, setLabOrderDetail] =
  //     useState<FhirClinicIpdDetails>(ipdDetailsDataFinal)

  const [ipdDetailsData, setIpdDetailsData] =
    useState<FhirActiveIPDDetailsForMedicalRole>()
  const [locId, setLocId] = useState<string>()

  useEffect(() => {
    dispatch(fetchIPDAppointmentDetails(ipdDetailsDataFinal.id!))

    return () => {}
  }, [])

  useEffect(() => {
    if (ipdActiveAppointmentSlice.appointmentFetched) {
      if (ipdActiveAppointmentSlice.activeIPDActionDetails) {
        setLocId(
          getInnerExtension(
            ipdActiveAppointmentSlice.activeIPDActionDetails.mainServiceRequest
              .extension
              ? ipdActiveAppointmentSlice.activeIPDActionDetails
                  .mainServiceRequest.extension
              : [],
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-attendant',
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-attendant-bed'
          )
        )

        if (locId) {
          dispatch(requestForLocation(locId.split('/')[1]))
        }
        // setLabOrderDetail(ipdActiveAppointmentSlice.activeIPDActionDetails)
        setIpdDetailsData(ipdActiveAppointmentSlice.activeIPDActionDetails)
      }
    }
    return () => {}
  }, [ipdActiveAppointmentSlice.appointmentFetched])

  return (
    <Box width='100%' px={1}>
      <Card
        elevation={0}
        style={{
          padding: 0,
          margin: 0,
          borderRadius: 4,
          backgroundColor: isOdd ? '#FFFFFFAF' : undefined,
        }}
      >
        <CardContent
          style={{
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            border: 1,
            // height: '100%',
          }}
        >
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            // style={{ position: 'absolute' }}
          >
            {/* {ipdActiveAppointmentSlice.fetchingAppointmentDetails && (
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            )} */}
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              justifyContent='space-between'
              alignContent='center'
              alignItems='center'
              //   style={{ position: 'absolute' }}
            >
              <Box flexGrow={1}>
                <Box display='flex' alignItems='center' alignContent='center'>
                  <Box px={1}>
                    {ipdActiveAppointmentSlice.appointmentFetched &&
                      ipdActiveAppointmentSlice.activeIPDActionDetails && (
                        <ProfilePicWithAgeAndGender
                          patient={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .patient
                          }
                        />
                      )}
                  </Box>
                  {ipdActiveAppointmentSlice.appointmentFetched &&
                    ipdActiveAppointmentSlice.activeIPDActionDetails && (
                      <PatientNameInIPDWidget
                        appointmentDetails={
                          ipdActiveAppointmentSlice.activeIPDActionDetails
                            .patient
                        }
                        serviceRequest={
                          ipdActiveAppointmentSlice.activeIPDActionDetails
                            .mainServiceRequest
                        }
                        location={undefined}
                      />
                    )}
                </Box>
              </Box>

              {ipdActiveAppointmentSlice.appointmentFetched &&
                ipdActiveAppointmentSlice.activeIPDActionDetails && (
                  <Box display='flex' flexDirection='row'>
                    {/*  {isIPDDischargeEditable(labOrderDetail) &&
                    !isPhysiotherapist() && (
                      <Tooltip title='Change Discharge Date'>
                        <Button
                          variant='text'
                          color='default'
                          onClick={() => {
                            setBillingHandler(true)
                          }}
                        >
                          Billing
                        </Button>e
                      </Tooltip>
                    )} */}

                    {isOrgAdmin() &&
                      type === 'ipd' &&
                      ipdActiveAppointmentSlice.appointmentFetched &&
                      ipdActiveAppointmentSlice.activeIPDActionDetails && (
                        <Box px={1.7} py={0.2}>
                          <PatientEvidenceResult
                            patientId={
                              ipdActiveAppointmentSlice.activeIPDActionDetails
                                .patient.id ?? ''
                            }
                          />
                        </Box>
                      )}

                    {(isDischarged(
                      ipdActiveAppointmentSlice.activeIPDActionDetails.mainTask!
                    ) ||
                      isDischargeInitiated(
                        ipdActiveAppointmentSlice.activeIPDActionDetails
                          .mainTask!
                      )) && (
                      <DischargeReportButton
                        serviceReqId={
                          ipdActiveAppointmentSlice.activeIPDActionDetails
                            .mainServiceRequest.id ?? ''
                        }
                      />
                    )}

                    <IPDDietReportButton
                      serviceReqId={
                        ipdActiveAppointmentSlice.activeIPDActionDetails
                          .mainServiceRequest.id ?? ''
                      }
                    />

                    <TreatmentReportOfIPDButton
                      serviceReqId={
                        ipdActiveAppointmentSlice.activeIPDActionDetails
                          .mainServiceRequest.id ?? ''
                      }
                    />
                    {/* <PoliceVerificationIPDButton
                    serviceReqId={labOrderDetail.serviceRequest.id ?? ''}
                  /> */}
                    {/* {!isOrgAdmin() && (
                      <Box>
                        {isIPDDischargeEditableAdmin(labOrderDetail) &&
                          !isPhysiotherapist() &&
                          !isDischarged(labOrderDetail.task!) &&
                          !isDischargeInitiated(labOrderDetail.task!) &&
                          //   isIPDDDateChangable(labOrderDetail) &&
                          labOrderDetail.appType.length === 0 && (
                            <Tooltip title='Change Discharge Date'>
                              <IconButton
                                aria-label='btn_ord_reschedule'
                                color='primary'
                                onClick={() => {
                                  setShowRescheduleHandler(true)
                                }}
                              >
                                <Edit color='primary' />
                              </IconButton>
                            </Tooltip>
                          )}
                      </Box>
                    )} */}

                    <Tooltip title='Close'>
                      <IconButton
                        aria-label='btn_ord_detail_menu'
                        onClick={onCloseClicked}
                      >
                        <CloseOutlined color='primary' />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
            </Box>

            <Divider
              orientation='horizontal'
              variant='fullWidth'
              flexItem
              style={{ width: '100%', height: '2px' }}
              color='primary'
            />
            {ipdActiveAppointmentSlice.fetchingAppointmentDetails && (
              <Box
                flexDirection='row'
                display='flex'
                alignSelf='center'
                justifyContent='center'
              >
                <CircularProgress size={25} />
              </Box>
            )}

            {ipdActiveAppointmentSlice.appointmentFetched &&
              ipdActiveAppointmentSlice.activeIPDActionDetails && (
                <Box display='flex' flexDirection='column'>
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    height='100%'
                    overflow='auto'
                  >
                    <SimpleBar
                      style={{
                        height: '100%',
                        width: '100%',
                        overflowX: 'hidden',
                        padding: '8px',
                      }}
                    >
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <PatientDetailsTile
                          patient={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .patient
                          }
                          location={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .roomData
                          }
                        />
                        {getExtensionValueOfUrlPatient(
                          ipdActiveAppointmentSlice.activeIPDActionDetails
                            .patient.extension ?? [],
                          'http://wellopathy.com/fhir/india/core/CodeSystem/country'
                        ) !== 'india' && (
                          <Box paddingTop={0.5}>
                            <Divider
                              orientation='horizontal'
                              variant='fullWidth'
                              flexItem
                              style={{ width: '100%', height: '2px' }}
                              color='primary'
                            />
                          </Box>
                        )}
                        {getExtensionValueOfUrlPatient(
                          ipdActiveAppointmentSlice.activeIPDActionDetails
                            .patient.extension ?? [],
                          'http://wellopathy.com/fhir/india/core/CodeSystem/country'
                        ) !== 'india' && (
                          <Box>
                            <VisaDetailsTile
                              patient={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .patient
                              }
                              serviceId={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .mainServiceRequest.id!
                              }
                            />
                          </Box>
                        )}
                        <Box paddingTop={0.5}>
                          <Divider
                            orientation='horizontal'
                            variant='fullWidth'
                            flexItem
                            style={{ width: '100%', height: '2px' }}
                            color='primary'
                          />
                        </Box>
                      </Box>
                      {/* {labOrderDetail.appType.length === 0 && ( */}

                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <SelfDeclaration
                          patient={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .patient
                          }
                          ipdData={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                          }
                        />
                      </Box>

                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <TravelHistory
                          patient={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .patient
                          }
                          ipdData={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                          }
                        />
                      </Box>

                      {(isDoctor() || isOrgAdmin()) && (
                        <Box>
                          <Box
                            borderColor='lightGrey'
                            width='100%'
                            display='flex'
                            flexDirection='column'
                          >
                            <IPDChiefComplaint
                              fhirAppointmentDetails={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                              }
                              status=''
                              splitview={true}
                            />
                          </Box>

                          <Box
                            borderColor='lightGrey'
                            width='100%'
                            display='flex'
                            flexDirection='column'
                          >
                            <MedicalConditions
                              patient={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .patient
                              }
                              purposeOfUse={PurposeOfUse.IPDAppointment}
                              requestingAppointmentId={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .mainServiceRequest.id
                              }
                              status=''
                              splitview={true}
                            />
                          </Box>
                          <Box
                            borderColor='lightGrey'
                            width='100%'
                            display='flex'
                            flexDirection='column'
                          >
                            <Procedures
                              patient={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .patient
                              }
                              purposeOfUse={PurposeOfUse.IPDAppointment}
                              requestingAppointmentId={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .mainServiceRequest.id
                              }
                              status=''
                              splitView={true}
                            />
                          </Box>

                          <Box
                            borderColor='lightGrey'
                            width='100%'
                            display='flex'
                            flexDirection='column'
                          >
                            <Allergies
                              patient={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .patient
                              }
                              purposeOfUse={PurposeOfUse.IPDAppointment}
                              requestingAppointmentId={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .mainServiceRequest.id
                              }
                              status=''
                              splitView={true}
                            />
                          </Box>

                          <Box
                            borderColor='lightGrey'
                            width='100%'
                            display='flex'
                            flexDirection='column'
                          >
                            <Medication
                              fhirAppointmentDetails={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                              }
                              patient={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .patient
                              }
                              purposeOfUse={PurposeOfUse.OPDAppointment}
                              requestingAppointmentId={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .mainServiceRequest.id
                              }
                              status=''
                              splitView={true}
                            />
                          </Box>

                          {isGenderFemale(
                            getGenderOfPatient(
                              ipdActiveAppointmentSlice.activeIPDActionDetails
                                .patient
                            )
                          ) && (
                            <Box
                              borderColor='lightGrey'
                              width='100%'
                              display='flex'
                              flexDirection='column'
                            >
                              <MensurationDetails
                                fhirAppointmentDetails={
                                  ipdActiveAppointmentSlice.activeIPDActionDetails
                                }
                                status=''
                                splitView={true}
                              />
                            </Box>
                          )}

                          {isGenderFemale(
                            getGenderOfPatient(
                              ipdActiveAppointmentSlice.activeIPDActionDetails
                                .patient
                            )
                          ) && (
                            <Box
                              borderColor='lightGrey'
                              width='100%'
                              display='flex'
                              flexDirection='column'
                            >
                              <Obstrics
                                fhirAppointmentDetails={
                                  ipdActiveAppointmentSlice.activeIPDActionDetails
                                }
                                status=''
                                splitView={true}
                              />
                            </Box>
                          )}

                          <Box
                            borderColor='lightGrey'
                            width='100%'
                            display='flex'
                            flexDirection='column'
                          >
                            <Habits
                              fhirAppointmentDetails={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                              }
                              status=''
                              splitview={true}
                            />
                          </Box>

                          <Box
                            borderColor='lightGrey'
                            width='100%'
                            display='flex'
                            flexDirection='column'
                          >
                            <Addictions
                              fhirAppointmentDetails={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                              }
                              status=''
                              splitview={true}
                            />
                          </Box>

                          <Box
                            borderColor='lightGrey'
                            width='100%'
                            display='flex'
                            flexDirection='column'
                          >
                            <FamilyMedicalConditions
                              fhirAppointmentDetails={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                              }
                              status=''
                              splitView={true}
                            />
                          </Box>

                          <Box
                            borderColor='lightGrey'
                            width='100%'
                            display='flex'
                            flexDirection='column'
                          >
                            <Diet
                              fhirAppointmentDetails={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                              }
                              status=''
                              splitview={true}
                            />
                          </Box>

                          <Box
                            borderColor='lightGrey'
                            width='100%'
                            display='flex'
                            flexDirection='column'
                          >
                            <Appetite
                              fhirAppointmentDetails={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                              }
                              status=''
                              splitView={true}
                            />
                          </Box>

                          <Box
                            borderColor='lightGrey'
                            width='100%'
                            display='flex'
                            flexDirection='column'
                          >
                            <Micturition
                              fhirAppointmentDetails={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                              }
                              status=''
                              splitView={true}
                            />
                          </Box>
                        </Box>
                      )}

                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <VitalsTile
                          patient={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .patient
                          }
                          ipdDetails={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .mainServiceRequest
                          }
                        />
                      </Box>

                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <TreatmentTile
                          patient={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .patient
                          }
                          ipdDetails={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .mainServiceRequest
                          }
                          isPT={false}
                        />
                      </Box>

                      {(isPhysiotherapist() || isOrgAdmin()) && (
                        <Box
                          borderColor='lightGrey'
                          width='100%'
                          display='flex'
                          flexDirection='column'
                        >
                          <PTtreatmentTile
                            patient={
                              ipdActiveAppointmentSlice.activeIPDActionDetails
                                .patient
                            }
                            ipdDetails={
                              ipdActiveAppointmentSlice.activeIPDActionDetails
                                .mainServiceRequest
                            }
                            isPT={true}
                          />
                        </Box>
                      )}

                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        {ipdActiveAppointmentSlice.appointmentFetched &&
                          ipdActiveAppointmentSlice.activeIPDActionDetails && (
                            <DietTile
                              serviceRequest={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .mainServiceRequest
                              }
                            />
                          )}
                      </Box>

                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <SystemDiagnosisManagement
                          valueSetCatCode='conditionMaster'
                          encounterId={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .mainEncounter?.id!
                          }
                          patientId={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .patient.id ?? ''
                          }
                          categoryCoding={{
                            code: '148006',
                            display: 'Preliminary diagnosis',
                            system: 'http://snomed.info/sct',
                          }}
                          title='Provisional Diagnosis'
                          otherCoding={{
                            code: 'J95-J99',
                            display: 'Other',
                          }}
                          splitView={true}
                        />
                      </Box>

                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <AssessmentTile
                          patient={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .patient
                          }
                          serviceReq={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .mainServiceRequest
                          }
                        />

                        {ipdDetailsData &&
                          ipdDetailsData.mainEncounter &&
                          ipdDetailsData.mainEncounter.id && (
                            <DischargeDiagnosisOfPT
                              encounterId={
                                ipdActiveAppointmentSlice.activeIPDActionDetails
                                  .mainEncounter?.id!
                              }
                            />
                          )}
                      </Box>
                      <Box
                        borderColor='lightGrey'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        <Feedback
                          patient={
                            ipdActiveAppointmentSlice.activeIPDActionDetails
                              .patient
                          }
                          ipdData={ipdDetailsData}
                        />
                      </Box>
                    </SimpleBar>
                  </Box>
                </Box>
              )}
          </Box>
        </CardContent>
      </Card>

      {/* {showBilling && (
        <ViewBillingOfIPD
          ipdDetails={labOrderDetail}
          onClose={() => {
            setBillingHandler(false)
          }}
          open={showBilling}
        />
      )} */}
    </Box>
  )
}
