import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Card, CardContent, Typography } from '@material-ui/core'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import { FhirClinicIpdDetails, IpdAdmission } from 'models/fhirClinicIpdDetails'
import { ReportColumns } from 'models/Report/reportColumn'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getAddressOfPatient,
  getAddressOfPatientForOPDAppointment,
  getIdentifierValueBySystem,
  getIdentifierValueBySystemTypeCoding,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameOfPatient,
  getProfilePicPatient,
  titleCase,
  getInnerExtension,
  getFirstNameOfRelatedPerson,
  getLastNameOfRelatedPerson,
  getPassportStartDate,
  getExtensionCodingOfUrlPatient,
  getPassportEndDate,
  getStringValueExtensionsOfUrl,
  getVisaCity,
  getValueCodableFromExtension,
  getDataFromCoding,
} from 'utils/fhirResourcesHelper'
import { useDispatch } from 'react-redux'
import { ProfilePicViewer } from 'views/components/common/profilePicViewer'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { SelectedFile } from 'views/components/LeftMenu/WelloFilePickerForProfile'
import {
  getIpdAdmitTIme,
  getIpdAdmitTImeForOrg,
  getRoomNo,
  getRoomNoForOrg,
} from 'utils/fhirResoureHelpers/ipdAppointmentHelper'
import { getIpdInTImeWithDate } from 'utils/dateUtil'
import { GenderAndAgeTileWithoutBack } from 'views/components/patients/patient_profile/genderAgeTilleWithoutBackGround'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { showErrorAlert } from 'redux/alertHandler/alertSlice'
import { getRelatedPerson } from 'utils/fhirResoureHelpers/ipdHelper'
import moment from 'moment'

interface Props {
  ipdDetails: IpdAdmission
  type: string
  onAppointmentSelected: (appointmentDetails: R4.IServiceRequest) => void
  isOdd?: boolean
  onViewClicked?: () => void
  dense?: boolean
  isSelected?: boolean
}

const options = ['Start IPD']

const ITEM_HEIGHT = 48

export const IPDOrgAdmissionDashboardList: React.FC<Props> = ({
  ipdDetails,
  type,
  onAppointmentSelected,
  isOdd = false,
  onViewClicked,
  dense = false,
  isSelected = false,
}: Props) => {
  const { t } = useTranslation()
  const [showBilling, setBillingHandler] = React.useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const dispatch = useDispatch()
  const [showText, setShowText] = React.useState<boolean>(false)
  const [fullText, setFullText] = React.useState<string>()
  const [attendant, setAttendant] = React.useState<R4.IRelatedPerson>()
  console.log(ipdDetails)

  const [paymentStatus, setPaymentStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [statusLoading, setStatusLoading] = useState<boolean>(false)

  const locId = getInnerExtension(
    ipdDetails.serviceRequest.extension
      ? ipdDetails.serviceRequest.extension
      : [],
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-attendant',
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-attendant-ref'
  )

  function getRelatedPersonResource(relatedPersonId: string) {
    setPaymentStatus({ requesting: true })

    setStatusLoading(true)

    getRelatedPerson(relatedPersonId)
      .then((e: R4.IRelatedPerson | undefined) => {
        if (e) {
          setAttendant(e)
        }
        setStatusLoading(false)

        setPaymentStatus({
          requesting: false,
          requestSuccess: true,
        })

        // dispatch(showSuccessAlert('Medication deleted successfully'))
        // dispatch(getMedicationsOfIPD(fhirAppointmentDetails))
      })
      .catch((e) => {
        dispatch(showErrorAlert('Error while fetching attendant details'))
        console.log(e)
        setStatusLoading(false)
        setPaymentStatus({
          requesting: false,
          requestSuccess: false,
        })
      })
  }

  useEffect(() => {
    if (locId && locId.length > 0) {
      getRelatedPersonResource(locId)
    }
  }, [])

  return (
    <Box
      width='100%'
      onClick={(e) => {
        if (onAppointmentSelected)
          onAppointmentSelected(ipdDetails.serviceRequest)
      }}
    >
      <Card
        elevation={isOdd ? 0 : 1}
        style={{
          padding: 0,
          margin: 0,
          borderRadius: 0,
          backgroundColor: isSelected
            ? kPrimaryLight
            : isOdd
            ? '#FFFFFFAF'
            : undefined,
        }}
      >
        <CardContent
          style={{
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            border: 0,
          }}
        >
          {dense && (
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              onClick={(e) => {
                if (onViewClicked) onViewClicked()
              }}
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                px={1}
              >
                <ProfilePicViewer
                  contentType='image/png'
                  existingFile={false}
                  decodingRequired={true}
                  imagePath='Patient.photo'
                  resourceId={ipdDetails.patient.id ?? ''}
                  resourceType='Patient'
                  onDataFetched={(value: string) => {
                    // setDataFile(true)
                  }}
                  onSelectionChanged={(value: SelectedFile) => {}}
                  update={false}
                  purpose='tile3'
                  prescription={true}
                />
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
                flexGrow={1}
              >
                <Typography
                  variant='subtitle1'
                  color='primary'
                  style={{
                    color: 'black',
                    fontWeight: 'bold',
                  }}
                >
                  {`${getNameOfPatient(ipdDetails.patient) ?? ''} ${
                    getMiddleNameOfPatient(ipdDetails.patient) ?? ''
                  } ${getLastNameOfPatient(ipdDetails.patient) ?? ''}`}
                </Typography>
                <Typography
                  variant='body2'
                  color='initial'
                  style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}
                >
                  {getAddressOfPatient(ipdDetails.patient.address ?? [])}
                </Typography>

                <Typography variant='subtitle2' color='initial'>
                  {getIdentifierValueBySystem(
                    ipdDetails.serviceRequest.identifier ?? [],
                    'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
                  )}
                </Typography>
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-start'
                >
                  <Typography variant='body2' color='initial'>
                    DOA {`${getIpdAdmitTImeForOrg(ipdDetails.serviceRequest)}`}
                  </Typography>

                  {/* {ipdDetails.type === 'ipd' && (
                        <Typography variant='body2' color='initial'>
                          {`${getIpdAdmitTIme(ipdAppointmentDetails)}`} -
                        </Typography>
                      )} */}
                  {/* {ipdDetails.type === 'opd' && (
                    <Typography variant='body2' color='initial'>
                      {`${getIpdAdmitTIme(ipdAppointmentDetails)}`} -{' '}
                      {`${getAppointmentEndTIme(ipdAppointmentDetails)}`}
                    </Typography>
                  )} */}
                  {ipdDetails.location && (
                    <Typography variant='body2' color='initial'>
                      {`${getRoomNoForOrg(ipdDetails.location)}`}
                    </Typography>
                  )}

                  <Box display='flex' flexBasis='row'>
                    <Typography variant='body2' color='initial'>
                      DOD
                    </Typography>

                    <Box display='flex' flexBasis='row' paddingLeft={0.3}>
                      <Typography variant='body2' color='initial'>
                        {` ${getIpdInTImeWithDate(
                          ipdDetails.serviceRequest.occurrencePeriod?.end ?? ''
                        )}`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {!dense && (
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              justifyContent='space-between'
              alignContent='center'
              p={0.5}
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                px={1}
                width='5%'
              >
                <ProfilePicViewer
                  contentType='image/png'
                  existingFile={false}
                  decodingRequired={true}
                  imagePath='Patient.photo'
                  resourceId={ipdDetails.patient.id ?? ''}
                  resourceType='Patient'
                  onDataFetched={(value: string) => {
                    // setDataFile(true)
                  }}
                  onSelectionChanged={(value: SelectedFile) => {}}
                  update={false}
                  purpose='tile3'
                  prescription={true}
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='15%'
              >
                {getMiddleNameOfPatient(ipdDetails.patient).length > 0 && (
                  <Typography
                    variant='subtitle1'
                    color='primary'
                    style={{
                      textTransform: 'capitalize',
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    {getNameOfPatient(ipdDetails.patient)}{' '}
                    {getMiddleNameOfPatient(ipdDetails.patient) ?? ''}{' '}
                    {getLastNameOfPatient(ipdDetails.patient)}
                  </Typography>
                )}

                {getMiddleNameOfPatient(ipdDetails.patient).length === 0 && (
                  <Typography
                    variant='subtitle1'
                    color='primary'
                    style={{
                      textTransform: 'capitalize',
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    {getNameOfPatient(ipdDetails.patient)}{' '}
                    {getLastNameOfPatient(ipdDetails.patient)}
                  </Typography>
                )}

                <Box
                  display='flex'
                  flexDirection='column'
                  width='45%'
                  justifyContent='flex-start'
                >
                  <GenderAndAgeTileWithoutBack
                    patient={ipdDetails.patient}
                    status={false}
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-start'
                >
                  <Typography
                    variant='body2'
                    color='initial'
                    style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}
                  >
                    {getAddressOfPatientForOPDAppointment(
                      ipdDetails.patient.address ?? []
                    )}
                  </Typography>
                </Box>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignContent='flex-start'
                width='10%'
                px={2}
              >
                {getIdentifierValueBySystemTypeCoding(
                  ipdDetails.patient.identifier ?? [],
                  'address-proof'
                ) && (
                  <Typography variant='subtitle2' color='initial'>
                    {
                      getIdentifierValueBySystemTypeCoding(
                        ipdDetails.patient.identifier ?? [],
                        'address-proof'
                      )!.display
                    }
                  </Typography>
                )}
                {getIdentifierValueBySystem(
                  ipdDetails.patient.identifier ?? [],
                  'address-proof'
                ) && (
                  <Box display='flex' flexDirection='column' width='50%'>
                    <Typography variant='subtitle2' color='initial'>
                      {getIdentifierValueBySystem(
                        ipdDetails.patient.identifier ?? [],
                        'address-proof'
                      )}
                    </Typography>
                  </Box>
                )}

                <Typography
                  variant='subtitle1'
                  color='primary'
                  style={{
                    textTransform: 'capitalize',
                    color: 'black',
                    fontWeight: 'bold',
                  }}
                >
                  {getExtensionCodingOfUrlPatient(
                    ipdDetails.patient.extension,
                    'http://wellopathy.com/fhir/india/core/CodeSystem/country'
                  ).display ?? ''}
                </Typography>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='15%'
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='center'
                  width='100%'
                >
                  {attendant && (
                    <Typography
                      variant='subtitle2'
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,

                        fontWeight: 600,
                        lineHeight: 'normal',
                      }}
                    >
                      {`${getFirstNameOfRelatedPerson(attendant) ?? ''} ${
                        getLastNameOfRelatedPerson(attendant) ?? ''
                      }`}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignContent='flex-start'
                width='10%'
                px={2}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  alignContent='flex-start'
                  width='100%'
                >
                  {attendant &&
                    getIdentifierValueBySystemTypeCoding(
                      attendant.identifier ?? [],
                      'related-address-proof'
                    ) && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='center'
                        width='100%'
                      >
                        <Typography variant='subtitle2' color='initial'>
                          {
                            getIdentifierValueBySystemTypeCoding(
                              attendant.identifier ?? [],
                              'related-address-proof'
                            )!.display
                          }
                        </Typography>
                      </Box>
                    )}
                  {attendant &&
                    getIdentifierValueBySystem(
                      attendant.identifier ?? [],
                      'related-address-proof'
                    ) && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='center'
                        width='100%'
                      >
                        <Typography variant='subtitle2' color='initial'>
                          {getIdentifierValueBySystem(
                            attendant.identifier ?? [],
                            'related-address-proof'
                          )}
                        </Typography>
                      </Box>
                    )}
                </Box>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='20%'
                paddingLeft={2}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='center'
                  width='100%'
                >
                  <Typography variant='body2' color='initial'>
                    DOA {`${getIpdAdmitTImeForOrg(ipdDetails.serviceRequest)}`}
                  </Typography>
                </Box>

                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='flex-start'
                  width='100%'
                  paddingLeft={8.4}
                >
                  <Typography variant='subtitle2' color='initial'>
                    {getIdentifierValueBySystem(
                      ipdDetails.serviceRequest.identifier ?? [],
                      'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
                    ).length > 0
                      ? getIdentifierValueBySystem(
                          ipdDetails.serviceRequest.identifier ?? [],
                          'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
                        )
                      : getIdentifierValueBySystem(
                          ipdDetails.serviceRequest.identifier ?? [],
                          'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
                        )}
                  </Typography>
                </Box>
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='flex-start'
                  width='100%'
                  paddingLeft={8.4}
                >
                  {ipdDetails.location && (
                    <Typography variant='body2' color='initial'>
                      {`${getRoomNoForOrg(ipdDetails.location)}`}
                    </Typography>
                  )}
                </Box>

                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='center'
                  width='100%'
                >
                  <Typography variant='body2' color='initial'>
                    DOD{' '}
                    {` ${getIpdInTImeWithDate(
                      ipdDetails.serviceRequest.occurrencePeriod?.end ?? ''
                    )}`}
                  </Typography>
                </Box>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='15%'
              >
                {getIdentifierValueBySystemTypeCoding(
                  ipdDetails.patient.identifier ?? [],
                  'address-proof'
                ) &&
                  getIdentifierValueBySystemTypeCoding(
                    ipdDetails.patient.identifier ?? [],
                    'address-proof'
                  )!.display === 'Passport' && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      justifyContent='flex-start'
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='center'
                        width='100%'
                      >
                        <Typography variant='body2' color='initial'>
                          {
                            getIdentifierValueBySystem(
                              ipdDetails.patient.identifier ?? [],
                              'address-proof'
                            )!
                          }
                        </Typography>
                      </Box>

                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='flex-start'
                        width='100%'
                        paddingLeft={9}
                      >
                        <Typography variant='body2' color='initial'>
                          {moment(
                            getPassportStartDate(
                              ipdDetails.patient.extension,
                              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
                            )
                          ).format('DD-MM-YYYY')}
                        </Typography>
                      </Box>
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='flex-start'
                        width='100%'
                        paddingLeft={9}
                      >
                        <Typography variant='body2' color='initial'>
                          {moment(
                            getPassportEndDate(
                              ipdDetails.patient.extension,
                              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
                            )
                          ).format('DD-MM-YYYY')}
                        </Typography>
                      </Box>
                    </Box>
                  )}
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                width='10%'
              >
                {getExtensionCodingOfUrlPatient(
                  ipdDetails.patient.extension,
                  'http://wellopathy.com/fhir/india/core/CodeSystem/country'
                ).display &&
                  getExtensionCodingOfUrlPatient(
                    ipdDetails.patient.extension,
                    'http://wellopathy.com/fhir/india/core/CodeSystem/country'
                  ).code !== 'IN' && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      justifyContent='flex-start'
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='center'
                        width='100%'
                      >
                        <Typography variant='body2' color='initial'>
                          {getStringValueExtensionsOfUrl(
                            ipdDetails.patient.extension,
                            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-number-ext'
                          ) ?? ''}
                        </Typography>
                      </Box>

                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='flex-start'
                        width='100%'
                        paddingLeft={4}
                      >
                        <Typography variant='body2' color='initial'>
                          {getDataFromCoding(
                            ipdDetails.patient.extension ?? [],
                            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-type-ext'
                          )}
                        </Typography>
                      </Box>
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='flex-start'
                        width='100%'
                        paddingLeft={4}
                      >
                        <Typography variant='body2' color='initial'>
                          {moment(
                            getPassportStartDate(
                              ipdDetails.patient.extension,
                              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-validity-period-ext'
                            )
                          ).format('DD-MM-YYYY')}
                        </Typography>
                      </Box>
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='flex-start'
                        width='100%'
                        paddingLeft={4}
                      >
                        <Typography variant='body2' color='initial'>
                          {moment(
                            getPassportEndDate(
                              ipdDetails.patient.extension,
                              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-validity-period-ext'
                            )
                          ).format('DD-MM-YYYY')}
                        </Typography>
                      </Box>
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='flex-start'
                        width='100%'
                        paddingLeft={4}
                      >
                        <Typography variant='body2' color='initial'>
                          {getVisaCity(
                            ipdDetails.patient.extension,
                            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-issued-country-ext'
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  )}
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>

      <NoteDialog
        id='notesTile'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        notesData={fullText!}
      />
    </Box>
  )
}
