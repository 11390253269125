import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import { LabOfferingDetail } from 'models/labOfferDetail'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showSuccessAlert, showErrorAlert } from 'redux/alertHandler/alertSlice'
import {
  requestLabOrder,
  resetLabOrderCreationState,
} from 'redux/lab/order/createOrderSlice/createOrderSlice'
import {
  resetPatientSearchPatient,
  searchPatients,
} from 'redux/patient/patientSearch/patientSearchSlice'
import { resetUpdateUserAddressState } from 'redux/patient/updatePatient/updatePatientSlice'
import { RootState } from 'redux/rootReducer'
import { availablePaymentOptions } from 'utils/constants'
import { getIdentifierValueBySystem } from 'utils/fhirResourcesHelper'
import { getRestrictionsFromLabOfferings } from 'utils/fhirResoureHelpers/planDefinitionHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { AddNewPatient } from 'views/components/add_new_patient'
import { OrderAmountSummaryTile } from 'views/components/generalOrder/ordarAmountSummary'
import CustomizedDividersForLab from 'views/components/LeftMenu/toggle_Selector_Lab'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { PatientTile } from 'views/components/patients/pateintTile'
import { PatientSearchByText } from 'views/components/patients/patientSearchByText'
import {
  getTelecomOfPatient,
  WelloLoadingIndicator,
} from 'wello-web-components'
import { RestrictionsOfSelectedOfferings } from '../common/restriction_detail'
import { AgentSlotForOrder } from './agent_slot_selector'
import { LabOfferingSelector } from './labTestSearch'
import { PatientAddressSelector } from './patient_address_selector'

interface Props {
  open: boolean
  onClose: () => void
  onLabOrderCreated: (orderId: string, paymentType: string) => void
  locationId?: string
  partnerLabUser?: R4.IPractitionerRole
  preselectedPatient?: R4.IPatient
  referralId?: string
  encounterRef?: string
  preSelectedTest?: LabOfferingDetail[]
  test?: string
}
export const CreateLabOrderHandler: React.FC<Props> = ({
  open,
  onClose,
  onLabOrderCreated,
  locationId,
  partnerLabUser,
  preselectedPatient,
  referralId,
  encounterRef,
  preSelectedTest,
  test,
}) => {
  const createOrderSlice = useSelector(
    (state: RootState) => state.createOrderSlice
  )
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '50%',
    },
    dialogCustomizedWidthFinal: {
      'max-width': '40%',
    },
  }))
  const [showAddPatient, setShowAddPatient] = useState<boolean>(false)
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const [selectedPatient, setSelectedPatient] =
    useState<R4.IPatient | undefined>()
  const [isPatientEditMode, setIsPatientEditMode] = useState<boolean>(true)

  const [selectedLabOfferings, setSelectedLabOfferings] =
    useState<LabOfferingDetail[] | undefined>(preSelectedTest)
  const [sampleCollectionPoint, setSampleCollectionPoint] =
    useState<string>('home_collection')
  const [selectedAddress, setSelectedAddress] = useState<
    R4.IAddress | undefined
  >(
    preselectedPatient && preselectedPatient.address
      ? preselectedPatient.address[0]
      : selectedPatient && selectedPatient.address
      ? selectedPatient.address[0]
      : undefined
  )
  const [selectedPaymentOptions, setSelectedPaymentOptions] =
    useState<R4.ICoding>(availablePaymentOptions[0])
  const [selectedSlot, setSelectedSlot] = useState<FhirSlotDetail>()
  useEffect(() => {
    if (createOrderSlice.appointmentCreatedSuccessfully) {
      if (onLabOrderCreated) {
        onLabOrderCreated(
          createOrderSlice.orderId,
          selectedPaymentOptions.code ?? ''
        )
        resetForm()
      }
    }
  }, [createOrderSlice, onLabOrderCreated])

  function isEnableCreateButton(): boolean | undefined {
    if (sampleCollectionPoint === 'home_collection') {
      return (
        selectedPatient &&
        selectedLabOfferings &&
        selectedAddress &&
        selectedSlot &&
        selectedLabOfferings.length > 0
      )
    }
    return (
      selectedPatient && selectedLabOfferings && selectedLabOfferings.length > 0
    )
  }

  function resetForm() {
    setSelectedPatient(undefined)
    setSelectedLabOfferings(undefined)
    setSelectedAddress(undefined)
    setIsPatientEditMode(true)
    dispatch(resetLabOrderCreationState())
    dispatch(resetUpdateUserAddressState())
    dispatch(resetPatientSearchPatient())
  }

  const patientsSearchState = useSelector(
    (state: RootState) => state.patientSearchSlice
  )

  useEffect(() => {
    if (test && preselectedPatient) {
      dispatch(
        searchPatients(
          getTelecomOfPatient(
            preselectedPatient,
            R4.ContactPointSystemKind._phone
          )!
            .replace(/\D/g, '')
            .slice(-10),
          '',
          getIdentifierValueBySystem(
            preselectedPatient.identifier ?? [],
            'http://wellopathy.com/fhir/india/core/id/offset-phone'
          )
        )
      )
    }
  }, [])

  useEffect(() => {
    if (test && preselectedPatient) {
      if (patientsSearchState.patientList) {
        setSelectedPatient(patientsSearchState.patientList[0])
        setIsPatientEditMode(false)
      }
    }
  }, [patientsSearchState.patientList])

  useEffect(() => {
    if (test && preselectedPatient) {
      if (
        patientsSearchState.searching === false &&
        patientsSearchState.patientList === undefined
      ) {
        if (patientsSearchState.noResultsAvailable) {
          setShowAddPatient(true)
        }
      }
    }
  }, [patientsSearchState.noResultsAvailable])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetForm()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{
        paperFullWidth:
          selectedPatient && selectedLabOfferings && selectedLabOfferings.length
            ? classes.dialogCustomizedWidth
            : classes.dialogCustomizedWidthFinal,
      }}
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>{t('labelCommon:new_lab_order')}</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
                resetForm()
              }}
              id='close_order'
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
        }}
      >
        <Box display='flex' flexDirection='row' width='100%'>
          <Box
            display='flex'
            flexDirection='column'
            flexGrow={1}
            maxWidth={width * 0.65}
          >
            <Box display='flex' flexDirection='column' width='100%'>
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                justifyContent='space-between'
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  width={
                    selectedLabOfferings &&
                    selectedLabOfferings.length > 0 &&
                    selectedPatient
                      ? '60%'
                      : '80%'
                  }
                  paddingRight={1}
                >
                  {patientsSearchState.searching && (
                    <CircularProgress size={25} />
                  )}
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                    width='100%'
                  >
                    {selectedPatient === undefined && (
                      <WelloFormItemLabel
                        title={t('labelCommon:select_patient')}
                      />
                    )}

                    {selectedPatient && (
                      <Box display='flex' flexDirection='row' paddingTop={0.5}>
                        <WelloFormItemLabel title='Selected Patient' />
                      </Box>
                    )}
                    {!isPatientEditMode && test === undefined && (
                      <Button
                        id='but_lab_ord_cre_modify_pat'
                        variant='text'
                        color='primary'
                        onClick={(e) => {
                          setIsPatientEditMode(true)
                          setSelectedPatient(undefined)
                          setSelectedAddress(undefined)
                        }}
                      >
                        Modify
                      </Button>
                    )}
                  </Box>
                  <Box display='flex' flexDirection='row' width='100%'>
                    {isPatientEditMode && (
                      <PatientSearchByText
                        id={5}
                        onNewPatientButtonClicked={() => {
                          setSelectedAddress(undefined)
                        }}
                        onPatientSelected={(patient) => {
                          setSelectedPatient(patient)
                          console.log(patient.address)
                          setSelectedAddress(patient.address?.[0])
                          setIsPatientEditMode(false)
                        }}
                      />
                    )}
                  </Box>
                  {!isPatientEditMode && selectedPatient && (
                    <Box display='flex' flexDirection='row' width='100%'>
                      <PatientTile patient={selectedPatient} />
                    </Box>
                  )}
                </Box>
                {selectedLabOfferings &&
                  selectedLabOfferings.length > 0 &&
                  selectedPatient && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='40%'
                      minHeight='115px'
                      maxHeight='500px'
                      paddingLeft={1}
                    >
                      <WelloFormItemLabel title='Price Summary' />
                      <OrderAmountSummaryTile
                        planDefinitions={selectedLabOfferings}
                      />
                    </Box>
                  )}
              </Box>

              <Box display='flex' flexDirection='column' my={2} width='100%'>
                <Box display='flex' flexDirection='row' width='100%'>
                  <Box
                    width={
                      selectedLabOfferings &&
                      selectedLabOfferings.length > 0 &&
                      selectedPatient
                        ? '60%'
                        : '80%'
                    }
                    paddingRight={1}
                    display='flex'
                    flexDirection='column'
                  >
                    <WelloFormItemLabel
                      title='Select Test or Package'
                      case='Yes'
                    />
                    {/* {!isOfferingsEditMode && (
                      <Button
                        variant='text'
                        id='but_lab_ord_cre_modify_tests'
                        onClick={(e) => {
                          setIsOfferingsEditMode(true)
                        }}
                      >
                        {t('modify')}
                      </Button>
                    )} */}

                    <Box display='flex' flexDirection='row' width='100%'>
                      <LabOfferingSelector
                        onSelectionChanges={(e: any) => {
                          setSelectedLabOfferings(e)
                        }}
                        disabled={false}
                        preSelectedOrdersRoles={preSelectedTest ?? []}
                        preSelectedText={test}
                      />
                    </Box>

                    {selectedLabOfferings &&
                      selectedLabOfferings.length > 0 &&
                      selectedPatient && (
                        <Box display='flex' flexDirection='row' width='100%'>
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                          >
                            <Box
                              display='flex'
                              flexDirection='row'
                              paddingTop={2}
                            >
                              <CustomizedDividersForLab
                                size='small'
                                menuLabel={t('labelCommon:order_type')}
                                options={[
                                  {
                                    displayText: t(
                                      'labelCommon:home_collection'
                                    ),
                                    value: 'home_collection',
                                  },
                                  {
                                    displayText: t(
                                      'labelCommon:onsite_collection'
                                    ),
                                    value: 'onsite-collection',
                                  },
                                ]}
                                onSelectionChanged={(selectedValue) => {
                                  setSampleCollectionPoint(selectedValue)
                                }}
                                preSelectedValue={sampleCollectionPoint}
                                disablePreSelect={false}
                              />
                            </Box>

                            {sampleCollectionPoint === 'home_collection' && (
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                              >
                                <PatientAddressSelector
                                  patient={selectedPatient}
                                  preSelectedAddress={selectedAddress ?? {}}
                                  onAddressSelected={(address) => {
                                    setSelectedAddress(address)
                                  }}
                                  onChangeAddress={() => {
                                    setSelectedAddress(undefined)
                                  }}
                                />
                              </Box>
                            )}
                            {sampleCollectionPoint === 'home_collection' && (
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                              >
                                <AgentSlotForOrder
                                  onSlotChanged={(newSlot) => {
                                    setSelectedSlot(newSlot)
                                  }}
                                />
                              </Box>
                            )}
                          </Box>
                        </Box>
                      )}
                  </Box>
                  {selectedLabOfferings &&
                    selectedLabOfferings.length > 0 &&
                    selectedPatient && (
                      <Box
                        width='40%'
                        paddingLeft={1}
                        display='flex'
                        flexDirection='column'
                      >
                        <Box display='flex' flexDirection='column' width='100%'>
                          <Box display='flex' flexDirection='row' width='100%'>
                            <WelloSelectFHIRCoding
                              title='Payment Option'
                              availableCodings={availablePaymentOptions}
                              onChanged={(paymentOption) => {
                                setSelectedPaymentOptions(paymentOption)
                              }}
                              preSelectedCoding={availablePaymentOptions[0]}
                              textFieldProps={{
                                size: 'small',
                                fullWidth: true,
                              }}
                              id='payment_type'
                            />
                          </Box>
                          {getRestrictionsFromLabOfferings(selectedLabOfferings)
                            .size > 0 && (
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                              paddingTop={2}
                            >
                              <WelloFormItemLabel title='Pretest Instructions' />
                              <RestrictionsOfSelectedOfferings
                                selectedOfferings={selectedLabOfferings}
                              />
                            </Box>
                          )}
                        </Box>
                      </Box>
                    )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {createOrderSlice.creatingAppointment && <CircularProgress size={30} />}
        {/*  <Button
          onClick={() => {
            onClose()
            resetForm()
          }}
          variant='outlined'
          disableElevation
        >
          Cancel
        </Button> */}
        <Button
          onClick={() => {
            if (selectedLabOfferings && selectedPatient) {
              dispatch(
                requestLabOrder(
                  selectedLabOfferings,
                  selectedPatient,
                  sampleCollectionPoint,
                  selectedPaymentOptions.code ?? '',
                  selectedAddress,
                  selectedSlot,
                  locationId,
                  partnerLabUser,
                  referralId,
                  encounterRef
                )
              )
            }
          }}
          variant='contained'
          color='primary'
          disableElevation
          disabled={!isEnableCreateButton()}
          id='create_order_final'
        >
          {selectedPaymentOptions.code === 'online'
            ? t('labelCommon:continue_payment')
            : t('labelCommon:create_order')}
        </Button>
      </DialogActions>
      {showAddPatient && (
        <AddNewPatient
          isLab={false}
          isAppointment={false}
          popup={showAddPatient}
          isMinimal={false}
          patientDetail={preselectedPatient}
          onPatientCreated={(
            createdPatient?: R4.IPatient,
            patientId?: string
          ) => {
            dispatch(showSuccessAlert('Patient added successfully'))

            if (createdPatient) {
              if (patientId) {
                const oldPatient: R4.IPatient = {
                  ...createdPatient,
                }
                const patId: string = patientId.split('/')[1]
                oldPatient.id = patId
                setSelectedPatient(oldPatient)
                setIsPatientEditMode(false)
                setShowAddPatient(false)
                // onPatientSelected(oldPatient)
              }
            }

            /* if (onPatientSelected && createdPatient) {
              onPatientSelected(createdPatient);
            } */
          }}
          onError={() => {
            dispatch(
              showErrorAlert('Error while adding patient. Please try later')
            )

            setShowAddPatient(false)
          }}
          onCancelClicked={() => {
            setShowAddPatient(false)
          }}
        />
      )}
    </Dialog>
  )
}
