/* eslint-disable guard-for-in */
/* eslint-disable no-alert */
/* eslint-disable no-useless-catch */
/* eslint-disable no-lone-blocks */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { doctorAppointmentView, DoctorBase } from 'lib/openApi'
import { ConsumableData } from 'models/consumableData'
import { DateWiseIPDAppointments } from 'models/dateSeparatedIPDAppointments'
import { DateWiseOrders } from 'models/dateSeparatedOrders'
import { DateWiseTherapies } from 'models/dateSeparatedTherapies'
import {
  FhirActiveIPDDetailsForMedicalRole,
  FhirAppointmentWithPatient,
  FhirIPDwithPatientDetails,
} from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirClinicIpdDetails, IpdAdmission } from 'models/fhirClinicIpdDetails'
import { FhirClinicTherapiesDetails } from 'models/fhirClinicTherapiesDetails'
import { FhirClinicTherapyBasic } from 'models/fhirClinicTherapyBasic'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { FhirLabDiagnosticRequest } from 'models/fhirLabDiagnosticRequest'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import {
  FhirLabOrderFullDetail,
  TestAndResult,
} from 'models/fhirLabOrderFullDetails'
import { FhirTherapyListWithChargeDef } from 'models/fhirTherapyList'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { ReportColumnsForAppointment } from 'models/Report/reportColumn'
import { RoomPrice } from 'models/roomPrice'
import { StatusWiseTherapies } from 'models/statusSeparatedTherapies'
import { SubModel } from 'models/subModel'
import {
  Morning,
  MorningCancelledTherapy,
  MidMornig,
  TreatmentAssemble,
  AfterNoon,
  Evening,
} from 'models/treatmentAssemble'
import { TreatmentPlanData } from 'models/treatmentPlanData'
import moment from 'moment'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserPractitionerRoleRef,
  getCurrentUserUnitDetails,
  getCurrentUserUnitReference,
  isOrgAdmin,
  isTherapist,
} from 'services/userDetailsService'
import {
  getCompleteDateStringWithOutDay,
  geTimeInString,
  getIpdInTImeWithDate,
  getIpdInTImeWithDateForReport,
  getShortTimeWithDate,
  getStartAndEndTimeInString,
  getStartAndEndTimeWithDateInString,
} from 'utils/dateUtil'
import { logger } from 'utils/logger'

import {
  getAddressOfPatient,
  getAgeOfPatientData,
  getCodeOfSystemFromCodableConcept,
  getDefaultCodeOfSystemFromCodableConcept,
  getDefaultCodeOfSystemFromCodableConceptList,
  getExtensionValueOfUrl,
  getGenderOfDoctor,
  getGenderOfPatient,
  getIdentifierValueBySystem,
  getNameFromHumanName,
  getNameOfPatient,
  getValueCodableFromExtension,
  getTelecomFromContactPoints,
  getTelecomOfPatientEdit,
  getValueCodingFromExtension,
  getValueRefValueExtensionsOfUrl,
  isMarried,
} from '../fhirResourcesHelper'
import { getUniqueTempId } from './idHelpers'
import { inBetWeenTime } from './ipdTreatmentHelper'
import {
  getLabOfferingDetailsFromBundle,
  getPriceComponentFromChangeItem,
} from './planDefinitionHelper'
import { getRoomTypeForDisplay } from './roomsHelper'

export function getDateWiseOrders(
  availableSlots: FhirLabOrderDetail[]
): DateWiseOrders[] {
  const dateWiseSlots: DateWiseOrders[] = []

  availableSlots.forEach((item) => {
    if (item.start) {
      const date = moment(item.start).format('YYYY-MM-DD')
      const index = dateWiseSlots.findIndex(
        (s) => moment(s.date).format('YYYY-MM-DD') === date
      )
      if (index < 0) {
        const dateWiseSlot: DateWiseOrders = {
          date: item.start,
          orders: [item],
        }
        dateWiseSlots.push(dateWiseSlot)
      } else if (dateWiseSlots[index]?.orders) {
        dateWiseSlots[index].orders?.push(item)
      } else {
        dateWiseSlots[index].orders = [item]
      }
    }
  })

  return dateWiseSlots
}

export function isMorningSlot(slotTime: string): boolean {
  const currentHour = moment(slotTime).format('HH') as unknown as number

  if (currentHour >= 0 && currentHour < 12) {
    return true
  }
  return false
}

export function isAfterNoonSlot(slotTime: string): boolean {
  const currentHour = moment(slotTime).format('HH') as unknown as number

  if (currentHour >= 12 && currentHour < 18) {
    return true
  }
  return false
}

export function isEvening(slotTime: string): boolean {
  const currentHour = moment(slotTime).format('HH') as unknown as number

  if (currentHour >= 18 && currentHour < 24) {
    return true
  }
  return false
}

export function getOrderCreationSuccessfulMessage(
  patient: R4.IPatient
): string {
  const message: string = `Order created for ${getNameOfPatient(patient)}`

  return message
}

export function getAppointmentSuccessfulMessage(
  patient: R4.IPatient,
  selectedDoctor: R4.IPractitioner | undefined,
  selectedSlot: R4.ISlot
): string {
  const message: string = `Appointment created for ${getNameOfPatient(
    patient
  )} with ${getNameFromHumanName(
    selectedDoctor?.name ?? []
  )} on ${dateToFromNowDaily(selectedSlot.start?.toString() ?? '')} at ${moment(
    selectedSlot.start ?? ''
  ).format('HH:mm a')}`

  return message
}
export function getAppointmentCancelMessage(
  appointmentDetails: FhirAppointmentDetail
): string {
  const message: string = `Appointment cancelled for ${getNameOfPatient(
    appointmentDetails.patient
  )} with ${getNameFromHumanName(
    appointmentDetails.practitionerDetail.practitioner.name ?? []
  )} scheduled on ${dateToFromNowDaily(
    appointmentDetails.appointment.start?.toString() ?? ''
  )} at ${moment(appointmentDetails.appointment.start ?? '').format('HH:mm a')}`

  return message
}
function dateToFromNowDaily(myDate: string) {
  // get from-now for this date
  const fromNow = moment(myDate).fromNow()

  // ensure the date is displayed with today and yesterday
  return moment(myDate).calendar(null, {
    // when the date is closer, specify custom values
    lastWeek: '[Last] dddd',
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    // when the date is further away, use from-now functionality
    sameElse() {
      return `[${fromNow}]`
    },
  })
}

export function filterAppointmentsAsPerDoctorSelection(
  selectedDoctors: DoctorBase[],
  appointments: doctorAppointmentView[]
): doctorAppointmentView[] {
  if (selectedDoctors) {
    if (selectedDoctors.length > 0) {
      const doctorIds: string[] = selectedDoctors.map((doc) => doc?.id ?? '')

      const filterOutAppointments: doctorAppointmentView[] =
        appointments.filter((item, index, arr) =>
          doctorIds.includes(item.doctorId ?? 'null')
        )

      return filterOutAppointments
    }
  }
  return appointments
}

export function getExpandedServiceRequestFromBundleForIpd(
  responseSlots: R4.IBundle
): FhirClinicIpdDetails[] {
  const convertedAppointments: FhirClinicIpdDetails[] = []
  const serviceRequests: any = {}
  const slots: any = {}
  const tasks: any = {}
  const homeCollectionTask: any = {}
  const chargeDdef: any = {}
  const loc: any = {}
  const practitioners: any = {}
  const patients: any = {}
  const practitionerRoles: any = {}
  const provenances: R4.IProvenance[] = []
  const observation: R4.IObservation[] = []
  const encounter: R4.IObservation[] = []
  const paymentReconilation: any = {}
  let count: number = 0

  if (responseSlots.entry) {
    if (responseSlots.entry.length > 0) {
      const entries: R4.IBundle_Entry[] = responseSlots.entry
      entries.forEach((value) => {
        if (value.resource) {
          if (value.resource.id) {
            switch (value.resource.resourceType) {
              case 'ServiceRequest':
                serviceRequests[value.resource.id] =
                  value.resource as R4.IServiceRequest
                break
              case 'Slot':
                slots[value.resource.id] = value.resource as R4.ISlot
                break
              case 'Practitioner':
                practitioners[value.resource.id] =
                  value.resource as R4.IPractitioner
                break
              case 'PractitionerRole':
                practitionerRoles[value.resource.id] =
                  value.resource as R4.IPractitionerRole
                break
              case 'ChargeItemDefinition':
                practitionerRoles[value.resource.id] =
                  value.resource as R4.IChargeItemDefinition
                break
              case 'Location':
                loc[value.resource.id] = value.resource as R4.ILocation
                break
              case 'Observation':
                observation.push(value.resource as R4.IObservation)
                break
              case 'Task':
                if (value.resource.status === R4.TaskStatusKind._accepted) {
                  homeCollectionTask[
                    value.resource.focus?.reference?.split('/')[1] ?? ''
                  ] = value.resource as R4.ITask
                }

                break
              case 'Patient':
                patients[value.resource.id] = value.resource as R4.IPatient
                break

              case 'Provenance':
                provenances.push(value.resource as R4.IProvenance)
                break
              case 'PaymentReconciliation':
                paymentReconilation[value.resource.id] =
                  value.resource as R4.IPaymentReconciliation
                break
              case 'Invoice':
                break
              default:
                break
            }
          }
        }
      })

      let notesData: string = ''
      let performer: string = ''
      let paymentId: string = ''

      for (const key in serviceRequests) {
        if (key) {
          const currentAppointment: R4.IServiceRequest = serviceRequests[
            key
          ] as R4.IServiceRequest
          const appointmentType = currentAppointment.code
            ? currentAppointment.code.coding
              ? currentAppointment.code.coding[0].code ?? ''
              : ''
            : ''

          if (currentAppointment.status) {
            if (currentAppointment.status === 'completed') {
              for (const key1 in paymentReconilation) {
                if (key1) {
                  const paymentInfo: R4.IPaymentReconciliation =
                    paymentReconilation[key1] as R4.IPaymentReconciliation

                  const chargeID: string | undefined =
                    getIdentifierValueBySystem(
                      paymentInfo.identifier ?? [],
                      appointmentType === '304903009' ||
                        appointmentType === '33022008'
                        ? 'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
                        : 'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
                    )
                  const appId: string | undefined = getIdentifierValueBySystem(
                    currentAppointment.identifier ?? [],
                    appointmentType === '304903009' ||
                      appointmentType === '33022008'
                      ? 'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
                      : 'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
                  )
                  if (chargeID === appId) {
                    paymentId = paymentInfo.id ?? ''
                  }
                }
              }
            }
          }

          const locIdData: string | undefined =
            currentAppointment.locationReference
              ? currentAppointment.locationReference[0].reference?.split('/')[1]
              : undefined
          let slotId: string = ''

          let practitionerId: string | undefined

          let practitionerRoleId: string | undefined

          let practitionerRoleIdAdmin: string | undefined

          const patientId: string | undefined =
            currentAppointment?.subject.reference?.split('/')[1]
          let startTime =
            (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
            ''
          let endTime =
            (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
            ''
          const serviceType: R4.ICoding | undefined =
            getCodeOfSystemFromCodableConcept(
              (serviceRequests[key] as R4.IServiceRequest).code ?? {},
              'http://wellopathy.com/fhir/india/core/CodeSystem/lab-service-type'
            )

          startTime =
            (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
              ?.start ?? ''
          endTime =
            (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
              ?.end ?? ''

          const owner1 = homeCollectionTask[key] as R4.ITask
          if (owner1) {
            const slotRef: R4.IReference | undefined =
              getInputIdentifierValueByCodemOfInvitation(
                owner1,
                'slot-reference'
              )?.valueReference
            if (slotRef) {
              slotId = slotRef.reference?.split('/')[1] ?? ''
            }

            if ((serviceRequests[key] as R4.IServiceRequest).performer) {
              if (currentAppointment.performer)
                practitionerRoleId =
                  currentAppointment.performer[0].reference?.split('/')[1] ?? ''
            }
          }

          if (practitionerRoleId) {
            const practRole: R4.IPractitionerRole = practitionerRoles[
              practitionerRoleId
            ] as R4.IPractitionerRole

            if (practRole) {
              practitionerId = (
                practitionerRoles[practitionerRoleId] as R4.IPractitionerRole
              ).practitioner?.reference?.split('/')[1]
            }
          }

          const status = provenances.filter((e) => {
            const res = e.target.findIndex((ref) => {
              if (ref.reference)
                return (
                  ref.reference.split('/')[1] === homeCollectionTask[key].id
                )

              return false
            })
            return res > -1
          })
          status.sort((a, b) =>
            moment(a.occurredDateTime).diff(b.occurredDateTime)
          )
          for (const key1 in observation) {
            if (key1) {
              const obs: R4.IObservation = observation[key1] as R4.IObservation

              if (obs.basedOn) {
                const refData = obs.basedOn[0].reference?.split('/')[1] ?? ''
                if (refData === currentAppointment.id) {
                  const codeData = getDefaultCodeOfSystemFromCodableConceptList(
                    obs.category ?? []
                  )
                  if (codeData === '11535-2') {
                    const taskData: R4.ITask | undefined =
                      homeCollectionTask[key]

                    if (taskData) {
                      if (taskData.businessStatus) {
                        if (taskData.businessStatus.coding) {
                          if (
                            taskData.businessStatus.coding[0].code ===
                              'initiate-discharge' ||
                            taskData.businessStatus.coding[0].code ===
                              'discharged'
                          )
                            notesData = obs.valueString ?? ''
                          performer = obs.performer
                            ? obs.performer[0].display ?? ''
                            : ''
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          status.sort((a, b) =>
            moment(a.occurredDateTime).diff(b.occurredDateTime)
          )

          const ipd: string = getIdentifierValueBySystem(
            serviceRequests[key].identifier ?? [],
            'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
          )!

          if (!isTherapist()) {
            convertedAppointments.push({
              count,
              start: startTime,
              end: endTime,
              performerDetail: {
                practitioner: practitioners[practitionerId ?? ''],
                practitionerRole: practitionerRoles[practitionerRoleId ?? ''],
              },
              task: homeCollectionTask[key],
              serviceRequest: serviceRequests[key],
              dispositionByDetails: performer,
              patient: patients[patientId ?? ''],
              statuses: status,
              isSelected: false,
              oldSlotRef: slotId,
              paymentReconilation:
                currentAppointment.status === 'completed'
                  ? paymentReconilation[paymentId ?? '']
                  : undefined,
              notes: notesData,
              ipdNumber: ipd ? parseInt(ipd.split('/')[4], 10) : 0,
              type:
                appointmentType === '304903009' ||
                appointmentType === '33022008'
                  ? 'opd'
                  : 'ipd',
              appType:
                appointmentType === '304903009'
                  ? 'Day Care'
                  : appointmentType === '33022008'
                  ? 'Consultaion'
                  : '',
              location: loc[locIdData ?? ''],
            })
          } else {
            const currentPractitioner: R4.IPractitioner =
              getCurrentUserPractitionerDetails()
            const gender = getGenderOfDoctor(currentPractitioner)
            if (gender === 'male') {
              const patient: R4.IPatient = patients[patientId ?? '']
              const patGender = getGenderOfPatient(patient)

              if (patGender === 'male') {
                convertedAppointments.push({
                  count,
                  start: startTime,
                  end: endTime,
                  performerDetail: {
                    practitioner: practitioners[practitionerId ?? ''],
                    practitionerRole:
                      practitionerRoles[practitionerRoleId ?? ''],
                  },
                  task: homeCollectionTask[key],
                  serviceRequest: serviceRequests[key],
                  dispositionByDetails: performer,
                  patient: patients[patientId ?? ''],
                  statuses: status,
                  isSelected: false,
                  oldSlotRef: slotId,
                  paymentReconilation: paymentReconilation[paymentId ?? ''],
                  notes: notesData,
                  ipdNumber: parseInt(ipd.split('/')[3], 10),
                  type:
                    appointmentType === '304903009' ||
                    appointmentType === '33022008'
                      ? 'opd'
                      : 'ipd',
                  appType:
                    appointmentType === '304903009'
                      ? 'Day Care'
                      : appointmentType === '33022008'
                      ? 'Consultaion'
                      : '',
                  location: loc[locIdData ?? ''],
                })
              }
            }

            if (gender === 'female') {
              const patient: R4.IPatient = patients[patientId ?? '']
              const patGender = getGenderOfPatient(patient)
              if (patGender === 'female') {
                convertedAppointments.push({
                  count,
                  start: startTime,
                  end: endTime,
                  performerDetail: {
                    practitioner: practitioners[practitionerId ?? ''],
                    practitionerRole:
                      practitionerRoles[practitionerRoleId ?? ''],
                  },
                  task: homeCollectionTask[key],
                  serviceRequest: serviceRequests[key],

                  patient: patients[patientId ?? ''],
                  statuses: status,
                  isSelected: false,
                  dispositionByDetails: performer,
                  oldSlotRef: slotId,
                  paymentReconilation: paymentReconilation[paymentId ?? ''],
                  notes: notesData,
                  ipdNumber: parseInt(ipd.split('/')[3], 10),
                  type:
                    appointmentType === '304903009' ||
                    appointmentType === '33022008'
                      ? 'opd'
                      : 'ipd',
                  appType:
                    appointmentType === '304903009'
                      ? 'Day Care'
                      : appointmentType === '33022008'
                      ? 'Consultaion'
                      : '',
                  location: loc[locIdData ?? ''],
                })
              }
            }
          }
        }
        count += 1
      }
    }
  }

  convertedAppointments.sort((n1, n2) => n1.ipdNumber - n2.ipdNumber)
  return convertedAppointments
}

export function getTherapiesFromBundleForIpd(
  responseSlots: R4.IBundle
): FhirClinicTherapiesDetails[] {
  const convertedAppointments: FhirClinicTherapiesDetails[] = []
  const serviceRequests: any = {}
  const slots: any = {}
  const tasks: any = {}
  const homeCollectionTask: any = {}
  const chargeDdef: any = {}

  const patients: any = {}

  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ServiceRequest':
                  serviceRequests[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break

                case 'Task':
                  if (value.resource.status === R4.TaskStatusKind._accepted) {
                    homeCollectionTask[
                      value.resource.focus?.reference?.split('/')[1] ?? ''
                    ] = value.resource as R4.ITask
                  }

                  break
                case 'Patient':
                  patients[value.resource.id] = value.resource as R4.IPatient
                  break

                default:
                  break
              }
            }
          }
        })

        for (const key in serviceRequests) {
          if (key) {
            const currentAppointment: R4.IServiceRequest = serviceRequests[
              key
            ] as R4.IServiceRequest

            if (
              currentAppointment.basedOn &&
              currentAppointment.basedOn.length > 0
            ) {
              const index = currentAppointment.basedOn.findIndex((e) => {
                if (
                  e.reference &&
                  e.reference.toLowerCase().includes('servicerequest')
                ) {
                  return true
                }
                return false
              })
              if (index > -1) {
                const mainServiceRequest: R4.IServiceRequest = serviceRequests[
                  currentAppointment.basedOn[index].reference!.split('/')[1]
                ] as R4.IServiceRequest
                const locIdData: string | undefined =
                  mainServiceRequest.locationReference
                    ? mainServiceRequest.locationReference[0].reference?.split(
                        '/'
                      )[1]
                    : undefined
                let slotId: string = ''
                const appointmentType = mainServiceRequest.code
                  ? mainServiceRequest.code.coding
                    ? mainServiceRequest.code.coding[0].code ?? ''
                    : ''
                  : ''

                const patientId: string | undefined =
                  mainServiceRequest?.subject.reference?.split('/')[1]
                let startTime =
                  (serviceRequests[key] as R4.IServiceRequest)
                    .occurrenceDateTime ?? ''
                let endTime =
                  (serviceRequests[key] as R4.IServiceRequest)
                    .occurrenceDateTime ?? ''
                const serviceType: R4.ICoding | undefined =
                  getCodeOfSystemFromCodableConcept(
                    (serviceRequests[key] as R4.IServiceRequest).code ?? {},
                    'http://wellopathy.com/fhir/india/core/CodeSystem/lab-service-type'
                  )

                startTime =
                  (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                    ?.start ?? ''
                endTime =
                  (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                    ?.end ?? ''

                const owner1 = homeCollectionTask[key] as R4.ITask
                if (owner1) {
                  const slotRef: R4.IReference | undefined =
                    getInputIdentifierValueByCodemOfInvitation(
                      owner1,
                      'slot-reference'
                    )?.valueReference
                  if (slotRef) {
                    slotId = slotRef.reference?.split('/')[1] ?? ''
                  }
                }

                const ipd: string = getIdentifierValueBySystem(
                  mainServiceRequest.identifier ?? [],
                  'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
                )!

                const currentPractitioner: R4.IPractitioner =
                  getCurrentUserPractitionerDetails()
                const gender = getGenderOfDoctor(currentPractitioner)
                const patient: R4.IPatient = patients[patientId ?? '']
                const patGender = getGenderOfPatient(patient)

                convertedAppointments.push({
                  therapy: serviceRequests[key],
                  start: startTime,
                  end: endTime,
                  mainServiceRequest,
                  patient: patients[patientId ?? ''],

                  ipdNumber: parseInt(ipd.split('/')[3], 10),
                  type:
                    appointmentType === '304903009' ||
                    appointmentType === '33022008'
                      ? 'opd'
                      : 'ipd',
                  appType:
                    appointmentType === '304903009'
                      ? 'Day Care'
                      : appointmentType === '33022008'
                      ? 'Consultaion'
                      : '',
                })
              }
            }
          }
        }
      }
    }
  }
  convertedAppointments.sort((n1, n2) => n1.ipdNumber - n2.ipdNumber)
  return convertedAppointments
}

export function getTherapiesBasicsFromBundleForIpd(
  responseSlots: R4.IBundle,
  gender: string
): FhirClinicTherapyBasic[] {
  const convertedAppointments: FhirClinicTherapyBasic[] = []
  const serviceRequests: any = {}
  const procedures: R4.IProcedure[] = []

  if (responseSlots.entry) {
    const entries: R4.IBundle_Entry[] = responseSlots.entry
    entries.forEach((value) => {
      if (value.resource) {
        if (value.resource.id) {
          switch (value.resource.resourceType) {
            case 'ServiceRequest':
              serviceRequests[value.resource.id] =
                value.resource as R4.IServiceRequest
              break
            case 'Procedure':
              procedures.push(value.resource as R4.IProcedure)
              break
            default:
              break
          }
        }
      }
    })

    for (const key in serviceRequests) {
      if (key) {
        const currentAppointment: R4.IServiceRequest = serviceRequests[
          key
        ] as R4.IServiceRequest

        if (
          getValueRefValueExtensionsOfUrl(
            currentAppointment.extension ?? [],
            'http://hl7.org/fhir/StructureDefinition/event-partOf'
          ) === undefined ||
          getValueRefValueExtensionsOfUrl(
            currentAppointment.extension ?? [],
            'http://hl7.org/fhir/StructureDefinition/event-partOf'
          ).length === 0
        ) {
          if (
            currentAppointment.basedOn &&
            currentAppointment.basedOn.length > 0
          ) {
            const index = currentAppointment.basedOn.findIndex((e) => {
              if (
                e.reference &&
                e.reference.toLowerCase().includes('servicerequest')
              ) {
                return true
              }
              return false
            })
            if (index > -1) {
              let startTime =
                (serviceRequests[key] as R4.IServiceRequest)
                  .occurrenceDateTime ?? ''
              let endTime =
                (serviceRequests[key] as R4.IServiceRequest)
                  .occurrenceDateTime ?? ''

              startTime =
                (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                  ?.start ?? ''
              endTime =
                (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                  ?.end ?? ''
              const procedureIndex = procedures.findIndex((e) => {
                if (e.basedOn && e.basedOn.length > 0) {
                  const srIndex = e.basedOn.findIndex((ref) => {
                    if (ref.reference && ref.reference.length > 0) {
                      const id = ref.reference.split('/')[1]
                      return id === serviceRequests[key].id
                    }

                    return false
                  })
                  return srIndex > -1
                }
                return false
              })

              convertedAppointments.push({
                therapy: serviceRequests[key],
                start: startTime,
                end: endTime,
                procedure:
                  procedureIndex > -1 ? procedures[procedureIndex] : undefined,
                mainServiceRequestId:
                  currentAppointment.basedOn[index].reference!.split('/')[1],
                patientReference: currentAppointment.subject.reference ?? '',
              })
            }
          }
        } else {
          let startTime =
            (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
            ''
          let endTime =
            (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
            ''

          startTime =
            (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
              ?.start ?? ''
          endTime =
            (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
              ?.end ?? ''
          const procedureIndex = procedures.findIndex((e) => {
            if (e.basedOn && e.basedOn.length > 0) {
              const srIndex = e.basedOn.findIndex((ref) => {
                if (ref.reference && ref.reference.length > 0) {
                  const id = ref.reference.split('/')[1]
                  return id === serviceRequests[key].id
                }

                return false
              })
              return srIndex > -1
            }
            return false
          })

          convertedAppointments.push({
            therapy: serviceRequests[key],
            start: startTime,
            end: endTime,
            procedure:
              procedureIndex > -1 ? procedures[procedureIndex] : undefined,
            mainServiceRequestId: getValueRefValueExtensionsOfUrl(
              currentAppointment.extension ?? [],
              'http://hl7.org/fhir/StructureDefinition/event-partOf'
            ).split('/')[1],
            patientReference: currentAppointment.subject.reference ?? '',
          })
        }
      }
    }
  }

  return convertedAppointments
}
export function getUserDetailAndAppointmentDetail(
  responseSlots: FhirAppointmentDetail
): string {
  let text: string = ''
  if (responseSlots.patient.maritalStatus) {
    text = isMarried(responseSlots.patient.maritalStatus)
      ? 'Married, '
      : 'Single, '
  }
  text = `${text}Appointment ,${moment(responseSlots.start).format(
    'h:mm a'
  )} - ${moment(responseSlots.end).format('h:mm a')}`
  return text
}

export function getOrderTypeCode(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: R4.ICoding | undefined = getCodeOfSystemFromCodableConcept(
    labOrderDetails.serviceRequest.code ?? {},
    'http://wellopathy.com/fhir/india/core/CodeSystem/lab-service-type'
  )
  return code?.code
}

export function getCheckOutTIme(
  labOrderDetails: FhirClinicIpdDetails
): string | undefined {
  return `${getIpdInTImeWithDate(
    labOrderDetails.serviceRequest.occurrencePeriod?.end ?? ''
  )}`
}

export function getCheckOutTImeForOrg(
  labOrderDetails: FhirClinicIpdDetails
): string | undefined {
  return `${getIpdInTImeWithDate(
    labOrderDetails.serviceRequest.occurrencePeriod?.end ?? ''
  )}`
}

export function getCheckOutTImeReport(
  labOrderDetails: FhirClinicIpdDetails
): string | undefined {
  return `${getIpdInTImeWithDateForReport(
    labOrderDetails.serviceRequest.occurrencePeriod?.end ?? ''
  )}`
}

export function getCheckOutTImeReportForDashboard(
  labOrderDetails: FhirClinicIpdDetails
): string | undefined {
  return `${getIpdInTImeWithDate(
    labOrderDetails.serviceRequest.occurrencePeriod?.end ?? ''
  )}`
}

export function getAppointmentEndTIme(
  labOrderDetails: FhirClinicIpdDetails
): string | undefined {
  return `${moment(
    labOrderDetails.serviceRequest.occurrencePeriod?.end ?? ''
  ).format('hh:mm A')}`
}

export function getAdmissionBy(
  labOrderDetails: FhirClinicIpdDetails
): string | undefined {
  let name: string = ''
  if (
    labOrderDetails.performerDetail.practitioner &&
    labOrderDetails.performerDetail.practitioner.name
  ) {
    name =
      getNameFromHumanName(
        labOrderDetails.performerDetail.practitioner.name ?? []
      ) ?? ''
  }

  return name || ''
}

export function getDispositionBy(
  labOrderDetails: FhirClinicIpdDetails
): string | undefined {
  let name: string = ''
  if (labOrderDetails.statuses) {
    if (labOrderDetails.statuses.length > 0) {
      const id = labOrderDetails.statuses[0].agent[0].who.id!
      if (labOrderDetails.performerDetail.practitionerRole.id === id) {
        name =
          labOrderDetails.performerDetail.practitionerRole.practitioner
            ?.display ?? ''
      }
    }
  }
  return name
}

export function getDispositionTime(
  labOrderDetails: FhirClinicIpdDetails
): string | undefined {
  return labOrderDetails.serviceRequest.meta
    ? labOrderDetails.serviceRequest.meta.lastUpdated
      ? moment(labOrderDetails.serviceRequest.meta.lastUpdated).format(
          'DD-MM-YYYY hh:mm A'
        )
      : ''
    : ''
}

export function getNotes(
  labOrderDetails: FhirClinicIpdDetails
): string | undefined {
  return ''
}

export function getIpdAdmitTIme(
  labOrderDetails: FhirClinicIpdDetails
): string | undefined {
  return `${getIpdInTImeWithDate(
    labOrderDetails.serviceRequest.occurrencePeriod?.start ?? ''
  )}`
}

export function getIpdAdmitTImeForOrg(
  labOrderDetails: R4.IServiceRequest
): string | undefined {
  return `${getIpdInTImeWithDate(
    labOrderDetails.occurrencePeriod?.start ?? ''
  )}`
}

export function getIpdAdmitTImeForReport(
  labOrderDetails: FhirClinicIpdDetails
): string | undefined {
  return `${getIpdInTImeWithDateForReport(
    labOrderDetails.serviceRequest.occurrencePeriod?.start ?? ''
  )}`
}

export function getIpdAdmitTImeForReportForDashboard(
  labOrderDetails: FhirClinicIpdDetails
): string | undefined {
  return `${getIpdInTImeWithDate(
    labOrderDetails.serviceRequest.occurrencePeriod?.start ?? ''
  )}`
}

export function getOPDStartDate(
  labOrderDetails: FhirClinicIpdDetails
): string | undefined {
  return `${moment(
    labOrderDetails.serviceRequest.occurrencePeriod?.start ?? ''
  ).format('DD-MM-YYYY')}`
}

export function getOPDStartTIme(
  labOrderDetails: FhirClinicIpdDetails
): string | undefined {
  return `${moment(
    labOrderDetails.serviceRequest.occurrencePeriod?.start ?? ''
  ).format('hh:mm A')}`
}

export function getRoomNo(
  labOrderDetails: FhirClinicIpdDetails
): string | undefined {
  let rommData = ''
  if (labOrderDetails.location) {
    rommData = `Room # ${
      labOrderDetails.location
        ? labOrderDetails.location.alias
          ? getRoomTypeForDisplay(labOrderDetails.location.alias[0])
          : ''
        : ''
    } - ${
      labOrderDetails.location
        ? labOrderDetails.location.alias
          ? labOrderDetails.location.alias[1]
          : ''
        : ''
    }`
  }

  return rommData
}

export function getRoomNoForOrg(
  labOrderDetails: R4.ILocation
): string | undefined {
  let rommData = ''

  rommData = `Room # ${
    labOrderDetails
      ? labOrderDetails.alias
        ? getRoomTypeForDisplay(labOrderDetails.alias[0])
        : ''
      : ''
  } - ${
    labOrderDetails
      ? labOrderDetails.alias
        ? labOrderDetails.alias[1]
        : ''
      : ''
  }`

  return rommData
}

export function getRoomNoForReport(
  labOrderDetails: FhirClinicIpdDetails
): string | undefined {
  return `# ${
    labOrderDetails.location
      ? labOrderDetails.location.alias
        ? labOrderDetails.location.alias[0]
        : ''
      : ''
  } - ${
    labOrderDetails.location
      ? labOrderDetails.location.alias
        ? labOrderDetails.location.alias[1]
        : ''
      : ''
  }`
}

export function getRoomNoForSheet(location: R4.ILocation): string | undefined {
  if (location) {
    return `${location ? (location.alias ? location.alias[1] : '') : ''}`
  }
  return ''
}

export function getRoomNoFromLocation(loc?: R4.ILocation): string | undefined {
  return `Room # ${
    loc ? (loc.alias ? getRoomTypeForDisplay(loc.alias[0]) : '') : ''
  } - ${loc ? (loc.alias ? loc.alias[1] : '') : ''}`
}

export function isOPD(labOrderDetails: FhirClinicIpdDetails): boolean {
  const appType = labOrderDetails.serviceRequest.code
    ? labOrderDetails.serviceRequest.code.coding
      ? labOrderDetails.serviceRequest.code.coding[0].code ?? ''
      : ''
    : ''
  const ipdDayCare: boolean = appType === '304903009' || appType === '33022008'
  return ipdDayCare
}

export function isOPDForCheck(labOrderDetails: FhirClinicIpdDetails): boolean {
  let ipdDayCare: boolean = false
  if (labOrderDetails.serviceRequest.code) {
    const codingData: R4.ICoding[] =
      labOrderDetails.serviceRequest.code.coding ?? []
    if (codingData.length > 0) {
      for (let i = 0; i < codingData.length; i++) {
        if (
          codingData[i].code === '304903009' ||
          codingData[i].code === '33022008'
        )
          ipdDayCare = true
      }
    }
  }

  return ipdDayCare
}

export function getIdentificationNumber(
  labOrderDetails: FhirClinicIpdDetails
): string {
  if (isOPD(labOrderDetails)) {
    return (
      getIdentifierValueBySystem(
        labOrderDetails.serviceRequest.identifier ?? [],
        'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
      ) ?? ''
    )
  }
  return (
    getIdentifierValueBySystem(
      labOrderDetails.serviceRequest.identifier ?? [],
      'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
    ) ?? ''
  )
}

export function getOrderStartTime(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: string | undefined = getOrderTypeCode(labOrderDetails)
  if (code === 'onsite-sample-collection') {
    return geTimeInString(
      labOrderDetails.serviceRequest.occurrenceDateTime ?? ''
    )
  }
  if (code === 'home-sample-collection') {
    return geTimeInString(
      labOrderDetails.serviceRequest.occurrencePeriod?.start ?? ''
    )
  }
  return 'onsite-sample-collection'
}

export function getOrderStartDate(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: string | undefined = getOrderTypeCode(labOrderDetails)
  if (code === 'onsite-sample-collection') {
    return getCompleteDateStringWithOutDay(
      labOrderDetails.serviceRequest.occurrenceDateTime ?? ''
    )
  }
  if (code === 'home-sample-collection') {
    return getCompleteDateStringWithOutDay(
      labOrderDetails.serviceRequest.occurrencePeriod?.start ?? ''
    )
  }
  return 'onsite-sample-collection'
}

export function getOrderDate(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: string | undefined = getOrderTypeCode(labOrderDetails)
  if (code === 'onsite-sample-collection') {
    return getCompleteDateStringWithOutDay(
      labOrderDetails.serviceRequest.occurrenceDateTime ?? ''
    )
  }
  if (code === 'home-sample-collection') {
    return getStartAndEndTimeInString(
      labOrderDetails.serviceRequest.occurrencePeriod?.start ?? '',
      labOrderDetails.serviceRequest.occurrencePeriod?.end ?? ''
    )
  }
  return 'onsite-sample-collection'
}

export function getOrderTimeWithDate(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: string | undefined = getOrderTypeCode(labOrderDetails)
  if (code === 'onsite-sample-collection') {
    return getShortTimeWithDate(
      labOrderDetails.serviceRequest.occurrenceDateTime ?? ''
    )
  }
  if (code === 'home-sample-collection') {
    return getStartAndEndTimeWithDateInString(
      labOrderDetails.serviceRequest.occurrencePeriod?.start ?? '',
      labOrderDetails.serviceRequest.occurrencePeriod?.end ?? ''
    )
  }
  return 'onsite-sample-collection'
}

export function getTestsOfOrder(
  labOrderDetail: FhirLabOrderDetail
): string | undefined {
  return labOrderDetail.tests
    ?.map((e) => e?.planDefinition.title ?? e?.planDefinition.name)
    .join(', ')
}

export function isLabOrderEditable(
  labOrderDetail: FhirLabOrderDetail
): boolean {
  if (labOrderDetail.serviceRequest.status === 'active') {
    const code: string | undefined = getOrderTypeCode(labOrderDetail)

    if (code === 'onsite-sample-collection') {
      return true
    }

    if (
      moment(labOrderDetail.serviceRequest.occurrencePeriod?.start).isAfter(
        new Date()
      )
    ) {
      return true
    }
  }

  return false
}

export function isLabOrderStatusChangeable(
  labOrderDetail: FhirLabOrderDetail
): boolean {
  if (labOrderDetail.serviceRequest.status === 'active') {
    const code: string | undefined = getOrderTypeCode(labOrderDetail)

    // if (code === 'onsite-sample-collection') {
    //     return true
    // }

    if (
      moment(labOrderDetail.serviceRequest.occurrencePeriod?.start).isSame(
        new Date()
      )
    ) {
      return true
    }
  }

  return false
}

export function isPartnerLabStatusChangeable(
  labOrderDetail: FhirLabOrderDetail
): boolean {
  if (labOrderDetail.task) {
    if (labOrderDetail.task.status === 'received') {
      return true
    }

    if (
      moment(
        labOrderDetail.serviceRequest.occurrencePeriod?.start
      ).isSameOrBefore(new Date())
    ) {
      return true
    }
  }

  return false
}

export function isPartnerOrderEditable(
  labOrderDetail: FhirLabOrderDetail
): boolean {
  if (labOrderDetail.task) {
    if (labOrderDetail.task.status === 'received') {
      return true
    }

    //  if (
    //    moment(
    //      labOrderDetail.serviceRequest.occurrencePeriod?.start
    //    ).isSameOrAfter(new Date())
    //  ) {
    //    return true
    //  }
  }

  return false
}

export function getTotalSpeiciment(
  labOrderDetails: FhirLabOrderDetail[]
): number {
  let count: number = 0
  for (let i = 0; i < labOrderDetails.length; i++) {
    if (labOrderDetails[i].tests) count += labOrderDetails[i].tests?.length ?? 0
  }
  return count
}

export function getTotalAmountOfLabOfferings(
  selectedLabOfferings: LabOfferingDetail[]
): number {
  let total: number = 0
  selectedLabOfferings.forEach((e) => {
    if (e.chargeItem) {
      const priceComponent:
        | R4.IChargeItemDefinition_PriceComponent
        | undefined = getPriceComponentFromChangeItem(e.chargeItem)
      if (
        priceComponent &&
        priceComponent.amount &&
        priceComponent.amount.value
      ) {
        total += priceComponent.amount.value
      }
    }
  })
  return total
}

export function getTotalAmount(labOrderDetails: FhirLabOrderDetail[]): number {
  let count: number = 0
  for (let i = 0; i < labOrderDetails.length; i++) {
    count += getTotalAmountOfLabOfferings(labOrderDetails[i].tests ?? [])
  }
  return count
}

export function isHomeOrder(labOrderDetail: FhirLabOrderDetail): boolean {
  if (labOrderDetail.serviceRequest.status === 'active') {
    const code: string | undefined = getOrderTypeCode(labOrderDetail)

    if (code === 'home-sample-collection') {
      return true
    }
  }

  return false
}

export function isOnsiteOrder(labOrderDetail: FhirLabOrderDetail): boolean {
  if (labOrderDetail.serviceRequest.status === 'active') {
    const code: string | undefined = getOrderTypeCode(labOrderDetail)

    if (code === 'onsite-sample-collection') {
      return true
    }
  }

  return false
}

export function getInputIdentifierValueBySystemOfInvitation(
  task: R4.ITask,
  system: string
): string | undefined {
  if (task && task.input && task.input.length > 0) {
    const emailInput = task.input.find(
      (e) => e.valueIdentifier?.system === system
    )
    if (emailInput) {
      return emailInput.valueIdentifier?.value
    }
  }

  return undefined
}

export function getInputIdentifierValueByCodemOfInvitation(
  task: R4.ITask,
  code: string
): R4.ITask_Input | undefined {
  if (task && task.input && task.input.length > 0) {
    const emailInput = task.input.find((e) => e.type?.coding?.[0].code === code)
    if (emailInput) {
      return emailInput
    }
  }

  return undefined
}

export function getProvenanceObjectForOrder(
  orderDetail: FhirLabOrderDetail,
  status: R4.ICoding
) {
  const currentUserDetails: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const mainProvenanceId: string = getUniqueTempId()
  const statusProvenance: R4.IProvenance = {
    id: mainProvenanceId,
    resourceType: 'Provenance',
    occurredDateTime: new Date().toISOString(),

    activity: {
      text: status.display ?? '',
      coding: [status],
    },
    agent: [
      {
        type: {
          coding: [
            {
              code: 'enterer',
              display: 'Enterer',
              system:
                'http://terminology.hl7.org/CodeSystem/provenance-participant-type',
            },
          ],
        },
        who: {
          id: currentUserDetails.id,
          reference:
            `${currentUserDetails.resourceType}/${currentUserDetails.id}` ?? '',
        },
      },
    ],
    target: [
      {
        id: orderDetail.serviceRequest.id,
        reference:
          `${orderDetail.serviceRequest.resourceType}/${orderDetail.serviceRequest.id}` ??
          '',
      },
      {
        id: orderDetail.task?.id,
        reference:
          `${orderDetail.task?.resourceType}/${orderDetail.task?.id}` ?? '',
      },
    ],
  }

  return statusProvenance
}

export function getProvenanceObjectForTask(task: R4.ITask, status: R4.ICoding) {
  const currentUserDetails: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const mainProvenanceId: string = getUniqueTempId()
  const statusProvenance: R4.IProvenance = {
    id: mainProvenanceId,
    resourceType: 'Provenance',
    occurredDateTime: new Date().toISOString(),

    activity: {
      text: status.display ?? '',
      coding: [status],
    },
    agent: [
      {
        type: {
          coding: [
            {
              code: 'enterer',
              display: 'Enterer',
              system:
                'http://terminology.hl7.org/CodeSystem/provenance-participant-type',
            },
          ],
        },
        who: {
          id: currentUserDetails.id,
          reference:
            `${currentUserDetails.resourceType}/${currentUserDetails.id}` ?? '',
        },
      },
    ],
    target: [
      {
        id: task.id,
        reference: `${task.resourceType}/${task.id}` ?? '',
      },
    ],
  }

  return statusProvenance
}

export function getProvenanceObjectForPartnerTask(
  task: R4.ITask,
  status: R4.ICoding
) {
  const currentUserDetails: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const mainProvenanceId: string = getUniqueTempId()
  const statusProvenance: R4.IProvenance = {
    id: mainProvenanceId,
    resourceType: 'Provenance',
    occurredDateTime: new Date().toISOString(),

    activity: {
      text: status.display ?? '',
      coding: [status],
    },
    agent: [
      {
        type: {
          coding: [
            {
              code: 'enterer',
              display: 'Enterer',
              system:
                'http://terminology.hl7.org/CodeSystem/provenance-participant-type',
            },
          ],
        },
        who: {
          id: currentUserDetails.id,
          reference:
            `${currentUserDetails.resourceType}/${currentUserDetails.id}` ?? '',
        },
      },
    ],
    target: [
      {
        id: task.id,
        reference: `${task.resourceType}/${task.id}` ?? '',
      },
    ],
  }

  return statusProvenance
}

export function getOrderStatusText(labOrder: FhirLabOrderDetail): string {
  const statusName: string = ''
  switch (labOrder.serviceRequest.status) {
    case 'active':
      return 'Scheduled'
    case 'revoked':
      return 'Cancelled'

    default:
      break
  }
  return statusName
}

export function getOrderFinalStatusText(labOrder: FhirLabOrderDetail): string {
  let statusName: string = ''
  if (labOrder.task) {
    switch (labOrder.task.status) {
      case 'accepted':
        return 'Scheduled'
      case 'cancelled':
        return 'Cancelled'
      case 'rejected':
        if (labOrder.task.businessStatus) {
          if (labOrder.task.businessStatus.coding) {
            if (labOrder.task.businessStatus.coding[0].code === 'rejected')
              statusName = 'Sample(s) Rejected'
            else statusName = 'Pickup Aborted by Agent'
          }
        }
        return statusName

      case 'received':
        return 'Sample Received'
      case 'draft':
        return 'Processing Sample (s)'
      case 'in-progress':
        return 'Pickup in Progress'
      case 'completed':
        return 'Report Delivered'

      default:
        break
    }
  }
  return statusName
}

export function getOrderAgentStatusText(labOrder: FhirLabOrderDetail): string {
  const statusName: string = ''
  if (labOrder.homeServiceTask) {
    switch (labOrder.homeServiceTask.status) {
      case 'accepted':
        return 'Scheduled'
      case 'completed':
        return 'Completed'
      case 'rejected':
        return 'Aborted'
      case 'in-progress':
        return 'Pickup In Progress'

      default:
        break
    }
  }
  return statusName
}

export function getPartnerOrderStatusText(
  labOrder: FhirLabOrderDetail
): string {
  const statusName: string = ''
  if (labOrder.partnerLabTask) {
    switch (labOrder.partnerLabTask.status) {
      case 'accepted':
        return 'Scheduled'
      case 'received':
        return 'Sample Received'
      case 'in-progress':
        return 'Processing Sample'
      case 'ready':
        return 'Sample Processed'
      case 'completed':
        return 'Report Uploaded'
      default:
        break
    }
  }
  return statusName
}

export function getChargeItemForSelectedPlans(
  selectedPlans: R4.IChargeItemDefinition[],
  subject: R4.IPatient,
  serviceRequestRef: R4.IReference,
  deductibles?: R4.IChargeItemDefinition[],
  additions?: R4.IChargeItemDefinition[]
): R4.IChargeItem {
  let chargeDefinitionReferences: string[] = []

  if (selectedPlans.length > 0) {
    const planRefs: string[] = selectedPlans.map(
      (e) => `${e.resourceType}/${e.id}`
    )

    chargeDefinitionReferences = [...chargeDefinitionReferences, ...planRefs]
  }

  logger.info(chargeDefinitionReferences)
  if (deductibles && deductibles.length > 0) {
    chargeDefinitionReferences = [
      ...chargeDefinitionReferences,
      ...deductibles.map((e) => `${e.resourceType}/${e.id}`),
    ]
  }

  logger.info(chargeDefinitionReferences)

  if (additions && additions.length > 0) {
    chargeDefinitionReferences = [
      ...chargeDefinitionReferences,
      ...additions.map((e) => `${e.resourceType}/${e.id}`),
    ]
  }

  const chargeItem: R4.IChargeItem = {
    resourceType: 'ChargeItem',
    status: R4.ChargeItemStatusKind._billed,
    enterer: getCurrentUserPractitionerRoleRef(),
    definitionCanonical: chargeDefinitionReferences,
    enteredDate: new Date().toISOString(),
    supportingInformation: [serviceRequestRef],
    requestingOrganization: getCurrentUserUnitReference(),
    code: {},
    subject: {
      reference: `${subject?.resourceType}/${subject.id}`,
    },
  }

  return chargeItem
}

export function getPaymentReconciliationResource(
  taskRef: R4.IReference,
  amount: R4.IMoney,
  selectedPaymentOption: string,
  selectedServiceType: string
): R4.IPaymentReconciliation {
  const onlinePaymentExtension = {
    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentType',
    valueCoding: {
      code: 'prepaid',
      display: 'prepaid',
      system: 'http://wellopathy.com/fhir/india/core/CodeSystem/payment_type',
    },
  }
  const cashPaymentExtension = {
    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentType',
    valueCoding: {
      code: 'postPaid',
      display: 'postpaid',
      system: 'http://wellopathy.com/fhir/india/core/CodeSystem/payment_type',
    },
  }
  const paymentReconciliation: R4.IPaymentReconciliation = {
    resourceType: 'PaymentReconciliation',
    status: 'active',
    paymentAmount: amount,
    request: taskRef,
    requestor: getCurrentUserPractitionerRoleRef(),
    paymentIssuer: getCurrentUserUnitReference(),
    outcome: R4.PaymentReconciliationOutcomeKind._queued,
  }

  if (selectedPaymentOption === 'online') {
    paymentReconciliation.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentMode',
        valueCoding: {
          code: 'online',
          display: 'online',
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/payment_mode',
        },
      },
      onlinePaymentExtension,
    ]
  }

  if (
    selectedPaymentOption === 'cash' &&
    selectedServiceType === 'home_collection'
  ) {
    paymentReconciliation.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentMode',
        valueCoding: {
          code: 'cash',
          display: 'cash',
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/payment_mode',
        },
      },
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/payment-status',
        valueCoding: {
          code: 'pending',
          display: 'pending',
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/payment-status',
        },
      },
      cashPaymentExtension,
    ]
  }

  if (
    selectedPaymentOption === 'cash' &&
    selectedServiceType === 'onsite-collection'
  ) {
    paymentReconciliation.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentMode',
        valueCoding: {
          code: 'cash',
          display: 'cash',
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/payment_mode',
        },
      },
      onlinePaymentExtension,
    ]
  }

  return paymentReconciliation
}

export function getOrdersForAgent(
  responseSlots: R4.IBundle
): FhirLabOrderDetail[] {
  const convertedAppointments: FhirLabOrderDetail[] = []
  const serviceRequests: any = {}
  const slots: any = {}
  const tasks: any = {}
  const homeCollectionTask: any = {}
  const practitioners: any = {}
  const patients: any = {}
  const practitionerRoles: any = {}
  const planDefinitions: any = {}
  const provenances: R4.IProvenance[] = []
  const specimen: R4.ISpecimen[] = []
  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ServiceRequest':
                  serviceRequests[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break
                case 'Slot':
                  slots[value.resource.id] = value.resource as R4.ISlot
                  break
                case 'Practitioner':
                  practitioners[value.resource.id] =
                    value.resource as R4.IPractitioner
                  break
                case 'PractitionerRole':
                  practitionerRoles[value.resource.id] =
                    value.resource as R4.IPractitionerRole
                  break
                case 'Task':
                  if (value.resource.status !== R4.TaskStatusKind._cancelled) {
                    if (value.resource?.partOf === undefined) {
                      tasks[
                        value.resource.focus?.reference?.split('/')[1] ?? ''
                      ] = value.resource as R4.ITask
                    } else {
                      homeCollectionTask[
                        value.resource.focus?.reference?.split('/')[1] ?? ''
                      ] = value.resource as R4.ITask
                    }
                  }

                  break
                case 'Patient':
                  patients[value.resource.id] = value.resource as R4.IPatient
                  break

                case 'Provenance':
                  provenances.push(value.resource as R4.IProvenance)
                  break

                case 'Specimen':
                  specimen.push(value.resource as R4.ISpecimen)
                  break
                case 'PlanDefinition':
                  planDefinitions[value.resource.id] =
                    value.resource as R4.IPlanDefinition
                  break
                default:
                  break
              }
            }
          }
        })

        const labOfferings: LabOfferingDetail[] =
          getLabOfferingDetailsFromBundle(responseSlots, []) ?? []
        labOfferings.forEach((e) => {
          planDefinitions[e.id] = e
        })

        for (const key in serviceRequests) {
          if (key) {
            const currentAppointment: R4.IServiceRequest = serviceRequests[
              key
            ] as R4.IServiceRequest
            let practitionerId: string | undefined

            let practitionerRoleId: string | undefined

            let practitionerRoleIdAdmin: string | undefined

            const patientId: string | undefined =
              currentAppointment?.subject.reference?.split('/')[1]
            const currentPlanDefinitions: LabOfferingDetail[] | undefined = (
              serviceRequests[key] as R4.IServiceRequest
            ).instantiatesCanonical?.map((e) => {
              const id: string = e.split('/')[1]

              return planDefinitions[id] as LabOfferingDetail
            })

            let startTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
              ''
            let endTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
              ''
            const serviceType: R4.ICoding | undefined =
              getCodeOfSystemFromCodableConcept(
                (serviceRequests[key] as R4.IServiceRequest).code ?? {},
                'http://wellopathy.com/fhir/india/core/CodeSystem/lab-service-type'
              )

            if (serviceType?.code === 'home-sample-collection') {
              startTime =
                (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                  ?.start ?? ''
              endTime =
                (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                  ?.end ?? ''

              const owner1 = homeCollectionTask[key] as R4.ITask
              if (owner1) {
                practitionerRoleId = (
                  homeCollectionTask[key] as R4.ITask
                ).owner?.reference?.split('/')[1]
              }
              const owner2 = tasks[key] as R4.ITask
              if (owner2) {
                practitionerRoleIdAdmin = (
                  tasks[key] as R4.ITask
                ).owner?.reference?.split('/')[1]
              }

              if (practitionerRoleId) {
                const practRole: R4.IPractitionerRole = practitionerRoles[
                  practitionerRoleId
                ] as R4.IPractitionerRole
                if (practRole) {
                  practitionerId = (
                    practitionerRoles[
                      practitionerRoleId
                    ] as R4.IPractitionerRole
                  ).practitioner?.reference?.split('/')[1]
                }
              }
            }

            const status = provenances.filter((e) => {
              const res = e.target.findIndex((ref) => {
                if (ref.reference)
                  return (
                    ref.reference.split('/')[1] === homeCollectionTask[key].id
                  )

                return false
              })
              return res > -1
            })
            status.sort((a, b) =>
              moment(a.occurredDateTime).diff(b.occurredDateTime)
            )

            const taskData: R4.ITask = tasks[key]
            const home = homeCollectionTask[key]
            if (taskData.status === 'in-progress') {
              convertedAppointments.push({
                start: startTime,
                end: endTime,
                performerDetail: {
                  practitioner: practitioners[practitionerId ?? ''],
                  practitionerRole: practitionerRoles[practitionerRoleId ?? ''],
                },
                homeServiceTask: homeCollectionTask[key],
                task: tasks[key],
                serviceRequest: serviceRequests[key],

                patient: patients[patientId ?? ''],
                tests: currentPlanDefinitions,
                statuses: status,
                isSelected: false,
                oldSlotRef: '',
              })
            }
          }
        }
      }
    }
  }

  return convertedAppointments
}

export function getPaymentType(
  responseSlots: R4.IBundle,
  orderDetil: FhirLabOrderDetail
): FhirLabOrderDetail {
  const convertedOrder: FhirLabOrderDetail = orderDetil
  const paymentReconciliation: any = {}

  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'PaymentReconciliation':
                  paymentReconciliation[value.resource.id] =
                    value.resource as R4.IPaymentReconciliation
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in paymentReconciliation) {
          if (key) {
            const currentAppointment: R4.IPaymentReconciliation =
              paymentReconciliation[key] as R4.IPaymentReconciliation
            const extension = getExtensionValueOfUrl(
              currentAppointment.extension,
              'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentType'
            )

            if (extension?.valueCoding?.display) {
              if (extension?.valueCoding?.display === 'Postpaid') {
                convertedOrder.paymentReconilation = currentAppointment
                convertedOrder.isPrepaid = false
              } else {
                convertedOrder.paymentReconilation = currentAppointment
                convertedOrder.isPrepaid = true
              }
            } else {
              convertedOrder.paymentReconilation = currentAppointment
              convertedOrder.isPrepaid = false
            }
          }
        }
      }
    }
  }

  return convertedOrder
}

export function getLabDiagnosticRequestFromBundle(
  responseSlots: R4.IBundle
): FhirLabDiagnosticRequest[] {
  const convertedAppointments: FhirLabDiagnosticRequest[] = []
  const serviceRequests: any = {}
  const patients: any = {}
  const planDefinitions: any = {}
  const provenances: R4.IProvenance[] = []
  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ServiceRequest':
                  serviceRequests[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break
                case 'Patient':
                  patients[value.resource.id] = value.resource as R4.IPatient
                  break
                case 'Provenance':
                  provenances.push(value.resource as R4.IProvenance)
                  break
                case 'PlanDefinition':
                  planDefinitions[value.resource.id] =
                    value.resource as R4.IPlanDefinition
                  break
                default:
                  break
              }
            }
          }
        })

        const labOfferings: LabOfferingDetail[] =
          getLabOfferingDetailsFromBundle(responseSlots, []) ?? []
        labOfferings.forEach((e) => {
          planDefinitions[e.id] = e
        })

        for (const key in serviceRequests) {
          if (key) {
            const currentAppointment: R4.IServiceRequest = serviceRequests[
              key
            ] as R4.IServiceRequest

            const patientId: string | undefined =
              currentAppointment?.subject.reference?.split('/')[1]
            const currentPlanDefinitions: LabOfferingDetail[] | undefined = (
              serviceRequests[key] as R4.IServiceRequest
            ).instantiatesCanonical?.map((e) => {
              const id: string = e.split('/')[1]

              return planDefinitions[id] as LabOfferingDetail
            })

            convertedAppointments.push({
              serviceRequest: serviceRequests[key],
              patient: patients[patientId ?? ''],
              tests: currentPlanDefinitions,
            })
          }
        }
      }
    }
  }

  return convertedAppointments
}

export function getLabOrderFullDetail(
  responseSlots: R4.IBundle
): FhirLabOrderFullDetail[] {
  const convertedLabOrders: FhirLabOrderFullDetail[] = []
  const serviceRequests: any = {}

  const observations: any = {}
  const practitioners: any = {}
  const patients: any = {}
  const practitionerRoles: any = {}
  const planDefinitions: any = {}
  const diagnosticReports: Map<string, R4.IDiagnosticReport[]> = new Map<
    string,
    R4.IDiagnosticReport[]
  >()

  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ServiceRequest':
                  serviceRequests[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break

                case 'Practitioner':
                  practitioners[value.resource.id] =
                    value.resource as R4.IPractitioner
                  break
                case 'PractitionerRole':
                  practitionerRoles[value.resource.id] =
                    value.resource as R4.IPractitionerRole
                  break

                case 'Observation':
                  observations[value.resource.id] =
                    value.resource as R4.IObservation
                  break

                case 'DiagnosticReport':
                  {
                    const index: number | undefined = (
                      value.resource as R4.IDiagnosticReport
                    ).basedOn?.findIndex((e) =>
                      e.reference?.includes('ServiceRequest')
                    )

                    if (index !== undefined && index > -1) {
                      const ref: R4.IReference = (
                        value.resource as R4.IDiagnosticReport
                      ).basedOn?.[index]!

                      const key: string = ref.reference?.split('/')[1] ?? ''

                      if (diagnosticReports.has(key)) {
                        diagnosticReports
                          .get(key)
                          ?.push(value.resource as R4.IDiagnosticReport)
                      } else {
                        diagnosticReports.set(
                          ref.reference?.split('/')[1] ?? '',
                          [value.resource as R4.IDiagnosticReport]
                        )
                      }
                    }
                    value.resource as R4.IDiagnosticReport
                  }

                  break

                case 'Patient':
                  patients[value.resource.id] = value.resource as R4.IPatient
                  break

                case 'PlanDefinition':
                  planDefinitions[value.resource.id] =
                    value.resource as R4.IPlanDefinition
                  break
                default:
                  break
              }
            }
          }
        })

        const labOfferings: LabOfferingDetail[] =
          getLabOfferingDetailsFromBundle(responseSlots, []) ?? []
        labOfferings.forEach((e) => {
          planDefinitions[e.id] = e
        })

        for (const key in serviceRequests) {
          if (key) {
            const currentRequest: R4.IServiceRequest = serviceRequests[
              key
            ] as R4.IServiceRequest
            let practitionerRoleId: string | undefined
            const patientId: string | undefined =
              currentRequest?.subject.reference?.split('/')[1]
            const currentPlanDefinitions: LabOfferingDetail[] | undefined = (
              serviceRequests[key] as R4.IServiceRequest
            ).instantiatesCanonical?.map((e) => {
              const id: string = e.split('/')[1]

              return planDefinitions[id] as LabOfferingDetail
            })

            let startTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
              ''
            let endTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
              ''
            const serviceType: R4.ICoding | undefined =
              getCodeOfSystemFromCodableConcept(
                (serviceRequests[key] as R4.IServiceRequest).code ?? {},
                'http://wellopathy.com/fhir/india/core/CodeSystem/lab-service-type'
              )

            if (serviceType?.code === 'home-sample-collection') {
              startTime =
                (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                  ?.start ?? ''
              endTime =
                (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                  ?.end ?? ''

              if (practitionerRoleId) {
                const practRole: R4.IPractitionerRole = practitionerRoles[
                  practitionerRoleId
                ] as R4.IPractitionerRole
              }
            }
            const testsAndResults: TestAndResult[] = []

            if (diagnosticReports.has(currentRequest.id!)) {
              if (diagnosticReports.get(currentRequest.id!)!.length > 0) {
                diagnosticReports.get(currentRequest.id ?? '')?.forEach((e) => {
                  const obs: R4.IObservation[] = []
                  if (e.result && e.result.length > 0) {
                    e.result!.forEach((res) => {
                      const obsId: string = res.reference?.split('/')[1]!
                      obs.push(observations[obsId])
                    })
                  }

                  testsAndResults.push({
                    diagnosticReport: e,
                    results: obs,
                  })
                })
              }
            }

            convertedLabOrders.push({
              start: startTime,
              end: endTime,
              testAndResults: testsAndResults,

              serviceRequest: serviceRequests[key],
              patient: patients[patientId ?? ''],
              tests: currentPlanDefinitions,
            })
          }
        }
      }
    }
  }

  return convertedLabOrders
}

export function getTransactionBodyResource(
  ServiceRequestId: string,
  PaymentReconciliationId: string,
  paymentType: string,
  tasks: string[],
  transactiontype: string
): R4.IParameters {
  const paramterResource: R4.IParameters = {
    resourceType: 'Parameters',
    parameter: [
      {
        name: 'order-type',
        valueString: transactiontype,
      },
      {
        name: 'order-id',
        valueString: ServiceRequestId,
      },
      {
        name: 'payment-type',
        valueString: paymentType === 'cash' ? 'offline' : 'online',
      },
      {
        name: 'razorpay-order-id',
        valueString: '',
      },
      {
        name: 'razorpay-payment-id',
        valueString: '',
      },
      {
        name: 'razorpay-sign',
        valueString: '',
      },
      {
        name: 'payment-reconciliation-id',
        valueString: PaymentReconciliationId,
      },
      {
        name: 'task-order-id',
        valueString: tasks.length >= 1 ? tasks[0] : '',
      },
      {
        name: 'task-agent-id',
        valueString: tasks.length > 1 ? tasks[1] : tasks[0],
      },
    ],
  }

  return paramterResource
}

export function getOrderStatusTextForChck(
  treatmentPlan: R4.IServiceRequest
): string {
  const statusName: string = ''
  switch (treatmentPlan.status) {
    case 'active':
      return 'Scheduled'
    case 'completed':
      return 'Scheduled'
    case 'revoked':
      return 'Cancelled'
    case 'unknown':
      return 'Cancelled'

    default:
      break
  }
  return statusName
}

export function getTransactionBodyResourceAppointment(
  ServiceRequestId: string,
  PaymentReconciliationId: string,
  paymentType: string,
  transactiontype: string
): R4.IParameters {
  const paramterResource: R4.IParameters = {
    resourceType: 'Parameters',
    parameter: [
      {
        name: 'order-type',
        valueString: transactiontype,
      },
      {
        name: 'order-id',
        valueString: ServiceRequestId,
      },
      {
        name: 'payment-type',
        valueString: paymentType === 'cash' ? 'offline' : 'online',
      },
      {
        name: 'razorpay-order-id',
        valueString: '',
      },
      {
        name: 'razorpay-payment-id',
        valueString: '',
      },
      {
        name: 'razorpay-sign',
        valueString: '',
      },
      {
        name: 'payment-reconciliation-id',
        valueString: PaymentReconciliationId,
      },
      {
        name: 'task-order-id',
        valueString: '',
      },
      {
        name: 'task-agent-id',
        valueString: '',
      },
    ],
  }

  return paramterResource
}

export function getTransactionBodyResourceAppointmentOnline(
  ServiceRequestId: string,
  PaymentReconciliationId: string,
  paymentType: string,
  transactiontype: string
): R4.IParameters {
  const paramterResource: R4.IParameters = {
    resourceType: 'Parameters',
    parameter: [
      {
        name: 'order-type',
        valueString: transactiontype,
      },
      {
        name: 'order-id',
        valueString: ServiceRequestId,
      },
      {
        name: 'payment-type',
        valueString: paymentType === 'cash' ? 'offline' : 'online',
      },
      {
        name: 'payment-reconciliation-id',
        valueString: PaymentReconciliationId,
      },
    ],
  }

  return paramterResource
}

export function getInvoicePdf(
  type: string,
  serviceRequestId: string,
  paymentReconciliationId: string
): R4.IParameters {
  const paramterResource: R4.IParameters = {
    resourceType: 'Parameters',
    parameter: [
      {
        name: 'order-reference',
        valueString: `${type}/${serviceRequestId}`,
      },
      {
        name: 'payment-reconciliation-ref',
        valueString: `PaymentReconciliation/${paymentReconciliationId}`,
      },
    ],
  }

  return paramterResource
}

export function getRoomPriceFromBundle(responseSlots: R4.IBundle): RoomPrice {
  let priceData: RoomPrice = {
    price: 0,
  }

  const chargeItemDef: any = {}

  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ChargeItemDefinition':
                  chargeItemDef[value.resource.id] =
                    value.resource as R4.IChargeItemDefinition
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in chargeItemDef) {
          if (key) {
            const chargeItemDefinition: R4.IChargeItemDefinition =
              chargeItemDef[key] as R4.IChargeItemDefinition
            const priceComponent:
              | R4.IChargeItemDefinition_PriceComponent
              | undefined =
              getPriceComponentFromChangeItem(chargeItemDefinition)

            priceData = {
              price: priceComponent
                ? priceComponent.amount
                  ? priceComponent.amount.value ?? 0
                  : 0
                : 0,
              chargeDdef: chargeItemDefinition,
            }
          }
        }
      }
    }
  }

  return priceData
}

export function getRoomNumber(responseSlots: R4.IBundle): R4.ICoding[] {
  const rommNumbers: R4.ICoding[] = []
  const locationDef: any = {}
  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Location':
                  locationDef[value.resource.id] =
                    value.resource as R4.ILocation
                  break
                default:
                  break
              }
            }
          }
        })
        for (const key in locationDef) {
          if (key) {
            const location: R4.ILocation = locationDef[key] as R4.ILocation

            const locCode: R4.ICoding = {
              system: location.identifier
                ? location.identifier[0].type
                  ? location.identifier[0].type.coding
                    ? location.identifier[0].type.coding[0].code ?? ''
                    : ''
                  : ''
                : '',
              display: location.identifier
                ? location.identifier[0].value ?? ''
                : '',
              code: location.identifier
                ? location.identifier[0].value ?? ''
                : '',
            }

            rommNumbers.push(locCode)
          }
        }
      }
    }
  }
  return rommNumbers
}

export function getExpandedIPDAppointmentFromBundle(
  responseBundle: R4.IBundle
): FhirActiveIPDDetailsForMedicalRole[] {
  const convertedAppointments: FhirActiveIPDDetailsForMedicalRole[] = []
  const appointments: any = {}
  const tasks: any = {}
  const encounters: any = {}
  const practitioners: any = {}
  const patients: any = {}
  const practitionerRoles: any = {}
  const paymentReconilation: any = {}
  const carePlans: any = {}
  const loc: any = {}
  const questionResponse: any = {}
  const relPerson: R4.IRelatedPerson[] = []
  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ServiceRequest':
                  appointments[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break
                case 'Task':
                  if (value.resource?.partOf === undefined) {
                    tasks[
                      value.resource.focus?.reference?.split('/')[1] ?? ''
                    ] = value.resource as R4.ITask
                  }

                  break

                case 'RelatedPerson':
                  relPerson.push(value.resource as R4.IRelatedPerson)
                  break

                case 'Location':
                  loc[value.resource.id] = value.resource as R4.ILocation
                  break

                case 'Practitioner':
                  practitioners[value.resource.id] =
                    value.resource as R4.IPractitioner
                  break
                case 'PractitionerRole':
                  practitionerRoles[value.resource.id] =
                    value.resource as R4.IPractitionerRole
                  break
                case 'QuestionnaireResponse':
                  questionResponse[value.resource.id] =
                    value.resource as R4.IQuestionnaireResponse
                  break
                case 'Encounter':
                  encounters[value.resource.id] =
                    value.resource as R4.IEncounter
                  break
                case 'CarePlan':
                  carePlans[value.resource.id] = value.resource as R4.ICarePlan
                  break
                case 'Patient':
                  patients[value.resource.id] = value.resource as R4.IPatient
                  break
                case 'PaymentReconciliation':
                  paymentReconilation[value.resource.id] =
                    value.resource as R4.IPaymentReconciliation
                  break

                default:
                  break
              }
            }
          }
        })

        for (const key in appointments) {
          if (key) {
            const currentAppointment: R4.IServiceRequest = appointments[
              key
            ] as R4.IServiceRequest
            let paymentId: string | undefined

            const locIdData: string | undefined =
              currentAppointment.locationReference
                ? currentAppointment.locationReference[0].reference?.split(
                    '/'
                  )[1]
                : undefined

            const practitionerRoleId: string | undefined =
              currentAppointment?.performer
                ?.find((val) => val.reference?.includes('PractitionerRole/'))
                ?.reference?.split('/')[1]

            const patientId: string | undefined =
              currentAppointment?.subject.reference?.split('/')[1]
            const encounterId: string | undefined = currentAppointment.encounter
              ? currentAppointment?.encounter.reference?.split('/')[1]
              : ''

            for (const key1 in paymentReconilation) {
              if (key1) {
                const paymentInfo: R4.IPaymentReconciliation =
                  paymentReconilation[key1] as R4.IPaymentReconciliation

                const chargeID: string | undefined =
                  getValueRefValueExtensionsOfUrl(
                    paymentInfo.extension,
                    'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-ref'
                  )?.split('/')[1]
                if (chargeID === currentAppointment.id) {
                  paymentId = paymentInfo.id
                }
              }
            }
            const RelatedPerson: R4.IRelatedPerson | undefined = getAttendant(
              relPerson,
              currentAppointment.id!
            )

            let carePlanId

            // )
            const keys: string[] = Object.keys(questionResponse)

            const practitionerRoleData: R4.IPractitionerRole =
              practitionerRoles[practitionerRoleId ?? '']
            const unitOrg = getCurrentUserUnitDetails()
            if (practitionerRoleData) {
              const orgId = practitionerRoleData.organization?.id ?? ''
              if (!isOrgAdmin()) {
                if (unitOrg.id === orgId) {
                  convertedAppointments.push({
                    mainServiceRequest: appointments[key],
                    mainTask: tasks[key],
                    patient: patients[patientId ?? ''],

                    mainEncounter: encounters[encounterId ?? ''],
                    practitionerDetail: {
                      practitioner: practitioners[practitionerRoleId ?? ''],
                      practitionerRole:
                        practitionerRoles[practitionerRoleId ?? ''],
                      location: loc[locIdData ?? ''],
                    },
                    token: '',
                    roomId: '',
                    questionResponse: questionResponse[keys[0]],
                    feedback: questionResponse[keys[1]],
                    attendant: RelatedPerson,
                    roomData: loc[locIdData ?? ''],
                  })
                }
              } else {
                convertedAppointments.push({
                  mainServiceRequest: appointments[key],
                  mainTask: tasks[key],
                  patient: patients[patientId ?? ''],

                  mainEncounter: encounters[encounterId ?? ''],
                  practitionerDetail: {
                    practitioner: practitioners[practitionerRoleId ?? ''],
                    practitionerRole:
                      practitionerRoles[practitionerRoleId ?? ''],
                    location: loc[locIdData ?? ''],
                  },
                  token: '',
                  roomId: '',
                  questionResponse: questionResponse[keys[0]],
                  feedback: questionResponse[keys[1]],
                  attendant: RelatedPerson,
                  roomData: loc[locIdData ?? ''],
                })
              }
            }
          }
        }
      }
    }
  }
  return convertedAppointments
}

function getAttendant(
  relPerson: R4.IRelatedPerson[],
  servidId: string
): R4.IRelatedPerson | undefined {
  for (let i = 0; i < relPerson.length; i++) {
    if (relPerson[i].text) {
      const id = relPerson[i].text?.id ?? ''
      if (id.length > 0) {
        const finalId = id.split('/')[1]
        if (finalId === servidId) {
          return relPerson[i]
        }
      }
    }
  }
  return undefined
}

export function getDateWiseIPD(
  availableSlots: FhirClinicIpdDetails[],
  existingDateWiseAppointments: DateWiseIPDAppointments[]
): DateWiseIPDAppointments[] {
  const dateWiseSlots: DateWiseIPDAppointments[] = [
    ...existingDateWiseAppointments,
  ]

  const dateWiseSlotsFinal: DateWiseIPDAppointments[] = []

  availableSlots.forEach((item) => {
    if (item.start) {
      const date = moment(item.start).format('YYYY-MM-DD')

      const index = dateWiseSlots.findIndex(
        (s) => moment(s.date).format('YYYY-MM-DD') === date
      )

      if (index < 0) {
        const dateWiseSlot: DateWiseIPDAppointments = {
          date: item.start,
          orders: [item],
        }
        dateWiseSlots.push(dateWiseSlot)
      } else if (dateWiseSlots[index]?.orders) {
        const preEles = [...(dateWiseSlots[index].orders ?? [])]
        dateWiseSlots[index] = {
          date: moment(item.start).format('YYYY-MM-DD'),
          orders: [...preEles, item],
        }
      } else {
        dateWiseSlots[index].orders = [item]
      }
    }
  })

  //   for (let i = 0; i < dateWiseSlots.length; i++) {
  //     dateWiseSlotsFinal.push({
  //       date: dateWiseSlots[i].date,
  //       orders: dateWiseSlots[i].orders.sort(
  //         (n1, n2) => n2.ipdNumber - n1.ipdNumber
  //       ),
  //     })
  //   }

  return dateWiseSlots
}

export function getDateWiseTherapies(
  availableSlots: FhirClinicTherapyBasic[],
  existingDateWiseAppointments: DateWiseTherapies[]
): DateWiseTherapies[] {
  const dateWiseSlots: DateWiseTherapies[] = [...existingDateWiseAppointments]

  const dateWiseSlotsFinal: DateWiseTherapies[] = []

  availableSlots.forEach((item) => {
    if (item.start) {
      const date = moment(item.start).format('YYYY-MM-DD')
      const index = dateWiseSlots.findIndex(
        (s) => moment(s.date).format('YYYY-MM-DD') === date
      )
      if (index < 0) {
        const dateWiseSlot: DateWiseTherapies = {
          date: item.start,
          orders: [item],
        }
        dateWiseSlots.push(dateWiseSlot)
      } else if (dateWiseSlots[index]?.orders) {
        const preEles = [...(dateWiseSlots[index].orders ?? [])]
        dateWiseSlots[index] = {
          date: moment(item.start).format('YYYY-MM-DD'),
          orders: [...preEles, item],
        }
      } else {
        dateWiseSlots[index].orders = [item]
      }
    }
  })

  dateWiseSlots.forEach((e) => {
    e.orders.sort((eachA, eachB) => moment(eachA.start).diff(eachB.start))
  })
  return dateWiseSlots
}

export function getStatusWiseTherapies(
  availableSlots: FhirClinicTherapyBasic[],
  existingDateWiseAppointments: StatusWiseTherapies[]
): StatusWiseTherapies[] {
  const dateWiseSlots: StatusWiseTherapies[] = [...existingDateWiseAppointments]

  availableSlots.forEach((item) => {
    if (item.therapy.status) {
      const date = item.therapy.status
      const index = dateWiseSlots.findIndex((s) => s.status === date)
      if (index < 0) {
        const dateWiseSlot: StatusWiseTherapies = {
          status: item.therapy.status,
          orders: [item],
        }
        dateWiseSlots.push(dateWiseSlot)
      } else if (dateWiseSlots[index]?.orders) {
        const preEles = [...(dateWiseSlots[index].orders ?? [])]
        dateWiseSlots[index] = {
          status: item.therapy.status,
          orders: [...preEles, item],
        }
      } else {
        dateWiseSlots[index].orders = [item]
      }
    }
  })

  dateWiseSlots.forEach((e) => {
    e.orders.sort((eachA, eachB) => moment(eachA.start).diff(eachB.start))
  })
  return dateWiseSlots.sort((eachA, eachB) =>
    (eachA!.status ?? '').localeCompare(eachB!.status ?? '')
  )
}

export function getTherapyListWithChargeItem(
  responseBundle: R4.IBundle
): FhirTherapyListWithChargeDef[] {
  const convertedAppointments: FhirTherapyListWithChargeDef[] = []
  const healthService: any = {}
  const chargeDdef: any = {}

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'HealthcareService':
                  healthService[value.resource.id] =
                    value.resource as R4.IHealthcareService
                  break
                case 'ChargeItemDefinition':
                  chargeDdef[value.resource.id] =
                    value.resource as R4.IChargeItemDefinition
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in healthService) {
          if (key) {
            const currentAppointment: R4.IHealthcareService = healthService[
              key
            ] as R4.IHealthcareService
            let payment: number = 0

            const chargeID: string | undefined =
              getValueRefValueExtensionsOfUrl(
                currentAppointment.extension,
                'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode'
              )?.split('/')[1]

            const chargeDdefData: R4.IChargeItemDefinition =
              chargeDdef[chargeID ?? '']
            if (chargeDdefData) {
              const priceComponent:
                | R4.IChargeItemDefinition_PriceComponent
                | undefined = getPriceComponentFromChangeItem(chargeDdefData)

              if (
                priceComponent &&
                priceComponent.amount &&
                priceComponent.amount.value
              ) {
                payment = priceComponent.amount.value
              }
            }
            const billing: string = currentAppointment.characteristic
              ? currentAppointment.characteristic[0].text ?? ''
              : ''
            if (currentAppointment.active === true)
              convertedAppointments.push({
                therapy: currentAppointment,
                price: billing === 'Billable' ? payment : 0,
                name: currentAppointment.name ?? '',
              })
          }
        }
      }
    }
  }
  return convertedAppointments
}

export function getTherapyListWithChargeItemForOPD(
  responseBundle: R4.IBundle
): FhirTherapyListWithChargeDef[] {
  const convertedAppointments: FhirTherapyListWithChargeDef[] = []
  const healthService: any = {}
  const chargeDdef: any = {}

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'HealthcareService':
                  healthService[value.resource.id] =
                    value.resource as R4.IHealthcareService
                  break
                case 'ChargeItemDefinition':
                  chargeDdef[value.resource.id] =
                    value.resource as R4.IChargeItemDefinition
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in healthService) {
          if (key) {
            const currentAppointment: R4.IHealthcareService = healthService[
              key
            ] as R4.IHealthcareService
            let payment: number = 0

            const chargeID: string | undefined =
              getValueRefValueExtensionsOfUrl(
                currentAppointment.extension,
                'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode'
              )?.split('/')[1]

            const chargeDdefData: R4.IChargeItemDefinition =
              chargeDdef[chargeID ?? '']
            if (chargeDdefData) {
              const priceComponent:
                | R4.IChargeItemDefinition_PriceComponent
                | undefined = getPriceComponentFromChangeItem(chargeDdefData)

              if (
                priceComponent &&
                priceComponent.amount &&
                priceComponent.amount.value
              ) {
                payment = priceComponent.amount.value
              }
            }
            const billing: string = currentAppointment.characteristic
              ? currentAppointment.characteristic[0].text ?? ''
              : ''
            if (currentAppointment.active === true)
              convertedAppointments.push({
                therapy: currentAppointment,
                price: payment,
                name: currentAppointment.name ?? '',
              })
          }
        }
      }
    }
  }
  return convertedAppointments
}

export function getSubstanceListWithChargeItem(
  responseBundle: R4.IBundle
): SubModel[] {
  const convertedAppointments: SubModel[] = []
  const healthService: any = {}
  const chargeDdef: any = {}

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Substance':
                  healthService[value.resource.id] =
                    value.resource as R4.ISubstance
                  break
                case 'ChargeItemDefinition':
                  chargeDdef[value.resource.id] =
                    value.resource as R4.IChargeItemDefinition
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in healthService) {
          if (key) {
            const currentAppointment: R4.ISubstance = healthService[
              key
            ] as R4.ISubstance
            let payment: number = 0

            const chargeID: string | undefined =
              getValueRefValueExtensionsOfUrl(
                currentAppointment.extension,
                'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode'
              )?.split('/')[1]

            const unitData: R4.ICodeableConcept | undefined =
              getValueCodableFromExtension(
                currentAppointment.extension,
                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-billing-unit-ex'
              )

            const chargeDdefData: R4.IChargeItemDefinition =
              chargeDdef[chargeID ?? '']
            if (chargeDdefData) {
              const priceComponent:
                | R4.IChargeItemDefinition_PriceComponent
                | undefined = getPriceComponentFromChangeItem(chargeDdefData)

              if (
                priceComponent &&
                priceComponent.amount &&
                priceComponent.amount.value
              ) {
                payment = priceComponent.amount.value
              }
            }
            if (currentAppointment.status === 'active')
              convertedAppointments.push({
                id: currentAppointment.id ?? '',
                name: currentAppointment.description ?? '',
                price: payment,
                unit: unitData ? unitData.coding![0].display ?? '' : '',
              })
          }
        }
      }
    }
  }
  return convertedAppointments
}

export function getAddedSubsTance(
  responseBundle: R4.IBundle
): ConsumableData[] {
  const convertedAppointments: ConsumableData[] = []
  const task: any = {}
  const healthService: any = {}
  const chargeDdef: any = {}

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Task':
                  task[value.resource.id] = value.resource as R4.ITask
                  break
                case 'Substance':
                  healthService[value.resource.id] =
                    value.resource as R4.ISubstance
                  break
                case 'ChargeItemDefinition':
                  chargeDdef[value.resource.id] =
                    value.resource as R4.IChargeItemDefinition
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in task) {
          if (key) {
            const currentAppointment: R4.ITask = task[key] as R4.ITask
            let payment: number = 0
            const substanceId: string | undefined = currentAppointment.focus
              ? currentAppointment.focus.reference
                ? currentAppointment.focus.reference.split('/')[1]
                : ''
              : ''

            const subsData: R4.ISubstance = healthService[substanceId ?? '']

            const chargeID: string | undefined =
              getValueRefValueExtensionsOfUrl(
                subsData.extension,
                'http://hl7.org/fhir/uv/order-catalog/StructureDefinition/ServiceBillingCode'
              )?.split('/')[1]
            const chargeDdefData: R4.IChargeItemDefinition =
              chargeDdef[chargeID ?? '']
            if (chargeDdefData) {
              const priceComponent:
                | R4.IChargeItemDefinition_PriceComponent
                | undefined = getPriceComponentFromChangeItem(chargeDdefData)

              if (
                priceComponent &&
                priceComponent.amount &&
                priceComponent.amount.value
              ) {
                payment = priceComponent.amount.value
              }
            }

            if (currentAppointment.status !== 'cancelled')
              convertedAppointments.push({
                date: currentAppointment.instantiatesUri
                  ? moment(currentAppointment.instantiatesUri).format(
                      'DD-MM-YYYY'
                    )
                  : '',
                task: currentAppointment,
                substance: subsData,
                charrgeDef: chargeDdefData,
                unit: currentAppointment.input
                  ? currentAppointment.input[0].valueInteger ?? 0
                  : 0,
                payment,
              })
          }
        }
      }
    }
  }
  return convertedAppointments
}

export function getDateWiseTherapy(
  availableSlots: TreatmentPlanData[]
): TreatmentAssemble[] {
  const dateWiseSlots: TreatmentAssemble[] = []

  let morning: Morning[] = []
  let morningCancelledSlot: MorningCancelledTherapy[] = []

  availableSlots.forEach((item) => {
    if (item.endData) {
      const date = moment(item.date).format('YYYY-MM-DD')
      const index = dateWiseSlots.findIndex(
        (s) => moment(s.date).format('YYYY-MM-DD') === date
      )
      if (index < 0) {
        morning = []
        morningCancelledSlot = []
        if (
          inBetWeenTime(moment(item.endData).format('HH:mm'), '04:59', '08:01')
        ) {
          if (item.treatmentPlan) {
            if (getOrderStatusTextForChck(item.treatmentPlan) === 'Cancelled') {
              morningCancelledSlot.push({
                cancelledStart: item.date,
                cancelledEnd: item.endData,
              })
            } else {
              morning.push({
                morningTherapyEnd: item.endData,
                morningTherapyStart: item.date,
              })
            }
          }
        }
        const dateWiseSlot: TreatmentAssemble = {
          date: moment(item.endData).format('YYYY-MM-DD'),
          morningTherapy: morning.sort((a, b) =>
            (a.morningTherapyStart ? a.morningTherapyStart : '') <
            (b.morningTherapyStart ? b.morningTherapyStart : '')
              ? -1
              : (a.morningTherapyStart ?? '') > (b.morningTherapyStart ?? '')
              ? 1
              : 0
          ),
          morningCancelled: morningCancelledSlot.sort(),
          midMorningTherapy: [],
          midMorningCancelled: [],
          afterNoonTherapy: [],
          eveingTherapy: [],
          afterNoonCancelled: [],
          eveningCancelled: [],
        }
        dateWiseSlots.push(dateWiseSlot)
      } else if (dateWiseSlots[index]?.morningTherapy) {
        const preEles = [...(dateWiseSlots[index].morningTherapy ?? [])]

        if (
          inBetWeenTime(moment(item.endData).format('HH:mm'), '04:59', '08:01')
        ) {
          if (item.treatmentPlan) {
            if (getOrderStatusTextForChck(item.treatmentPlan) === 'Cancelled') {
              // morningCancelledSlot.push({
              //   cancelledStart: item.date,
              //   cancelledEnd: item.endData,
              // })
            } else {
              const morningItem: Morning = {
                morningTherapyEnd: item.endData,
                morningTherapyStart: item.date,
              }

              dateWiseSlots[index] = {
                date: moment(item.endData).format('YYYY-MM-DD'),
                morningTherapy: [...preEles, morningItem].sort((a, b) =>
                  (a.morningTherapyStart ? a.morningTherapyStart : '') <
                  (b.morningTherapyStart ? b.morningTherapyStart : '')
                    ? -1
                    : (a.morningTherapyStart ?? '') >
                      (b.morningTherapyStart ?? '')
                    ? 1
                    : 0
                ),
                morningCancelled: morningCancelledSlot.sort(),
                midMorningTherapy: [],
                midMorningCancelled: [],
                afterNoonTherapy: [],
                eveingTherapy: [],
                afterNoonCancelled: [],
                eveningCancelled: [],
              }
            }
          }
        }
      } else {
        if (
          inBetWeenTime(moment(item.endData).format('HH:mm'), '04:59', '08:01')
        ) {
          if (item.treatmentPlan) {
            if (getOrderStatusTextForChck(item.treatmentPlan) === 'Cancelled') {
              morningCancelledSlot.push({
                cancelledStart: item.date,
                cancelledEnd: item.endData,
              })
            } else {
              morning.push({
                morningTherapyEnd: item.endData,
                morningTherapyStart: item.date,
              })
            }
          }
        }
        dateWiseSlots[index].morningTherapy = morning.sort((a, b) =>
          (a.morningTherapyStart ? a.morningTherapyStart : '') <
          (b.morningTherapyStart ? b.morningTherapyStart : '')
            ? -1
            : (a.morningTherapyStart ?? '') > (b.morningTherapyStart ?? '')
            ? 1
            : 0
        )
        dateWiseSlots[index].morningCancelled = morningCancelledSlot.sort()
      }
    }
  })

  return dateWiseSlots
}

export function getDateWiseTherapyMidMorning(
  availableSlots: TreatmentPlanData[]
): TreatmentAssemble[] {
  const dateWiseSlots: TreatmentAssemble[] = []

  let midMorning: MidMornig[] = []
  let midMorningCancelled: string[] = []
  availableSlots.forEach((item) => {
    if (item.endData) {
      const date = moment(item.date).format('YYYY-MM-DD')
      const index = dateWiseSlots.findIndex(
        (s) => moment(s.date).format('YYYY-MM-DD') === date
      )

      if (index < 0) {
        midMorning = []
        midMorningCancelled = []
        if (
          inBetWeenTime(moment(item.endData).format('HH:mm'), '09:00', '12:31')
        ) {
          if (item.treatmentPlan) {
            if (getOrderStatusTextForChck(item.treatmentPlan) === 'Cancelled') {
              midMorningCancelled.push(item.date)
            } else {
              midMorning.push({
                midMorningStart: item.date,
                midMorningEnd: item.endData,
              })
            }
          }
        }
        const dateWiseSlot: TreatmentAssemble = {
          date: moment(item.endData).format('YYYY-MM-DD'),
          morningTherapy: [],
          morningCancelled: [],
          midMorningTherapy: midMorning.sort((a, b) =>
            (a.midMorningStart ? a.midMorningStart : '') <
            (b.midMorningStart ? b.midMorningStart : '')
              ? -1
              : (a.midMorningStart ?? '') > (b.midMorningStart ?? '')
              ? 1
              : 0
          ),
          midMorningCancelled: midMorningCancelled.sort(),
          afterNoonTherapy: [],
          eveingTherapy: [],
          afterNoonCancelled: [],
          eveningCancelled: [],
        }
        dateWiseSlots.push(dateWiseSlot)
      } else if (dateWiseSlots[index]?.midMorningTherapy) {
        const preEles = [...(dateWiseSlots[index].midMorningTherapy ?? [])]

        if (
          inBetWeenTime(moment(item.endData).format('HH:mm'), '09:00', '12:31')
        ) {
          if (item.treatmentPlan) {
            if (getOrderStatusTextForChck(item.treatmentPlan) === 'Cancelled') {
              // morningCancelledSlot.push({
              //   cancelledStart: item.date,
              //   cancelledEnd: item.endData,
              // })
            } else {
              const morningItem: MidMornig = {
                midMorningStart: item.date,
                midMorningEnd: item.endData,
              }

              dateWiseSlots[index] = {
                date: moment(item.endData).format('YYYY-MM-DD'),
                morningTherapy: [],
                morningCancelled: [],
                midMorningTherapy: [...preEles, morningItem].sort((a, b) =>
                  (a.midMorningStart ? a.midMorningStart : '') <
                  (b.midMorningStart ? b.midMorningStart : '')
                    ? -1
                    : (a.midMorningStart ?? '') > (b.midMorningStart ?? '')
                    ? 1
                    : 0
                ),
                midMorningCancelled: [],
                afterNoonTherapy: [],
                eveingTherapy: [],
                afterNoonCancelled: [],
                eveningCancelled: [],
              }
            }
          }
        }
      } else {
        if (
          inBetWeenTime(moment(item.endData).format('HH:mm'), '09:00', '12:31')
        ) {
          if (item.treatmentPlan) {
            if (getOrderStatusTextForChck(item.treatmentPlan) === 'Cancelled') {
              midMorningCancelled.push(item.date)
            } else {
              midMorning.push({
                midMorningStart: item.date,
                midMorningEnd: item.endData,
              })
            }
          }
        }
        dateWiseSlots[index].midMorningTherapy = midMorning.sort((a, b) =>
          (a.midMorningStart ? a.midMorningStart : '') <
          (b.midMorningStart ? b.midMorningStart : '')
            ? -1
            : (a.midMorningStart ?? '') > (b.midMorningStart ?? '')
            ? 1
            : 0
        )
        dateWiseSlots[index].midMorningCancelled = midMorningCancelled.sort()
      }
    }
  })

  return dateWiseSlots
}

export function getDateWiseTherapyAfterNoon(
  availableSlots: TreatmentPlanData[]
): TreatmentAssemble[] {
  const dateWiseSlots: TreatmentAssemble[] = []

  let afterNoon: AfterNoon[] = []
  let afterNoonCancelledSlot: string[] = []
  availableSlots.forEach((item) => {
    if (item.endData) {
      const date = moment(item.date).format('YYYY-MM-DD')
      const index = dateWiseSlots.findIndex(
        (s) => moment(s.date).format('YYYY-MM-DD') === date
      )

      if (index < 0) {
        afterNoon = []
        afterNoonCancelledSlot = []
        if (
          inBetWeenTime(moment(item.endData).format('HH:mm'), '15:00', '17:30')
        ) {
          if (item.treatmentPlan) {
            if (getOrderStatusTextForChck(item.treatmentPlan) === 'Cancelled') {
              afterNoonCancelledSlot.push(item.date)
            } else {
              afterNoon.push({
                afterNoonStart: moment(item.date).toISOString(),
                afterNoonEnd: moment(item.endData).toISOString(),
              })
            }
          }
        }
        const dateWiseSlot: TreatmentAssemble = {
          date: moment(item.endData).format('YYYY-MM-DD'),
          morningTherapy: [],
          morningCancelled: [],
          midMorningTherapy: [],
          midMorningCancelled: [],
          afterNoonTherapy: afterNoon.sort((a, b) =>
            (a.afterNoonStart ? a.afterNoonStart : '') <
            (b.afterNoonStart ? b.afterNoonStart : '')
              ? -1
              : (a.afterNoonStart ?? '') > (b.afterNoonStart ?? '')
              ? 1
              : 0
          ),
          eveingTherapy: [],
          afterNoonCancelled: afterNoonCancelledSlot,
          eveningCancelled: [],
        }
        dateWiseSlots.push(dateWiseSlot)
      } else if (dateWiseSlots[index]?.afterNoonTherapy) {
        const preEles = [...(dateWiseSlots[index].afterNoonTherapy ?? [])]

        if (
          inBetWeenTime(moment(item.endData).format('HH:mm'), '15:00', '17:30')
        ) {
          if (item.treatmentPlan) {
            if (getOrderStatusTextForChck(item.treatmentPlan) === 'Cancelled') {
              // morningCancelledSlot.push({
              //   cancelledStart: item.date,
              //   cancelledEnd: item.endData,
              // })
            } else {
              const morningItem: AfterNoon = {
                afterNoonStart: moment(item.date).toISOString(),
                afterNoonEnd: moment(item.endData).toISOString(),
              }

              dateWiseSlots[index] = {
                date: moment(item.endData).format('YYYY-MM-DD'),
                morningTherapy: [],
                morningCancelled: [],
                midMorningTherapy: [],
                midMorningCancelled: [],
                afterNoonTherapy: [...preEles, morningItem].sort((a, b) =>
                  (a.afterNoonStart ? a.afterNoonStart : '') <
                  (b.afterNoonEnd ? b.afterNoonEnd : '')
                    ? -1
                    : (a.afterNoonStart ?? '') > (b.afterNoonStart ?? '')
                    ? 1
                    : 0
                ),
                eveingTherapy: [],
                afterNoonCancelled: [],
                eveningCancelled: [],
              }
            }
          }
        }
      } else {
        if (
          inBetWeenTime(moment(item.endData).format('HH:mm'), '15:00', '17:30')
        ) {
          if (item.treatmentPlan) {
            if (getOrderStatusTextForChck(item.treatmentPlan) === 'Cancelled') {
              afterNoonCancelledSlot.push(item.date)
            } else {
              afterNoon.push({
                afterNoonStart: moment(item.date).toISOString(),
                afterNoonEnd: moment(item.endData).toISOString(),
              })
            }
          }
        }
        dateWiseSlots[index].afterNoonTherapy = afterNoon.sort((a, b) =>
          (a.afterNoonStart ? a.afterNoonStart : '') <
          (b.afterNoonStart ? b.afterNoonStart : '')
            ? -1
            : (a.afterNoonStart ?? '') > (b.afterNoonStart ?? '')
            ? 1
            : 0
        )
        dateWiseSlots[index].afterNoonCancelled = afterNoonCancelledSlot.sort()
      }
    }
  })
  return dateWiseSlots
}

export function getDateWiseTherapyEvening(
  availableSlots: TreatmentPlanData[]
): TreatmentAssemble[] {
  const dateWiseSlots: TreatmentAssemble[] = []

  const dateWiseSlotsFinal: DateWiseIPDAppointments[] = []
  let evening: Evening[] = []
  let eveingCancelledSlot: string[] = []
  availableSlots.forEach((item) => {
    if (item.endData) {
      const date = moment(item.date).format('YYYY-MM-DD')
      const index = dateWiseSlots.findIndex(
        (s) => moment(s.date).format('YYYY-MM-DD') === date
      )

      if (index < 0) {
        evening = []
        eveingCancelledSlot = []
        if (
          inBetWeenTime(moment(item.endData).format('HH:mm'), '19:00', '19:30')
        ) {
          if (item.treatmentPlan) {
            if (getOrderStatusTextForChck(item.treatmentPlan) === 'Cancelled') {
              eveingCancelledSlot.push(item.date)
            } else {
              evening.push({
                eveingStart: moment(item.date).toISOString(),
                eveningEnd: moment(item.endData).toISOString(),
              })
            }
          }
        }
        const dateWiseSlot: TreatmentAssemble = {
          date: moment(item.endData).format('YYYY-MM-DD'),
          morningTherapy: [],
          morningCancelled: [],
          midMorningTherapy: [],
          afterNoonTherapy: [],
          midMorningCancelled: [],
          eveingTherapy: evening.sort((a, b) =>
            (a.eveingStart ? a.eveingStart : '') <
            (b.eveingStart ? b.eveingStart : '')
              ? -1
              : (a.eveingStart ?? '') > (b.eveingStart ?? '')
              ? 1
              : 0
          ),
          afterNoonCancelled: [],
          eveningCancelled: eveingCancelledSlot,
        }
        dateWiseSlots.push(dateWiseSlot)
      } else if (dateWiseSlots[index]?.eveingTherapy) {
        const preEles = [...(dateWiseSlots[index].eveingTherapy ?? [])]

        if (
          inBetWeenTime(moment(item.endData).format('HH:mm'), '19:00', '19:30')
        ) {
          if (item.treatmentPlan) {
            if (getOrderStatusTextForChck(item.treatmentPlan) === 'Cancelled') {
              // morningCancelledSlot.push({
              //   cancelledStart: item.date,
              //   cancelledEnd: item.endData,
              // })
            } else {
              const morningItem: Evening = {
                eveingStart: moment(item.date).toISOString(),
                eveningEnd: moment(item.endData).toISOString(),
              }

              dateWiseSlots[index] = {
                date: moment(item.endData).format('YYYY-MM-DD'),
                morningTherapy: [],
                morningCancelled: [],
                midMorningTherapy: [],
                midMorningCancelled: [],
                afterNoonTherapy: [],
                eveingTherapy: [...preEles, morningItem].sort((a, b) =>
                  (a.eveingStart ? a.eveingStart : '') <
                  (b.eveingStart ? b.eveingStart : '')
                    ? -1
                    : (a.eveingStart ?? '') > (b.eveingStart ?? '')
                    ? 1
                    : 0
                ),
                afterNoonCancelled: [],
                eveningCancelled: [],
              }
            }
          }
        }
      } else {
        if (
          inBetWeenTime(moment(item.endData).format('HH:mm'), '19:00', '19:30')
        ) {
          if (item.treatmentPlan) {
            if (getOrderStatusTextForChck(item.treatmentPlan) === 'Cancelled') {
              //   afterNoonCancelledSlot.push(item.date)
            } else {
              evening.push({
                eveingStart: moment(item.date).toISOString(),
                eveningEnd: moment(item.endData).toISOString(),
              })
            }
          }
        }
        dateWiseSlots[index].eveingTherapy = evening.sort((a, b) =>
          (a.eveingStart ? a.eveingStart : '') <
          (b.eveingStart ? b.eveingStart : '')
            ? -1
            : (a.eveingStart ?? '') > (b.eveingStart ?? '')
            ? 1
            : 0
        )
        dateWiseSlots[index].afterNoonCancelled = []
      }
    }
  })

  return dateWiseSlots
}

export async function getCountForTherapist(
  organizationId: string,
  startDate: string,
  type?: string,
  gender?: string
): Promise<R4.IBundle | R4.IOperationOutcome | undefined> {
  try {
    const fhirClient: FHIRApiClient = new FHIRApiClient()

    const searchParameters: any = {
      _count: 0,
      intent: 'order',
      performer: `${`Organization/`}${organizationId}`,
      category: '225365006',
      status: 'active',
      _total: 'accurate',
    }

    if (type === 'ipd' || !type) {
      searchParameters['based-on:ServiceRequest.code'] = '281685003'
    } else if (type === 'opd') {
      searchParameters['based-on:ServiceRequest.code'] = '33022008'
    } else if (type !== 'appointment') {
      searchParameters['based-on:ServiceRequest.code'] = '304903009'
    }

    const occurrences: string[] = []

    if (startDate) {
      occurrences.push(`ge${moment(startDate).toISOString()}`)
    }

    if (occurrences.length > 0) {
      searchParameters.occurrence = occurrences
    }

    if (gender && gender.length > 0 && type !== 'appointment') {
      searchParameters['based-on:ServiceRequest.patient:Patient.gender'] =
        gender
    } else {
      searchParameters['subject:Patient.gender'] = gender
    }

    const r = await fhirClient.doGetResourceIncludeAndIncludeIterate(
      '/ServiceRequest',
      searchParameters
    )

    const completeDate = r

    return completeDate
  } catch (error) {
    throw error
  }

  return undefined
}

export async function getIPDDetailsAPI(
  accountId: string
): Promise<R4.IBundle | R4.IOperationOutcome | FHIRErrorResponses | undefined> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const searchParameters: any = {
    _id: accountId,
    _include: ['ServiceRequest:patient', 'ServiceRequest:servicereq-location'],
    '_include:iterate': [
      'ServiceRequest:performer',
      'Task:owner',
      'PractitionerRole:practitioner',
    ],
    _revinclude: ['Task:focus', 'Provenance:target', 'Observation:based-on'],
  }

  const res = await fhirClient.doGetResourceForAppointmentWithIncludeIterate1(
    `/ServiceRequest?_revinclude:iterate=PaymentReconciliation:request`,
    `ServiceRequest/${accountId}`,
    searchParameters
  )

  return res
}

export async function getIPDDetails(
  accountId: string
): Promise<FhirClinicIpdDetails | undefined> {
  try {
    const res = await getIPDDetailsAPI(accountId)

    if (res) {
      const appointmentResponse: R4.IBundle = res as R4.IBundle
      const fhirAppointments: FhirClinicIpdDetails[] =
        getExpandedServiceRequestFromBundleForIpd(appointmentResponse)

      if (fhirAppointments.length > 0) {
        return fhirAppointments[0]
      }
    }
  } catch (error) {}
  return undefined
}

export function getIPDCurrentStatus(ipd: FhirClinicIpdDetails): string {
  if (ipd.task && ipd.task.businessStatus) {
    switch (
      getDefaultCodeOfSystemFromCodableConcept(
        ipd.task.businessStatus
      )?.toLocaleLowerCase()
    ) {
      case 'scheduled':
        return 'room allotted'
      case 'admitted':
        if (ipd.serviceRequest.status === 'draft') {
          return 'room allotted'
        }
        return 'admitted'
    }
  }

  return 'initiated'
}

export function getIPDPastStatuses(ipd: FhirClinicIpdDetails): string[] {
  if (ipd.task && ipd.task.businessStatus) {
    switch (
      getDefaultCodeOfSystemFromCodableConcept(
        ipd.task.businessStatus
      )?.toLocaleLowerCase()
    ) {
      case 'scheduled':
        return ['initiated', 'room allotted']
      case 'admitted':
        if (ipd.serviceRequest.status === 'draft') {
          return ['initiated', 'room allotted']
        }
        return ['initiated', 'room allotted', 'admitted']
    }
  }

  return ['initiated']
}

export function getRoomPriceDataFromBundle(responseSlots: R4.IBundle): number {
  let price: number = 0

  const chargeItemDef: any = {}

  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ChargeItemDefinition':
                  chargeItemDef[value.resource.id] =
                    value.resource as R4.IChargeItemDefinition
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in chargeItemDef) {
          if (key) {
            const chargeItemDefinition: R4.IChargeItemDefinition =
              chargeItemDef[key] as R4.IChargeItemDefinition
            const priceComponent:
              | R4.IChargeItemDefinition_PriceComponent
              | undefined =
              getPriceComponentFromChangeItem(chargeItemDefinition)
            price = priceComponent
              ? priceComponent.amount
                ? priceComponent.amount.value ?? 0
                : 0
              : 0
          }
        }
      }
    }
  }

  return price
}

export function getExpandedAppointmentFromBundleForReport(
  responseSlots: R4.IBundle,
  name?: string
): ReportColumnsForAppointment[] {
  const convertedAppointments: FhirAppointmentDetail[] = []
  const reportAppointments: ReportColumnsForAppointment[] = []
  const appointments: any = {}
  const slots: any = {}
  const encounters: any = {}
  const practitioners: any = {}
  const patients: any = {}
  const practitionerRoles: any = {}
  const paymentReconilation: any = {}

  if (responseSlots.entry) {
    const entries: R4.IBundle_Entry[] = responseSlots.entry
    entries.forEach((value) => {
      if (value.resource) {
        if (value.resource.id) {
          switch (value.resource.resourceType) {
            case 'Appointment':
              appointments[value.resource.id] =
                value.resource as R4.IAppointment
              break
            case 'Slot':
              slots[value.resource.id] = value.resource as R4.ISlot
              break
            case 'Practitioner':
              practitioners[value.resource.id] =
                value.resource as R4.IPractitioner
              break
            case 'PractitionerRole':
              practitionerRoles[value.resource.id] =
                value.resource as R4.IPractitionerRole
              break
            case 'Encounter':
              encounters[
                value.resource.appointment?.[0].reference?.split('/')[1] ?? ''
              ] = value.resource as R4.IEncounter
              break
            case 'Patient':
              patients[value.resource.id] = value.resource as R4.IPatient
              break
            case 'PaymentReconciliation':
              paymentReconilation[value.resource.id] =
                value.resource as R4.IPaymentReconciliation
              break
            default:
              break
          }
        }
      }
    })

    for (const key in appointments) {
      if (key) {
        const currentAppointment: R4.IAppointment = appointments[
          key
        ] as R4.IAppointment

        let paymentId: string | undefined
        const slotId: string | undefined =
          currentAppointment.slot?.[0].reference?.split('/')[1]
        const practitionerId: string | undefined =
          currentAppointment?.participant
            ?.find((val) => val.actor?.reference?.includes('Practitioner/'))
            ?.actor?.reference?.split('/')[1]
        const practitionerRoleId: string | undefined =
          currentAppointment?.participant
            ?.find((val) => val.actor?.reference?.includes('PractitionerRole/'))
            ?.actor?.reference?.split('/')[1]

        const patientId: string | undefined = currentAppointment?.participant
          ?.find((val) => val.actor?.reference?.includes('Patient/'))
          ?.actor?.reference?.split('/')[1]
        for (const key1 in paymentReconilation) {
          if (key1) {
            const paymentInfo: R4.IPaymentReconciliation = paymentReconilation[
              key1
            ] as R4.IPaymentReconciliation

            const chargeID: string | undefined =
              getValueRefValueExtensionsOfUrl(
                paymentInfo.extension,
                'http://wellopathy.com/fhir/india/core/StructureDefinition/appointment-ref'
              )?.split('/')[1]
            if (chargeID === currentAppointment.id) {
              paymentId = paymentInfo.id
            }
          }
        }

        const practitionerRoleData: R4.IPractitionerRole =
          practitionerRoles[practitionerRoleId ?? '']

        if (practitionerRoleData) {
          const orgId = practitionerRoleData.organization?.id ?? ''

          const startStr: string =
            (appointments[key] as R4.IAppointment).start ?? ''

          const appointmentData = {
            start: (appointments[key] as R4.IAppointment).start ?? '',
            end: (appointments[key] as R4.IAppointment).end ?? '',
            appointment: appointments[key],
            slot: slots[slotId ?? ''],
            patient: patients[patientId ?? ''],
            encounter:
              encounters[(appointments[key] as R4.IAppointment).id ?? ''],
            practitionerDetail: {
              practitioner: practitioners[practitionerId ?? ''],
              practitionerRole: practitionerRoles[practitionerRoleId ?? ''],
            },
            token: '',
            roomId: '',
            paymentReconcilation: paymentReconilation[paymentId ?? ''],
            enabled: !!(
              (moment(moment(moment()).format('YYYY-MM-DDTHH:mm:ss')).diff(
                moment(startStr).format('YYYY-MM-DDTHH:mm:ss'),
                'hours'
              ) <= 24 &&
                moment(moment(moment()).format('YYYY-MM-DDTHH:mm:ss')).diff(
                  moment(startStr).format('YYYY-MM-DDTHH:mm:ss'),
                  'hours'
                ) >= 0 &&
                Number.isNaN(
                  moment(moment(moment()).format('YYYY-MM-DDTHH:mm:ss')).diff(
                    moment(startStr).format('YYYY-MM-DDTHH:mm:ss'),
                    'hours'
                  )
                ) === false) ||
              moment(moment(moment()).format('YYYY-MM-DD')).isSame(
                moment(startStr).format('YYYY-MM-DD')
              )
            ),
          }

          reportAppointments.push({
            appointment: appointmentData.start,
            appointmentType: appointmentData.appointment.serviceType
              ? appointmentData.appointment.serviceType![0].coding![0]
                  .display ?? ''
              : '',
            patientId: appointmentData.patient.id ?? '',
            patient_name: getNameFromHumanName(appointmentData.patient.name!),
            phoneNo: `${getTelecomOfPatientEdit(appointmentData.patient)}`,
            AmountPaid: appointmentData.paymentReconcilation
              ? appointmentData.paymentReconcilation.paymentAmount
                ? appointmentData.paymentReconcilation.paymentAmount.value ?? 0
                : 0
              : 0,
            type: '',
            age: getAgeOfPatientData(appointmentData.patient),
            gender: getGenderOfPatient(appointmentData.patient),
            address:
              getAddressOfPatient(appointmentData.patient.address ?? []) ?? '',
            availableAppointments: appointmentData,
            ipNumber: 0,
            start: appointmentData.start,
            serviceRequest: '',
          })
        }
      }
    }
  }

  return reportAppointments
}

export function getExpandedIPDwithFhirResource(
  responseBundle: R4.IBundle
): FhirIPDwithPatientDetails[] {
  const convertedAppointments: FhirIPDwithPatientDetails[] = []
  const appointments: any = {}

  const patients: any = {}

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ServiceRequest':
                  appointments[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break

                case 'Patient':
                  patients[value.resource.id] = value.resource as R4.IPatient
                  break

                default:
                  break
              }
            }
          }
        })

        for (const key in appointments) {
          if (key) {
            const currentAppointment: R4.IServiceRequest = appointments[
              key
            ] as R4.IServiceRequest

            const patientId: string | undefined =
              currentAppointment?.subject.reference?.split('/')[1]

            convertedAppointments.push({
              mainServiceRequest: appointments[key],
              patient: patients[patientId ?? ''],
            })
          }
        }
      }
    }
  }
  return convertedAppointments
}

export function getExpandedwithFhirResourceOPD(
  responseBundle: R4.IBundle
): FhirAppointmentWithPatient[] {
  const convertedAppointments: FhirAppointmentWithPatient[] = []
  const appointments: any = {}

  const patients: any = {}

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Appointment':
                  appointments[value.resource.id] =
                    value.resource as R4.IAppointment
                  break

                case 'Patient':
                  patients[value.resource.id] = value.resource as R4.IPatient
                  break

                default:
                  break
              }
            }
          }
        })

        for (const key in appointments) {
          if (key) {
            const currentAppointment: R4.IAppointment = appointments[
              key
            ] as R4.IAppointment

            const patientId: string | undefined =
              currentAppointment?.participant
                ?.find((val) => val.actor?.reference?.includes('Patient/'))
                ?.actor?.reference?.split('/')[1]

            convertedAppointments.push({
              appointment: appointments[key],
              patient: patients[patientId ?? ''],
            })
          }
        }
      }
    }
  }
  return convertedAppointments
}

export function getTherapiesBasicsFromBundleForAppointment(
  responseSlots: R4.IBundle,
  gender: string
): FhirClinicTherapyBasic[] {
  const convertedAppointments: FhirClinicTherapyBasic[] = []
  const serviceRequests: any = {}
  const procedures: R4.IProcedure[] = []

  if (responseSlots.entry) {
    const entries: R4.IBundle_Entry[] = responseSlots.entry
    entries.forEach((value) => {
      if (value.resource) {
        if (value.resource.id) {
          switch (value.resource.resourceType) {
            case 'ServiceRequest':
              serviceRequests[value.resource.id] =
                value.resource as R4.IServiceRequest
              break
            case 'Procedure':
              procedures.push(value.resource as R4.IProcedure)
              break
            default:
              break
          }
        }
      }
    })

    for (const key in serviceRequests) {
      if (key) {
        const currentAppointment: R4.IServiceRequest = serviceRequests[
          key
        ] as R4.IServiceRequest

        if (
          currentAppointment.basedOn &&
          currentAppointment.basedOn.length > 0
        ) {
          const index = currentAppointment.basedOn.findIndex((e) => {
            if (
              e.reference &&
              e.reference.toLowerCase().includes('servicerequest')
            ) {
              return true
            }
            return false
          })
          if (index > -1) {
            let startTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
              ''
            let endTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
              ''

            startTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                ?.start ?? ''
            endTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                ?.end ?? ''
            const procedureIndex = procedures.findIndex((e) => {
              if (e.basedOn && e.basedOn.length > 0) {
                const srIndex = e.basedOn.findIndex((ref) => {
                  if (ref.reference && ref.reference.length > 0) {
                    const id = ref.reference.split('/')[1]
                    return id === serviceRequests[key].id
                  }

                  return false
                })
                return srIndex > -1
              }
              return false
            })

            convertedAppointments.push({
              therapy: serviceRequests[key],
              start: startTime,
              end: endTime,
              procedure:
                procedureIndex > -1 ? procedures[procedureIndex] : undefined,
              mainServiceRequestId:
                currentAppointment.basedOn[index].reference!.split('/')[1],
              patientReference: currentAppointment.subject.reference ?? '',
            })
          } else {
            let startTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
              ''
            let endTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
              ''

            startTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                ?.start ?? ''
            endTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                ?.end ?? ''
            const procedureIndex = procedures.findIndex((e) => {
              if (e.basedOn && e.basedOn.length > 0) {
                const srIndex = e.basedOn.findIndex((ref) => {
                  if (ref.reference && ref.reference.length > 0) {
                    const id = ref.reference.split('/')[1]
                    return id === serviceRequests[key].id
                  }

                  return false
                })
                return srIndex > -1
              }
              return false
            })

            convertedAppointments.push({
              therapy: serviceRequests[key],
              start: startTime,
              end: endTime,
              procedure:
                procedureIndex > -1 ? procedures[procedureIndex] : undefined,
              mainServiceRequestId: getValueRefValueExtensionsOfUrl(
                currentAppointment.extension ?? [],
                'http://hl7.org/fhir/StructureDefinition/event-partOf'
              ).split('/')[1],
              patientReference: currentAppointment.subject.reference ?? '',
            })
          }
        }
      }
    }
  }

  return convertedAppointments
}

export function getExpandedServiceRequestFromBundleForDashboard(
  responseSlots: R4.IBundle
): IpdAdmission[] {
  const convertedAppointments: IpdAdmission[] = []
  const serviceRequests: any = {}
  const patients: any = {}

  if (responseSlots.entry) {
    if (responseSlots.entry.length > 0) {
      const entries: R4.IBundle_Entry[] = responseSlots.entry
      entries.forEach((value) => {
        if (value.resource) {
          if (value.resource.id) {
            switch (value.resource.resourceType) {
              case 'ServiceRequest':
                serviceRequests[value.resource.id] =
                  value.resource as R4.IServiceRequest
                break

              case 'Patient':
                patients[value.resource.id] = value.resource as R4.IPatient
                break

              default:
                break
            }
          }
        }
      })

      for (const key in serviceRequests) {
        if (key) {
          const currentAppointment: R4.IServiceRequest = serviceRequests[
            key
          ] as R4.IServiceRequest

          const locIdData: string | undefined =
            currentAppointment.locationReference
              ? currentAppointment.locationReference[0].reference?.split('/')[1]
              : undefined

          const patientId: string | undefined =
            currentAppointment?.subject.reference?.split('/')[1]
          let startTime =
            (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
            ''
          let endTime =
            (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
            ''

          startTime =
            (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
              ?.start ?? ''
          endTime =
            (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
              ?.end ?? ''

          let ipd: string = getIdentifierValueBySystem(
            serviceRequests[key].identifier ?? [],
            'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
          )

          if (ipd.length === 0) {
            ipd = getIdentifierValueBySystem(
              serviceRequests[key].identifier ?? [],
              'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
            )
          }
          if (!isTherapist()) {
            convertedAppointments.push({
              start: startTime,
              end: endTime,
              serviceRequest: serviceRequests[key],
              patient: patients[patientId ?? ''],
              ipdNumber: ipd ? parseInt(ipd.split('/')[4], 10) : 0,
              patientPhoto: '',
              unitId: '',
            })
          }
        }
      }
    }
  }

  convertedAppointments.sort((n1, n2) => n1.ipdNumber - n2.ipdNumber)
  return convertedAppointments
}
