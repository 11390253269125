import {
  Box,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
  Divider,
  ListSubheader,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  Tooltip,
  Collapse,
  Link,
} from '@material-ui/core'

import {
  DeleteOutlineOutlined,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  Close,
  Add,
  ExpandMore,
} from '@material-ui/icons'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedLabResults } from 'models/groupedLabResults'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { resetAddLabTestSlice } from 'redux/consultation/addlabTest/addLabTestIPD/addLabTestSlice'
import { requestLabTestDetailsDetailsOfFollowupPatient } from 'redux/consultation/followUpLabTest/FollowUpLabResultSlice'
import { requestLabTestDetailsDetailsOfPatient } from 'redux/consultation/labTestOPD/labDetailSlice'
import { requestVitalDetailsOfPatient } from 'redux/consultation/vitalsDetailsSlice/vitalsDetailSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'

import {
  isMedicalServiceProvider,
  isPhysiotherapist,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getAppointmentIdForFollowUp } from 'utils/fhirResourcesHelper'
import { getDateWiseVitals } from 'utils/fhirResoureHelpers/labOrderHelpers'
import {
  getObservationCodeText,
  getObservationContent,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { logger } from 'utils/logger'
import { AddLabTest } from 'views/components/ipdconusltation/objective/addLabTestResult'
import { VitalElement } from 'views/components/LeftMenu/VitalElement'
import { AddLabTestOPD } from './AddLabTest'
import { LabResultTile } from './tiles/labResultTile'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#333333',
  },
  subTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    fontStyle: 'normal',
    textTransform: 'uppercase',
    color: '#333333',
  },
  tabTitleText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
    textTransform: 'uppercase',
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '19px',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  rejectButtonText: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
  },
}))

interface VitalsProp {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
}

export const FollowUpLabResults: React.FC<VitalsProp> = ({
  fhirAppointmentDetails,
  split,
}: VitalsProp) => {
  const { t } = useTranslation(['common'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const [groupedLabTest, setGroupedLabTest] = useState<GroupedLabResults[]>([])
  const [groupedCbcPanel, setGroupedCbcPanel] = useState<GroupedLabResults[]>(
    []
  )
  const labTestSearchSlice = useSelector(
    (state: RootState) => state.diagnosticObsFinderSlice
  )

  const [groupedPanel2, setGroupedPanel2] = useState<GroupedLabResults[]>([])
  const [groupedPanel3, setGroupedPanel3] = useState<GroupedLabResults[]>([])
  const [groupedLft, setGroupedLft] = useState<GroupedLabResults[]>([])
  const [groupedRft, setGroupedRft] = useState<GroupedLabResults[]>([])
  const [groupedPanel4, setGroupedPanel4] = useState<GroupedLabResults[]>([])
  const classes = useStyles()
  const dispatch = useDispatch()
  const vitalsDetailsSlice = useSelector(
    (state: RootState) => state.FollowUpLabResultSlice
  )
  const appointmentId: string = getAppointmentIdForFollowUp(
    fhirAppointmentDetails.appointment.supportingInformation ?? []
  )

  useEffect(() => {
    dispatch(
      requestLabTestDetailsDetailsOfFollowupPatient(
        fhirAppointmentDetails.patient,
        appointmentId
      )
    )
  }, [dispatch, fhirAppointmentDetails])
  useEffect(() => {
    if (
      vitalsDetailsSlice.resultsAvailable &&
      vitalsDetailsSlice.groupedLabTests
    ) {
      updateLabTest(vitalsDetailsSlice.groupedLabTests)
    }
    if (
      vitalsDetailsSlice.resultsAvailable &&
      vitalsDetailsSlice.groupedPanel1 &&
      vitalsDetailsSlice.groupedPanel1.length > 0
    ) {
      updatePanel1(vitalsDetailsSlice.groupedPanel1 ?? [])
    }
    if (
      vitalsDetailsSlice.resultsAvailable &&
      vitalsDetailsSlice.groupedPanel2 &&
      vitalsDetailsSlice.groupedPanel2.length > 0
    ) {
      updatePanel2(vitalsDetailsSlice.groupedPanel2 ?? [])
    }
    if (
      vitalsDetailsSlice.resultsAvailable &&
      vitalsDetailsSlice.groupedPanel3 &&
      vitalsDetailsSlice.groupedPanel3.length > 0
    ) {
      updatePanel3(vitalsDetailsSlice.groupedPanel3 ?? [])
    }
    if (
      vitalsDetailsSlice.resultsAvailable &&
      vitalsDetailsSlice.groupedLft &&
      vitalsDetailsSlice.groupedLft.length > 0
    ) {
      updateLft(vitalsDetailsSlice.groupedLft ?? [])
    }
    if (
      vitalsDetailsSlice.resultsAvailable &&
      vitalsDetailsSlice.groupedRft &&
      vitalsDetailsSlice.groupedRft.length > 0
    ) {
      updateRft(vitalsDetailsSlice.groupedRft ?? [])
    }
    if (
      vitalsDetailsSlice.resultsAvailable &&
      vitalsDetailsSlice.groupedPanel4 &&
      vitalsDetailsSlice.groupedPanel4.length > 0
    ) {
      updatePanel4(vitalsDetailsSlice.groupedPanel4 ?? [])
    }
  }, [vitalsDetailsSlice])

  function updateLabTest(labResult: GroupedLabResults[]) {
    const results: GroupedLabResults[] = []
    for (let i = 0; i < labResult.length; i++) {
      results.push({
        name: '',
        date: labResult[i].date,
        occupation: labResult[i].occupation,
        checked: labResult[i].checked,
      })
    }
    setGroupedLabTest(results)
  }

  function updatePanel1(panel1: GroupedLabResults[]) {
    const results: GroupedLabResults[] = []
    for (let i = 0; i < panel1.length; i++) {
      results.push({
        name: '',
        date: panel1[i].date,
        occupation: panel1[i].occupation,
        checked: panel1[i].checked,
      })
    }
    setGroupedCbcPanel(results)
  }

  function updatePanel2(panel: GroupedLabResults[]) {
    const results: GroupedLabResults[] = []
    for (let i = 0; i < panel.length; i++) {
      results.push({
        name: '',
        date: panel[i].date,
        occupation: panel[i].occupation,
        checked: panel[i].checked,
      })
    }
    setGroupedPanel2(results)
  }
  function updatePanel3(panel: GroupedLabResults[]) {
    const results: GroupedLabResults[] = []
    for (let i = 0; i < panel.length; i++) {
      results.push({
        name: '',
        date: panel[i].date,
        occupation: panel[i].occupation,
        checked: panel[i].checked,
      })
    }
    setGroupedPanel3(results)
  }

  function updateLft(panel: GroupedLabResults[]) {
    const results: GroupedLabResults[] = []
    for (let i = 0; i < panel.length; i++) {
      results.push({
        name: '',
        date: panel[i].date,
        occupation: panel[i].occupation,
        checked: panel[i].checked,
      })
    }
    setGroupedLft(results)
  }

  function updateRft(panel: GroupedLabResults[]) {
    const results: GroupedLabResults[] = []
    for (let i = 0; i < panel.length; i++) {
      results.push({
        name: '',
        date: panel[i].date,
        occupation: panel[i].occupation,
        checked: panel[i].checked,
      })
    }
    setGroupedRft(results)
  }
  function updatePanel4(panel: GroupedLabResults[]) {
    const results: GroupedLabResults[] = []
    for (let i = 0; i < panel.length; i++) {
      results.push({
        name: '',
        date: panel[i].date,
        occupation: panel[i].occupation,
        checked: panel[i].checked,
      })
    }
    setGroupedPanel4(results)
  }

  function handleCollapse(rate: boolean, index: number) {
    const values: GroupedLabResults[] = [...groupedLabTest]
    values[index].checked = rate
    setGroupedLabTest(values)
  }

  function handleCollapseForPanel1(rate: boolean, index: number) {
    const values: GroupedLabResults[] = [...groupedCbcPanel]
    values[index].checked = rate
    setGroupedCbcPanel(values)
  }
  function handleCollapseForPanel2(rate: boolean, index: number) {
    const values: GroupedLabResults[] = [...groupedPanel2]
    values[index].checked = rate
    setGroupedPanel2(values)
  }
  function handleCollapseForPanel3(rate: boolean, index: number) {
    const values: GroupedLabResults[] = [...groupedPanel3]
    values[index].checked = rate
    setGroupedPanel3(values)
  }
  function handleCollapseForLft(rate: boolean, index: number) {
    const values: GroupedLabResults[] = [...groupedLft]
    values[index].checked = rate
    setGroupedLft(values)
  }

  function handleCollapseForRft(rate: boolean, index: number) {
    const values: GroupedLabResults[] = [...groupedRft]
    values[index].checked = rate
    setGroupedRft(values)
  }

  function handleCollapseForPanel4(rate: boolean, index: number) {
    const values: GroupedLabResults[] = [...groupedPanel4]
    values[index].checked = rate
    setGroupedPanel4(values)
  }
  return (
    <ReactVisibilitySensor
      offset={{
        top: 100,
        bottom: 30,
      }}
      onChange={(isVisible) => {
        logger.info('Inside diagnostic')
        if (isVisible) {
          dispatch(setSelectedSection({ selectedSection: 'vitals' }))
        }
      }}
    >
      <Grid container direction='column' style={{ flexWrap: 'nowrap' }}>
        <Grid
          item
          //   onMouseEnter={() => showAddButton(true)}
          //   onMouseLeave={() => showAddButton(false)}
        >
          <Grid container direction='row' id='vitals'>
            <Box py={1}>
              <Typography variant='h6'>
                {' '}
                Previous Appointment {t('labelCommon:lab_tests')}{' '}
              </Typography>
            </Box>{' '}
            {/* {isMedicalServiceProvider() && !isPhysiotherapist() && !split && (
              <Box>
                <IconButton
                  style={{ padding: '4px' }}
                  onClick={() => {
                    setOpenAddDialogue(true)
                  }}
                >
                  <Add style={{ height: '16px' }} color='primary' />
                </IconButton>
              </Box>
            )} */}
          </Grid>

          {vitalsDetailsSlice.searchingConditions && (
            <Grid item>
              <CircularProgress size={15} />
            </Grid>
          )}
          {vitalsDetailsSlice.noResultsAvailable && (
            <Grid item>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                No results available
              </Typography>
            </Grid>
          )}
          {vitalsDetailsSlice.errorWhileSearchingProcedures && (
            <Grid item>
              <Typography
                variant='subtitle2'
                style={{
                  fontWeight: 400,
                }}
              >
                {vitalsDetailsSlice.errorReason ??
                  'Error while fetching Lab Results'}
              </Typography>
            </Grid>
          )}
          {vitalsDetailsSlice.resultsAvailable &&
            vitalsDetailsSlice.groupedLabTests && (
              <Box display='flex' flexDirection='column' width='100%'>
                <Box py={1}>
                  {groupedLabTest && groupedLabTest.length > 0 && (
                    <Grid
                      item
                      style={{ padding: 0, margin: 0 }}
                      xs={split ? 12 : 12}
                    >
                      <Box
                        flexGrow
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        {groupedLabTest.map((val, index: number) => (
                          <Box key={val.date ?? ''}>
                            <Box
                              paddingX={1}
                              borderRadius={2}
                              style={{
                                backgroundColor: 'lightGrey',
                              }}
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              height={40}
                              paddingY={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow={1}
                                alignItems='center'
                              >
                                <Typography
                                  variant='subtitle1'
                                  style={{
                                    color: 'black',

                                    fontWeight: 'bold',
                                  }}
                                >
                                  Lab Test Results :{' '}
                                  {moment(val.date).format(
                                    'Do MMM YYYY hh:mm A'
                                  )}
                                </Typography>
                              </Box>
                              <Box
                                justifyContent='flex-end'
                                display='flex'
                                flexGrow={1}
                                alignItems='center'
                              >
                                <Box px={1}>
                                  <Tooltip title=''>
                                    <IconButton
                                      aria-label='collapse_order_type'
                                      size='small'
                                      onClick={() => {
                                        handleCollapse(!val.checked, index)
                                      }}
                                    >
                                      {val.checked && <ArrowDropUpOutlined />}
                                      {!val.checked && (
                                        <ArrowDropDownOutlined />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Box>
                            </Box>
                            <Collapse
                              in={val.checked}
                              style={{
                                width: '100%',
                              }}
                            >
                              <Box
                                flexGrow
                                width='100%'
                                display='flex'
                                flexDirection='column'
                              >
                                <Grid
                                  container
                                  direction='row'
                                  id='vitals'
                                  style={{
                                    backgroundColor: kDialogueBackground,
                                  }}
                                >
                                  <Grid item xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Parameter Name
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>

                                  <Grid item key={1} xs={5}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Value
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item key={1} xs={4}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Normal Range
                                          </Typography>
                                        </Box>
                                      </Box>
                                      {/* <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box> */}
                                    </Box>
                                  </Grid>
                                </Grid>
                                <LabResultTile
                                  obsData={val.occupation}
                                  obsDefData={[]}
                                  patientData={fhirAppointmentDetails.patient}
                                  type='test'
                                />
                              </Box>
                            </Collapse>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  )}
                </Box>
              </Box>
            )}
          {/* For panel1 --------------------------------------- */}

          {vitalsDetailsSlice.resultsAvailable &&
            vitalsDetailsSlice.groupedPanel1 && (
              <Box display='flex' flexDirection='column' width='100%'>
                <Box>
                  {groupedCbcPanel && groupedCbcPanel.length > 0 && (
                    <Grid
                      item
                      style={{ padding: 0, margin: 0 }}
                      xs={split ? 12 : 12}
                    >
                      <Box
                        flexGrow
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        {groupedCbcPanel.map((val, index: number) => (
                          <Box py={1} key={val.date ?? ''}>
                            <Box
                              paddingX={1}
                              borderRadius={2}
                              style={{
                                backgroundColor: 'lightGrey',
                              }}
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              height={40}
                              paddingY={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow={1}
                                alignItems='center'
                              >
                                <Typography
                                  variant='subtitle1'
                                  style={{
                                    color: 'black',

                                    fontWeight: 'bold',
                                  }}
                                >
                                  CBC Panel - Blood :{' '}
                                  {moment(val.date).format(
                                    'Do MMM YYYY hh:mm A'
                                  )}
                                </Typography>
                              </Box>
                              <Box
                                justifyContent='flex-end'
                                display='flex'
                                flexGrow={1}
                                alignItems='center'
                              >
                                <Box px={1}>
                                  <Tooltip title=''>
                                    <IconButton
                                      aria-label='collapse_order_type'
                                      size='small'
                                      onClick={() => {
                                        handleCollapseForPanel1(
                                          !val.checked,
                                          index
                                        )
                                      }}
                                    >
                                      {val.checked && <ArrowDropUpOutlined />}
                                      {!val.checked && (
                                        <ArrowDropDownOutlined />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Box>
                            </Box>
                            <Collapse
                              in={val.checked}
                              style={{
                                width: '100%',
                              }}
                            >
                              <Box
                                flexGrow
                                width='100%'
                                display='flex'
                                flexDirection='column'
                              >
                                <Grid
                                  container
                                  direction='row'
                                  id='vitals'
                                  style={{
                                    backgroundColor: kDialogueBackground,
                                  }}
                                >
                                  <Grid item xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Parameter Name
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>

                                  <Grid item key={1} xs={5}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Value
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item key={1} xs={4}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Normal Range
                                          </Typography>
                                        </Box>
                                      </Box>
                                      {/* <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box> */}
                                    </Box>
                                  </Grid>
                                </Grid>
                                <LabResultTile
                                  obsData={val.occupation}
                                  obsDefData={
                                    labTestSearchSlice.testData &&
                                    labTestSearchSlice.testData.length > 0
                                      ? labTestSearchSlice.testData[0].obsDef
                                      : []
                                  }
                                  patientData={fhirAppointmentDetails.patient}
                                />
                              </Box>
                            </Collapse>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  )}
                </Box>
              </Box>
            )}

          {/* For panel2 --------------------------------------- */}

          {vitalsDetailsSlice.resultsAvailable &&
            vitalsDetailsSlice.groupedPanel2 && (
              <Box display='flex' flexDirection='column' width='100%'>
                <Box>
                  {groupedPanel2 && groupedPanel2.length > 0 && (
                    <Grid
                      item
                      style={{ padding: 0, margin: 0 }}
                      xs={split ? 12 : 12}
                    >
                      <Box
                        flexGrow
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        {groupedPanel2.map((val, index: number) => (
                          <Box py={1} key={val.date ?? ''}>
                            <Box
                              paddingX={1}
                              borderRadius={2}
                              style={{
                                backgroundColor: 'lightGrey',
                              }}
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              height={40}
                              paddingY={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow={1}
                                alignItems='center'
                              >
                                <Typography
                                  variant='subtitle1'
                                  style={{
                                    color: 'black',

                                    fontWeight: 'bold',
                                  }}
                                >
                                  Diabetic Retinopathy Screening :{' '}
                                  {moment(val.date).format(
                                    'Do MMM YYYY hh:mm A'
                                  )}
                                </Typography>
                              </Box>
                              <Box
                                justifyContent='flex-end'
                                display='flex'
                                flexGrow={1}
                                alignItems='center'
                              >
                                <Box px={1}>
                                  <Tooltip title=''>
                                    <IconButton
                                      aria-label='collapse_order_type'
                                      size='small'
                                      onClick={() => {
                                        handleCollapseForPanel2(
                                          !val.checked,
                                          index
                                        )
                                      }}
                                    >
                                      {val.checked && <ArrowDropUpOutlined />}
                                      {!val.checked && (
                                        <ArrowDropDownOutlined />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Box>
                            </Box>
                            <Collapse
                              in={val.checked}
                              style={{
                                width: '100%',
                              }}
                            >
                              <Box
                                flexGrow
                                width='100%'
                                display='flex'
                                flexDirection='column'
                              >
                                <Grid
                                  container
                                  direction='row'
                                  id='vitals'
                                  style={{
                                    backgroundColor: kDialogueBackground,
                                  }}
                                >
                                  <Grid item xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Parameter Name
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>

                                  <Grid item key={1} xs={5}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Value
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item key={1} xs={4}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Normal Range
                                          </Typography>
                                        </Box>
                                      </Box>
                                      {/* <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box> */}
                                    </Box>
                                  </Grid>
                                </Grid>
                                <LabResultTile
                                  obsData={val.occupation}
                                  obsDefData={[]}
                                  patientData={fhirAppointmentDetails.patient}
                                />
                              </Box>
                            </Collapse>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  )}
                </Box>
              </Box>
            )}

          {/* For panel3 --------------------------------------- */}

          {vitalsDetailsSlice.resultsAvailable &&
            vitalsDetailsSlice.groupedPanel3 && (
              <Box display='flex' flexDirection='column' width='100%'>
                <Box>
                  {groupedPanel3 && groupedPanel3.length > 0 && (
                    <Grid
                      item
                      style={{ padding: 0, margin: 0 }}
                      xs={split ? 12 : 12}
                    >
                      <Box
                        flexGrow
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        {groupedPanel3.map((val, index: number) => (
                          <Box py={1} key={val.date ?? ''}>
                            <Box
                              paddingX={1}
                              borderRadius={2}
                              style={{
                                backgroundColor: 'lightGrey',
                              }}
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              height={40}
                              paddingY={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow={1}
                                alignItems='center'
                              >
                                <Typography
                                  variant='subtitle1'
                                  style={{
                                    color: 'black',

                                    fontWeight: 'bold',
                                  }}
                                >
                                  Lipid Profile :{' '}
                                  {moment(val.date).format(
                                    'Do MMM YYYY hh:mm A'
                                  )}
                                </Typography>
                              </Box>
                              <Box
                                justifyContent='flex-end'
                                display='flex'
                                flexGrow={1}
                                alignItems='center'
                              >
                                <Box px={1}>
                                  <Tooltip title=''>
                                    <IconButton
                                      aria-label='collapse_order_type'
                                      size='small'
                                      onClick={() => {
                                        handleCollapseForPanel3(
                                          !val.checked,
                                          index
                                        )
                                      }}
                                    >
                                      {val.checked && <ArrowDropUpOutlined />}
                                      {!val.checked && (
                                        <ArrowDropDownOutlined />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Box>
                            </Box>
                            <Collapse
                              in={val.checked}
                              style={{
                                width: '100%',
                              }}
                            >
                              <Box
                                flexGrow
                                width='100%'
                                display='flex'
                                flexDirection='column'
                              >
                                <Grid
                                  container
                                  direction='row'
                                  id='vitals'
                                  style={{
                                    backgroundColor: kDialogueBackground,
                                  }}
                                >
                                  <Grid item xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Parameter Name
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>

                                  <Grid item key={1} xs={5}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Value
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item key={1} xs={4}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Normal Range
                                          </Typography>
                                        </Box>
                                      </Box>
                                      {/* <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box> */}
                                    </Box>
                                  </Grid>
                                </Grid>
                                <LabResultTile
                                  obsData={val.occupation}
                                  obsDefData={
                                    labTestSearchSlice.lipidData &&
                                    labTestSearchSlice.lipidData.length > 0
                                      ? labTestSearchSlice.lipidData[0].obsDef
                                      : []
                                  }
                                  patientData={fhirAppointmentDetails.patient}
                                />
                              </Box>
                            </Collapse>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  )}
                </Box>
              </Box>
            )}

          {/* For LFT --------------------------------------- */}

          {vitalsDetailsSlice.resultsAvailable &&
            vitalsDetailsSlice.groupedLft && (
              <Box display='flex' flexDirection='column' width='100%'>
                <Box>
                  {groupedLft && groupedLft.length > 0 && (
                    <Grid
                      item
                      style={{ padding: 0, margin: 0 }}
                      xs={split ? 12 : 12}
                    >
                      <Box
                        flexGrow
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        {groupedLft.map((val, index: number) => (
                          <Box py={1} key={val.date ?? ''}>
                            <Box
                              paddingX={1}
                              borderRadius={2}
                              style={{
                                backgroundColor: 'lightGrey',
                              }}
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              height={40}
                              paddingY={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow={1}
                                alignItems='center'
                              >
                                <Typography
                                  variant='subtitle1'
                                  style={{
                                    color: 'black',

                                    fontWeight: 'bold',
                                  }}
                                >
                                  Liver Function Test :{' '}
                                  {moment(val.date).format(
                                    'Do MMM YYYY hh:mm A'
                                  )}
                                </Typography>
                              </Box>
                              <Box
                                justifyContent='flex-end'
                                display='flex'
                                flexGrow={1}
                                alignItems='center'
                              >
                                <Box px={1}>
                                  <Tooltip title=''>
                                    <IconButton
                                      aria-label='collapse_order_type'
                                      size='small'
                                      onClick={() => {
                                        handleCollapseForLft(
                                          !val.checked,
                                          index
                                        )
                                      }}
                                    >
                                      {val.checked && <ArrowDropUpOutlined />}
                                      {!val.checked && (
                                        <ArrowDropDownOutlined />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Box>
                            </Box>
                            <Collapse
                              in={val.checked}
                              style={{
                                width: '100%',
                              }}
                            >
                              <Box
                                flexGrow
                                width='100%'
                                display='flex'
                                flexDirection='column'
                              >
                                <Grid
                                  container
                                  direction='row'
                                  id='vitals'
                                  style={{
                                    backgroundColor: kDialogueBackground,
                                  }}
                                >
                                  <Grid item xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Parameter Name
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>

                                  <Grid item key={1} xs={5}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Value
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item key={1} xs={4}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Normal Range
                                          </Typography>
                                        </Box>
                                      </Box>
                                      {/* <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box> */}
                                    </Box>
                                  </Grid>
                                </Grid>
                                <LabResultTile
                                  obsData={val.occupation}
                                  obsDefData={
                                    labTestSearchSlice.lftData &&
                                    labTestSearchSlice.lftData.length > 0
                                      ? labTestSearchSlice.lftData[0].obsDef
                                      : []
                                  }
                                  patientData={fhirAppointmentDetails.patient}
                                />
                              </Box>
                            </Collapse>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  )}
                </Box>
              </Box>
            )}
          {/* For LFT --------------------------------------- */}

          {vitalsDetailsSlice.resultsAvailable &&
            vitalsDetailsSlice.groupedRft && (
              <Box display='flex' flexDirection='column' width='100%'>
                <Box>
                  {groupedRft && groupedRft.length > 0 && (
                    <Grid
                      item
                      style={{ padding: 0, margin: 0 }}
                      xs={split ? 12 : 12}
                    >
                      <Box
                        flexGrow
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        {groupedRft.map((val, index: number) => (
                          <Box py={1} key={val.date ?? ''}>
                            <Box
                              paddingX={1}
                              borderRadius={2}
                              style={{
                                backgroundColor: 'lightGrey',
                              }}
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              height={40}
                              paddingY={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow={1}
                                alignItems='center'
                              >
                                <Typography
                                  variant='subtitle1'
                                  style={{
                                    color: 'black',

                                    fontWeight: 'bold',
                                  }}
                                >
                                  Renal Function Test :{' '}
                                  {moment(val.date).format(
                                    'Do MMM YYYY hh:mm A'
                                  )}
                                </Typography>
                              </Box>
                              <Box
                                justifyContent='flex-end'
                                display='flex'
                                flexGrow={1}
                                alignItems='center'
                              >
                                <Box px={1}>
                                  <Tooltip title=''>
                                    <IconButton
                                      aria-label='collapse_order_type'
                                      size='small'
                                      onClick={() => {
                                        handleCollapseForRft(
                                          !val.checked,
                                          index
                                        )
                                      }}
                                    >
                                      {val.checked && <ArrowDropUpOutlined />}
                                      {!val.checked && (
                                        <ArrowDropDownOutlined />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Box>
                            </Box>
                            <Collapse
                              in={val.checked}
                              style={{
                                width: '100%',
                              }}
                            >
                              <Box
                                flexGrow
                                width='100%'
                                display='flex'
                                flexDirection='column'
                              >
                                <Grid
                                  container
                                  direction='row'
                                  id='vitals'
                                  style={{
                                    backgroundColor: kDialogueBackground,
                                  }}
                                >
                                  <Grid item xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Parameter Name
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>

                                  <Grid item key={1} xs={5}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Value
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item key={1} xs={4}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Normal Range
                                          </Typography>
                                        </Box>
                                      </Box>
                                      {/* <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box> */}
                                    </Box>
                                  </Grid>
                                </Grid>
                                <LabResultTile
                                  obsData={val.occupation}
                                  obsDefData={
                                    labTestSearchSlice.rftData &&
                                    labTestSearchSlice.rftData.length > 0
                                      ? labTestSearchSlice.rftData[0].obsDef
                                      : []
                                  }
                                  patientData={fhirAppointmentDetails.patient}
                                />
                              </Box>
                            </Collapse>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  )}
                </Box>
              </Box>
            )}

          {vitalsDetailsSlice.resultsAvailable &&
            vitalsDetailsSlice.groupedPanel4 && (
              <Box display='flex' flexDirection='column' width='100%'>
                <Box>
                  {groupedPanel4 && groupedPanel4.length > 0 && (
                    <Grid
                      item
                      style={{ padding: 0, margin: 0 }}
                      xs={split ? 12 : 12}
                    >
                      <Box
                        flexGrow
                        width='100%'
                        display='flex'
                        flexDirection='column'
                      >
                        {groupedPanel4.map((val, index: number) => (
                          <Box key={val.date ?? ''}>
                            <Box
                              paddingX={1}
                              borderRadius={2}
                              style={{
                                backgroundColor: 'lightGrey',
                              }}
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              height={40}
                              paddingY={1}
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow={1}
                                alignItems='center'
                              >
                                <Typography
                                  variant='subtitle1'
                                  style={{
                                    color: 'black',

                                    fontWeight: 'bold',
                                  }}
                                >
                                  Urinalysis Complete Panel :{' '}
                                  {moment(val.date).format(
                                    'Do MMM YYYY hh:mm A'
                                  )}
                                </Typography>
                              </Box>
                              <Box
                                justifyContent='flex-end'
                                display='flex'
                                flexGrow={1}
                                alignItems='center'
                              >
                                <Box px={1}>
                                  <Tooltip title=''>
                                    <IconButton
                                      aria-label='collapse_order_type'
                                      size='small'
                                      onClick={() => {
                                        handleCollapseForPanel4(
                                          !val.checked,
                                          index
                                        )
                                      }}
                                    >
                                      {val.checked && <ArrowDropUpOutlined />}
                                      {!val.checked && (
                                        <ArrowDropDownOutlined />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Box>
                            </Box>
                            <Collapse
                              in={val.checked}
                              style={{
                                width: '100%',
                              }}
                            >
                              <Box
                                flexGrow
                                width='100%'
                                display='flex'
                                flexDirection='column'
                              >
                                <Grid
                                  container
                                  direction='row'
                                  id='vitals'
                                  style={{
                                    backgroundColor: kDialogueBackground,
                                  }}
                                >
                                  <Grid item xs={3}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Parameter Name
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>

                                  <Grid item key={1} xs={5}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Value
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item key={1} xs={4}>
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Normal Range
                                          </Typography>
                                        </Box>
                                      </Box>
                                      {/* <Box
                                        display='flex'
                                        width='2%'
                                        paddingTop={0.1}
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box> */}
                                    </Box>
                                  </Grid>
                                </Grid>
                                <LabResultTile
                                  obsData={val.occupation}
                                  obsDefData={
                                    labTestSearchSlice.urineData &&
                                    labTestSearchSlice.urineData.length > 0
                                      ? labTestSearchSlice.urineData[0].obsDef
                                      : []
                                  }
                                  patientData={fhirAppointmentDetails.patient}
                                />
                              </Box>
                            </Collapse>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  )}
                </Box>
              </Box>
            )}
        </Grid>
      </Grid>
    </ReactVisibilitySensor>
  )
}
