import { IHealthcareService } from '@ahryman40k/ts-fhir-types/lib/R4'
import { Box, Typography, Button } from '@material-ui/core'
import {
  kBackGround,
  kBackgroundDefault,
  kBackgroundPaper,
  kPrimaryDark,
  kSelectedTabBackground,
} from 'configs/styles/muiThemes'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import React, { useEffect, useState } from 'react'
import {
  enableSearchingOfCrossFunctional,
  enableSearchingOfLabReferralOfUnit,
  getCrossFunctionalStatusForUnit,
  getOpenForLabReferralStatusOfUnit,
  hideSearchingOfCrossFunctional,
  hideSearchingOfLabReferralOfUnit,
} from 'utils/admisnistration/crossFunctional/crossFunctinalUtils'
import WelloSwitch from 'views/components/WelloSwitch'

import { IncomingCrossFunctionInvitationPage } from './incomingCrossFunctionInvitationPage'

interface TabDetail {
  code: string
  label: string
  enabled: boolean
}

interface Props {}

export const CrossOrgLabReferralManagementComponent: React.FC<Props> =
  ({}: Props) => {
    const defaultTabs = [
      {
        code: 'visibility',
        label: 'Search Visibility',
        enabled: true,
      },

      {
        code: 'invitation',
        label: 'Invitation',
        enabled: false,
      },
    ]

    const [fetchStatus, setFetchStatus] = useState<NetworkRequestStatus>({
      initial: true,
      requesting: false,
    })

    const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
      initial: true,
      requesting: false,
    })
    const [tabs, setTabs] = useState<TabDetail[]>(defaultTabs)
    const [selectedTab, setSelectedTab] = useState<string>(defaultTabs[0].code)
    const [searchableStatus, setSearchableStatus] = useState(false)

    const [currentCareStatus, setCurrentCareStatus] =
      useState<IHealthcareService | undefined>(undefined)

    function fetchCurrentCrossFunctionalStatus() {
      setFetchStatus({ requesting: true })
      getOpenForLabReferralStatusOfUnit().then((e) => {
        if (e !== false) {
          setFetchStatus({
            requesting: false,
            requestSuccess: true,
          })
          const hcs = e as IHealthcareService

          setCurrentCareStatus(e as IHealthcareService)
          if (hcs.active === true) {
            setSearchableStatus(true)
            setTabs([
              {
                code: 'visibility',
                label: 'Search Visibility',
                enabled: true,
              },
              {
                code: 'invitation',
                label: 'Invitation',
                enabled: true,
              },
            ])
          } else {
            setSearchableStatus(false)
            setTabs([
              {
                code: 'visibility',
                label: 'Search Visibility',
                enabled: true,
              },

              {
                code: 'invitation',
                label: 'Invitation',
                enabled: false,
              },
            ])
          }
          setFetchStatus({
            requesting: false,
            requestSuccess: true,
            requestError: false,
          })
        } else {
          setFetchStatus({
            requesting: false,
            requestError: true,
          })
        }
      })
    }

    function updateCrossFunctionalStatus() {
      if (
        (currentCareStatus === undefined ||
          currentCareStatus.active === false) &&
        searchableStatus === true
      ) {
        setUpdateStatus({ requesting: true })
        enableSearchingOfLabReferralOfUnit().then((e) => {
          if (e !== false) {
            setUpdateStatus({
              requesting: false,
              requestSuccess: true,
            })
            fetchCurrentCrossFunctionalStatus()
          } else {
            setFetchStatus({
              requesting: false,
              requestError: false,
            })
          }
        })
      } else if (
        (currentCareStatus === undefined ||
          currentCareStatus.active === true) &&
        searchableStatus === false
      ) {
        setUpdateStatus({ requesting: true })
        hideSearchingOfLabReferralOfUnit().then((e) => {
          if (e !== false) {
            setUpdateStatus({
              requesting: false,
              requestSuccess: true,
            })
            fetchCurrentCrossFunctionalStatus()
          } else {
            setFetchStatus({
              requesting: false,
              requestError: false,
            })
          }
        })
      }
    }

    function disableSaveButton() {
      if (updateStatus.requesting) {
        return true
      }
      if (searchableStatus === false && currentCareStatus === undefined) {
        return true
      }
      if (searchableStatus === false && currentCareStatus?.active === false) {
        return true
      }
      if (searchableStatus === currentCareStatus?.active) {
        return true
      }
      return false
    }

    useEffect(() => {
      fetchCurrentCrossFunctionalStatus()
    }, [])

    if (fetchStatus.requesting) {
      return (
        <Box
          display='flex'
          flexDirection='column'
          width='100%'
          height='100%'
          px={2}
          py={0.5}
          bgcolor={kBackgroundDefault}
        >
          <Typography
            variant='subtitle2'
            color='initial'
            style={{
              textTransform: 'capitalize',
            }}
          >
            Loading...
          </Typography>
        </Box>
      )
    }

    return (
      <Box display='flex' flexDirection='column' width='100%' height='100%'>
        <Box
          display='flex'
          flexDirection='row'
          width='100%'
          px={2}
          py={0.5}
          bgcolor={kBackgroundDefault}
        >
          <Typography variant='subtitle2' color='initial'>
            Search Visibility and Invitations for Referral
          </Typography>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          width='100%'
          height='100%'
          px={2}
          bgcolor={kBackgroundPaper}
        >
          <Box
            display='flex'
            flexDirection='row'
            width='100%'
            pt={1}
            borderBottom={1}
            borderColor={kBackgroundDefault}
          >
            {tabs.map((tab) => (
              <Box
                key={tab.code}
                display='flex'
                flexDirection='row'
                height='25px'
                pr={2}
                tabIndex={0}
                style={{
                  cursor: tab.enabled ? 'pointer' : 'not-allowed',
                }}
                onClick={() => {
                  if (tab.enabled) {
                    setSelectedTab(tab.code)
                  }
                }}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    if (tab.enabled) {
                      setSelectedTab(tab.code)
                    }
                  }
                }}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='space-between'
                  alignContent='center'
                  alignItems='center'
                  width='100%'
                  justifyItems='center'
                >
                  <Typography
                    variant='subtitle2'
                    color={selectedTab === tab.code ? 'primary' : 'initial'}
                    style={{
                      textTransform: 'capitalize',
                    }}
                  >
                    {tab.label}
                  </Typography>
                  <Box
                    width='25px'
                    height='2px'
                    style={{
                      borderRadius: '2px',

                      backgroundColor:
                        tab.code === selectedTab ? kPrimaryDark : 'transparent',
                    }}
                  />
                </Box>
              </Box>
            ))}
          </Box>

          {selectedTab === 'visibility' && (
            <Box
              display='flex'
              flexDirection='column'
              width='100%'
              height='100%'
              bgcolor={kBackgroundPaper}
            >
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                py={4}
                borderBottom={1}
                borderColor={kBackgroundDefault}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  width='60%'
                  justifyContent='space-between'
                >
                  <Typography variant='subtitle1' color='initial'>
                    Visible to Other Units
                  </Typography>

                  <WelloSwitch
                    checked={searchableStatus}
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter') {
                        if (searchableStatus === true) {
                          setSearchableStatus(false)
                        } else {
                          setSearchableStatus(true)
                        }
                      }
                    }}
                    onChange={(event, checked) => {
                      setSearchableStatus(checked)
                    }}
                    name='Searchable'
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='row'
                width='60%'
                py={1}
                justifyContent='space-between'
              >
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  disabled={disableSaveButton()}
                  onClick={() => {
                    updateCrossFunctionalStatus()
                  }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          )}

          {selectedTab === 'invitation' &&
            currentCareStatus &&
            currentCareStatus.id && (
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                height='100%'
                bgcolor={kBackgroundPaper}
              >
                <IncomingCrossFunctionInvitationPage />
              </Box>
            )}
        </Box>
      </Box>
    )
  }
