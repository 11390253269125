import { R4 } from '@ahryman40k/ts-fhir-types'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { doctorAppointmentView, DoctorBase } from 'lib/openApi'
import { DateWiseOrders } from 'models/dateSeparatedOrders'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { IpdAdmission, Print, PrintFormat } from 'models/fhirClinicIpdDetails'
import { FhirLabDiagnosticRequest } from 'models/fhirLabDiagnosticRequest'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import {
  FhirLabOrderFullDetail,
  TestAndResult,
} from 'models/fhirLabOrderFullDetails'
import {
  getAddress,
  getAgeOfPatientData,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameFromHumanName,
  getNameOfPatient,
  getTelecomFromContactPoints,
  getTelecomOfPatient,
  getCodeOfSystemFromCodableConcept,
  getExtensionValueOfUrl,
  isMarried,
  getAddressOfPatientForOPDAppointment,
  getExtensionCodingOfUrlPatient,
  getIdentifierValueBySystemTypeCoding,
  getIdentifierValueBySystem,
  getPassportStartDate,
  getPassportEndDate,
  getStringValueExtensionsOfUrl,
  getEmailOfPractitioner,
  getPhoneOfOrgAdmin,
  getFirstNameOfRelatedPerson,
  getLastNameOfRelatedPerson,
  getDataFromCoding,
  getVisaCity,
} from 'utils/fhirResourcesHelper'
import { LabOfferingDetail } from 'models/labOfferDetail'
import moment from 'moment'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserMainOrganizationDetails,
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserPractitionerRoleRef,
  getCurrentUserUnitDetails,
  getCurrentUserUnitReference,
  isOrgAdmin,
} from 'services/userDetailsService'
import {
  getCompleteDateStringWithOutDay,
  geTimeInString,
  geTimeInStringWithDate,
  getIpdInTImeWithDate,
  getShortTimeWithDate,
  getStartAndEndTimeInString,
  getStartAndEndTimeInStringWithDate,
  getStartAndEndTimeWithDateInString,
} from 'utils/dateUtil'
import { logger } from 'utils/logger'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { getUniqueTempId } from './idHelpers'
import {
  getLabOfferingDetailsFromBundle,
  getPriceComponentFromChangeItem,
} from './planDefinitionHelper'
import { getIpdAdmitTImeForOrg, getRoomNoForOrg } from './ipdAppointmentHelper'

export function getDateWiseOrders(
  availableSlots: FhirLabOrderDetail[]
): DateWiseOrders[] {
  const dateWiseSlots: DateWiseOrders[] = []

  availableSlots.forEach((item) => {
    if (item.start) {
      const date = moment(item.start).format('YYYY-MM-DD')
      const index = dateWiseSlots.findIndex(
        (s) => moment(s.date).format('YYYY-MM-DD') === date
      )
      if (index < 0) {
        const dateWiseSlot: DateWiseOrders = {
          date: item.start,
          orders: [item],
        }
        dateWiseSlots.push(dateWiseSlot)
      } else if (dateWiseSlots[index]?.orders) {
        dateWiseSlots[index].orders?.push(item)
      } else {
        dateWiseSlots[index].orders = [item]
      }
    }
  })

  return dateWiseSlots
}

export function isMorningSlot(slotTime: string): boolean {
  const currentHour = moment(slotTime).format('HH') as unknown as number

  if (currentHour >= 0 && currentHour < 12) {
    return true
  }
  return false
}
export function isAfterNoonSlot(slotTime: string): boolean {
  const currentHour = moment(slotTime).format('HH') as unknown as number

  if (currentHour >= 12 && currentHour < 18) {
    return true
  }
  return false
}

export function isEvening(slotTime: string): boolean {
  const currentHour = moment(slotTime).format('HH') as unknown as number

  if (currentHour >= 18 && currentHour < 24) {
    return true
  }
  return false
}

export function getOrderCreationSuccessfulMessage(
  patient: R4.IPatient
): string {
  const message: string = `Order created for ${getNameOfPatient(patient)}`

  return message
}

export function getAppointmentSuccessfulMessage(
  patient: R4.IPatient,
  selectedDoctor: R4.IPractitioner | undefined,
  selectedSlot: R4.ISlot
): string {
  const message: string = `Appointment created for ${getNameOfPatient(
    patient
  )} with ${getNameFromHumanName(
    selectedDoctor?.name ?? []
  )} on ${dateToFromNowDaily(selectedSlot.start?.toString() ?? '')} at ${moment(
    selectedSlot.start ?? ''
  ).format('HH:mm a')}`

  return message
}
export function getAppointmentCancelMessage(
  appointmentDetails: FhirAppointmentDetail
): string {
  const message: string = `Appointment cancelled for ${getNameOfPatient(
    appointmentDetails.patient
  )} with ${getNameFromHumanName(
    appointmentDetails.practitionerDetail.practitioner.name ?? []
  )} scheduled on ${dateToFromNowDaily(
    appointmentDetails.appointment.start?.toString() ?? ''
  )} at ${moment(appointmentDetails.appointment.start ?? '').format('HH:mm a')}`

  return message
}
function dateToFromNowDaily(myDate: string) {
  // get from-now for this date
  const fromNow = moment(myDate).fromNow()

  // ensure the date is displayed with today and yesterday
  return moment(myDate).calendar(null, {
    // when the date is closer, specify custom values
    lastWeek: '[Last] dddd',
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    // when the date is further away, use from-now functionality
    sameElse() {
      return `[${fromNow}]`
    },
  })
}

export function filterAppointmentsAsPerDoctorSelection(
  selectedDoctors: DoctorBase[],
  appointments: doctorAppointmentView[]
): doctorAppointmentView[] {
  if (selectedDoctors) {
    if (selectedDoctors.length > 0) {
      const doctorIds: string[] = selectedDoctors.map((doc) => doc?.id ?? '')

      const filterOutAppointments: doctorAppointmentView[] =
        appointments.filter((item, index, arr) =>
          doctorIds.includes(item.doctorId ?? 'null')
        )

      return filterOutAppointments
    }
  }
  return appointments
}

export function getExpandedServiceRequestFromBundle(
  responseSlots: R4.IBundle
): FhirLabOrderDetail[] {
  const convertedAppointments: FhirLabOrderDetail[] = []
  const serviceRequests: any = {}
  const slots: any = {}
  const tasks: any = {}
  const homeCollectionTask: any = {}
  const partnerLabTask: any = {}
  const practitioners: any = {}
  const patients: any = {}
  const practitionerRoles: any = {}
  const planDefinitions: any = {}
  const provenances: R4.IProvenance[] = []
  const paymentReconilation: any = {}

  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ServiceRequest':
                  serviceRequests[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break
                case 'Slot':
                  slots[value.resource.id] = value.resource as R4.ISlot
                  break
                case 'Practitioner':
                  practitioners[value.resource.id] =
                    value.resource as R4.IPractitioner
                  break
                case 'PractitionerRole':
                  practitionerRoles[value.resource.id] =
                    value.resource as R4.IPractitionerRole
                  break
                case 'Task':
                  if (value.resource.status !== R4.TaskStatusKind._cancelled) {
                    if (value.resource?.partOf === undefined) {
                      tasks[
                        value.resource.focus?.reference?.split('/')[1] ?? ''
                      ] = value.resource as R4.ITask
                    } else if (value.resource?.code?.coding) {
                      if (
                        value.resource?.code?.coding[0].code?.includes(
                          'parnerLab-test-order'
                        )
                      ) {
                        partnerLabTask[
                          value.resource.focus?.reference?.split('/')[1] ?? ''
                        ] = value.resource as R4.ITask
                      } else {
                        homeCollectionTask[
                          value.resource.focus?.reference?.split('/')[1] ?? ''
                        ] = value.resource as R4.ITask
                      }
                    }
                  }

                  if (value.resource.status === R4.TaskStatusKind._cancelled) {
                    if (value.resource?.partOf === undefined) {
                      tasks[
                        value.resource.focus?.reference?.split('/')[1] ?? ''
                      ] = value.resource as R4.ITask
                    } else {
                      homeCollectionTask[
                        value.resource.focus?.reference?.split('/')[1] ?? ''
                      ] = value.resource as R4.ITask
                    }
                  }

                  break
                case 'Patient':
                  patients[value.resource.id] = value.resource as R4.IPatient
                  break

                case 'Provenance':
                  provenances.push(value.resource as R4.IProvenance)
                  break
                case 'PlanDefinition':
                  planDefinitions[value.resource.id] =
                    value.resource as R4.IPlanDefinition
                  break
                case 'PaymentReconciliation':
                  paymentReconilation[value.resource.id] =
                    value.resource as R4.IPaymentReconciliation
                  break
                default:
                  break
              }
            }
          }
        })

        const labOfferings: LabOfferingDetail[] =
          getLabOfferingDetailsFromBundle(responseSlots, []) ?? []
        labOfferings.forEach((e) => {
          planDefinitions[e.id] = e
        })

        for (const key in serviceRequests) {
          if (key) {
            const currentAppointment: R4.IServiceRequest = serviceRequests[
              key
            ] as R4.IServiceRequest
            let slotId: string = ''

            let practitionerId: string | undefined

            let practitionerRoleId: string | undefined
            let paymentId: string | undefined

            let practitionerRoleIdAdmin: string | undefined

            const patientId: string | undefined =
              currentAppointment?.subject.reference?.split('/')[1]
            const currentPlanDefinitions: LabOfferingDetail[] | undefined = (
              serviceRequests[key] as R4.IServiceRequest
            ).instantiatesCanonical?.map((e) => {
              const id: string = e.split('/')[1]

              return planDefinitions[id] as LabOfferingDetail
            })

            let startTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
              ''
            let endTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
              ''
            const serviceType: R4.ICoding | undefined =
              getCodeOfSystemFromCodableConcept(
                (serviceRequests[key] as R4.IServiceRequest).code ?? {},
                'http://wellopathy.com/fhir/india/core/CodeSystem/lab-service-type'
              )

            if (serviceType?.code === 'home-sample-collection') {
              startTime =
                (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                  ?.start ?? ''
              endTime =
                (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                  ?.end ?? ''

              const owner1 = homeCollectionTask[key] as R4.ITask

              if (owner1) {
                const slotRef: R4.IReference | undefined =
                  getInputIdentifierValueByCodemOfInvitation(
                    owner1,
                    'slot-reference'
                  )?.valueReference
                if (slotRef) {
                  slotId = slotRef.reference?.split('/')[1] ?? ''
                }

                practitionerRoleId = (
                  homeCollectionTask[key] as R4.ITask
                ).owner?.reference?.split('/')[1]
              }
              const owner2 = tasks[key] as R4.ITask

              if (owner2) {
                practitionerRoleIdAdmin = (
                  tasks[key] as R4.ITask
                ).owner?.reference?.split('/')[1]
                for (const key1 in paymentReconilation) {
                  if (key1) {
                    const paymentInfo: R4.IPaymentReconciliation =
                      paymentReconilation[key1] as R4.IPaymentReconciliation
                    if (paymentInfo.request) {
                      const chargeID: string | undefined =
                        paymentInfo.request.reference?.split('/')[1] ?? ''
                      if (chargeID === owner2.id) {
                        paymentId = paymentInfo.id
                      }
                    }
                  }
                }
              }

              if (practitionerRoleId) {
                const practRole: R4.IPractitionerRole = practitionerRoles[
                  practitionerRoleId
                ] as R4.IPractitionerRole

                if (practRole) {
                  practitionerId = (
                    practitionerRoles[
                      practitionerRoleId
                    ] as R4.IPractitionerRole
                  ).practitioner?.reference?.split('/')[1]
                }
              }
            } else {
              const owner2 = tasks[key] as R4.ITask

              if (owner2) {
                for (const key1 in paymentReconilation) {
                  if (key1) {
                    const paymentInfo: R4.IPaymentReconciliation =
                      paymentReconilation[key1] as R4.IPaymentReconciliation
                    if (paymentInfo.request) {
                      const chargeID: string | undefined =
                        paymentInfo.request.reference?.split('/')[1] ?? ''
                      if (chargeID === owner2.id) {
                        paymentId = paymentInfo.id
                      }
                    }
                  }
                }
              }
            }

            const status = provenances.filter((e) => {
              const res = e.target.findIndex((ref) => {
                if (ref.reference)
                  return ref.reference.split('/')[1] === tasks[key].id

                return false
              })
              return res > -1
            })
            status.sort((a, b) =>
              moment(a.occurredDateTime).diff(b.occurredDateTime)
            )

            convertedAppointments.push({
              start: startTime,
              end: endTime,
              performerDetail: {
                practitioner: practitioners[practitionerId ?? ''],
                practitionerRole: practitionerRoles[practitionerRoleId ?? ''],
              },
              homeServiceTask: homeCollectionTask[key],
              task: tasks[key],
              serviceRequest: serviceRequests[key],
              patient: patients[patientId ?? ''],
              tests: currentPlanDefinitions,
              statuses: status,
              isSelected: false,
              oldSlotRef: slotId,
              paymentReconilation: paymentReconilation[paymentId ?? ''],
            })
          }
        }
      }
    }
  }

  return convertedAppointments
}

export function getUserDetailAndAppointmentDetail(
  responseSlots: FhirAppointmentDetail
): string {
  let text: string = ''
  if (responseSlots.patient.maritalStatus) {
    text = isMarried(responseSlots.patient.maritalStatus)
      ? 'Married, '
      : 'Single, '
  }
  text = `${text}Appointment ,${moment(responseSlots.start).format(
    'h:mm a'
  )} - ${moment(responseSlots.end).format('h:mm a')}`
  return text
}

export function getOrderTypeCode(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: R4.ICoding | undefined = getCodeOfSystemFromCodableConcept(
    labOrderDetails.serviceRequest.code ?? {},
    'http://wellopathy.com/fhir/india/core/CodeSystem/lab-service-type'
  )
  return code?.code
}

export function getOrderTypeDisplayCode(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: R4.ICoding | undefined = getCodeOfSystemFromCodableConcept(
    labOrderDetails.serviceRequest.code ?? {},
    'http://wellopathy.com/fhir/india/core/CodeSystem/lab-service-type'
  )
  return code?.display
}

export function getOrderTime(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: string | undefined = getOrderTypeCode(labOrderDetails)
  if (code === 'onsite-sample-collection') {
    return geTimeInStringWithDate(
      labOrderDetails.serviceRequest.occurrenceDateTime ?? ''
    )
  }
  if (code === 'home-sample-collection') {
    return getStartAndEndTimeInStringWithDate(
      labOrderDetails.serviceRequest.occurrencePeriod?.start ?? '',
      labOrderDetails.serviceRequest.occurrencePeriod?.end ?? ''
    )
  }
  return 'onsite-sample-collection'
}

export function getOrderStartTime(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: string | undefined = getOrderTypeCode(labOrderDetails)
  if (code === 'onsite-sample-collection') {
    return geTimeInString(
      labOrderDetails.serviceRequest.occurrenceDateTime ?? ''
    )
  }
  if (code === 'home-sample-collection') {
    return geTimeInString(
      labOrderDetails.serviceRequest.occurrencePeriod?.start ?? ''
    )
  }
  return 'onsite-sample-collection'
}

export function getOrderStartDate(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: string | undefined = getOrderTypeCode(labOrderDetails)
  if (code === 'onsite-sample-collection') {
    return getCompleteDateStringWithOutDay(
      labOrderDetails.serviceRequest.occurrenceDateTime ?? ''
    )
  }
  if (code === 'home-sample-collection') {
    return getCompleteDateStringWithOutDay(
      labOrderDetails.serviceRequest.occurrencePeriod?.start ?? ''
    )
  }
  return 'onsite-sample-collection'
}

export function getOrderDate(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: string | undefined = getOrderTypeCode(labOrderDetails)
  if (code === 'onsite-sample-collection') {
    return getCompleteDateStringWithOutDay(
      labOrderDetails.serviceRequest.occurrenceDateTime ?? ''
    )
  }
  if (code === 'home-sample-collection') {
    return getStartAndEndTimeInString(
      labOrderDetails.serviceRequest.occurrencePeriod?.start ?? '',
      labOrderDetails.serviceRequest.occurrencePeriod?.end ?? ''
    )
  }
  return 'onsite-sample-collection'
}

export function getOrderTimeWithDate(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: string | undefined = getOrderTypeCode(labOrderDetails)
  if (code === 'onsite-sample-collection') {
    return getShortTimeWithDate(
      labOrderDetails.serviceRequest.occurrenceDateTime ?? ''
    )
  }
  if (code === 'home-sample-collection') {
    return getStartAndEndTimeWithDateInString(
      labOrderDetails.serviceRequest.occurrencePeriod?.start ?? '',
      labOrderDetails.serviceRequest.occurrencePeriod?.end ?? ''
    )
  }
  return 'onsite-sample-collection'
}

export function getTestsOfOrder(
  labOrderDetail: FhirLabOrderDetail
): string | undefined {
  return labOrderDetail.tests
    ?.map((e) => e?.planDefinition.title ?? e?.planDefinition.name)
    .join(', ')
}

export function isLabOrderEditable(
  labOrderDetail: FhirLabOrderDetail
): boolean {
  if (labOrderDetail.serviceRequest.status === 'active') {
    const code: string | undefined = getOrderTypeCode(labOrderDetail)

    if (code === 'onsite-sample-collection') {
      return true
    }

    if (
      moment(labOrderDetail.serviceRequest.occurrencePeriod?.start).isAfter(
        new Date()
      )
    ) {
      return true
    }
  }

  return false
}

export function isLabOrderStatusChangeable(
  labOrderDetail: FhirLabOrderDetail
): boolean {
  if (labOrderDetail.serviceRequest.status === 'active') {
    const code: string | undefined = getOrderTypeCode(labOrderDetail)

    // if (code === 'onsite-sample-collection') {
    //     return true
    // }

    if (
      moment(labOrderDetail.serviceRequest.occurrencePeriod?.start).isSame(
        new Date()
      )
    ) {
      return true
    }
  }

  return false
}

export function isPartnerLabStatusChangeable(
  labOrderDetail: FhirLabOrderDetail
): boolean {
  if (labOrderDetail.task) {
    if (labOrderDetail.task.status === 'received') {
      return true
    }

    if (
      moment(
        labOrderDetail.serviceRequest.occurrencePeriod?.start
      ).isSameOrBefore(new Date())
    ) {
      return true
    }
  }

  return false
}

export function isPartnerOrderEditable(
  labOrderDetail: FhirLabOrderDetail
): boolean {
  if (labOrderDetail.task) {
    if (labOrderDetail.task.status === 'received') {
      return true
    }

    //  if (
    //    moment(
    //      labOrderDetail.serviceRequest.occurrencePeriod?.start
    //    ).isSameOrAfter(new Date())
    //  ) {
    //    return true
    //  }
  }

  return false
}

export function getTotalSpeiciment(
  labOrderDetails: FhirLabOrderDetail[]
): number {
  let count: number = 0
  for (let i = 0; i < labOrderDetails.length; i++) {
    if (labOrderDetails[i].tests) count += labOrderDetails[i].tests?.length ?? 0
  }
  return count
}

export function getTotalAmountOfLabOfferings(
  selectedLabOfferings: LabOfferingDetail[]
): number {
  let total: number = 0
  selectedLabOfferings.forEach((e) => {
    if (e.chargeItem) {
      const priceComponent:
        | R4.IChargeItemDefinition_PriceComponent
        | undefined = getPriceComponentFromChangeItem(e.chargeItem)
      if (
        priceComponent &&
        priceComponent.amount &&
        priceComponent.amount.value
      ) {
        total += priceComponent.amount.value
      }
    }
  })
  return total
}

export function getTotalAmount(labOrderDetails: FhirLabOrderDetail[]): number {
  let count: number = 0
  for (let i = 0; i < labOrderDetails.length; i++) {
    count += getTotalAmountOfLabOfferings(labOrderDetails[i].tests ?? [])
  }
  return count
}

export function isHomeOrder(labOrderDetail: FhirLabOrderDetail): boolean {
  if (labOrderDetail.serviceRequest.status === 'active') {
    const code: string | undefined = getOrderTypeCode(labOrderDetail)

    if (code === 'home-sample-collection') {
      return true
    }
  }

  return false
}

export function isOnsiteOrder(labOrderDetail: FhirLabOrderDetail): boolean {
  if (labOrderDetail.serviceRequest.status === 'active') {
    const code: string | undefined = getOrderTypeCode(labOrderDetail)

    if (code === 'onsite-sample-collection') {
      return true
    }
  }

  return false
}

export function getInputIdentifierValueBySystemOfInvitation(
  task: R4.ITask,
  system: string
): string | undefined {
  if (task && task.input && task.input.length > 0) {
    const emailInput = task.input.find(
      (e) => e.valueIdentifier?.system === system
    )
    if (emailInput) {
      return emailInput.valueIdentifier?.value
    }
  }

  return undefined
}

export function getInputIdentifierValueByCodemOfInvitation(
  task: R4.ITask,
  code: string
): R4.ITask_Input | undefined {
  if (task && task.input && task.input.length > 0) {
    const emailInput = task.input.find((e) => e.type?.coding?.[0].code === code)
    if (emailInput) {
      return emailInput
    }
  }

  return undefined
}

export function getProvenanceObjectForOrder(
  orderDetail: FhirLabOrderDetail,
  status: R4.ICoding
) {
  const currentUserDetails: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const mainProvenanceId: string = getUniqueTempId()
  const statusProvenance: R4.IProvenance = {
    id: mainProvenanceId,
    resourceType: 'Provenance',
    occurredDateTime: new Date().toISOString(),

    activity: {
      text: status.display ?? '',
      coding: [status],
    },
    agent: [
      {
        type: {
          coding: [
            {
              code: 'enterer',
              display: 'Enterer',
              system:
                'http://terminology.hl7.org/CodeSystem/provenance-participant-type',
            },
          ],
        },
        who: {
          id: currentUserDetails.id,
          reference:
            `${currentUserDetails.resourceType}/${currentUserDetails.id}` ?? '',
        },
      },
    ],
    target: [
      {
        id: orderDetail.serviceRequest.id,
        reference:
          `${orderDetail.serviceRequest.resourceType}/${orderDetail.serviceRequest.id}` ??
          '',
      },
      {
        id: orderDetail.task?.id,
        reference:
          `${orderDetail.task?.resourceType}/${orderDetail.task?.id}` ?? '',
      },
    ],
  }

  return statusProvenance
}

export function getProvenanceObjectForTask(task: R4.ITask, status: R4.ICoding) {
  const currentUserDetails: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const mainProvenanceId: string = getUniqueTempId()
  const statusProvenance: R4.IProvenance = {
    id: mainProvenanceId,
    resourceType: 'Provenance',
    occurredDateTime: new Date().toISOString(),

    activity: {
      text: status.display ?? '',
      coding: [status],
    },
    agent: [
      {
        type: {
          coding: [
            {
              code: 'enterer',
              display: 'Enterer',
              system:
                'http://terminology.hl7.org/CodeSystem/provenance-participant-type',
            },
          ],
        },
        who: {
          id: currentUserDetails.id,
          reference:
            `${currentUserDetails.resourceType}/${currentUserDetails.id}` ?? '',
        },
      },
    ],
    target: [
      {
        id: task.id,
        reference: `${task.resourceType}/${task.id}` ?? '',
      },
    ],
  }

  return statusProvenance
}

export function getProvenanceObjectForPartnerTask(
  task: R4.ITask,
  status: R4.ICoding
) {
  const currentUserDetails: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const mainProvenanceId: string = getUniqueTempId()
  const statusProvenance: R4.IProvenance = {
    id: mainProvenanceId,
    resourceType: 'Provenance',
    occurredDateTime: new Date().toISOString(),

    activity: {
      text: status.display ?? '',
      coding: [status],
    },
    agent: [
      {
        type: {
          coding: [
            {
              code: 'enterer',
              display: 'Enterer',
              system:
                'http://terminology.hl7.org/CodeSystem/provenance-participant-type',
            },
          ],
        },
        who: {
          id: currentUserDetails.id,
          reference:
            `${currentUserDetails.resourceType}/${currentUserDetails.id}` ?? '',
        },
      },
    ],
    target: [
      {
        id: task.id,
        reference: `${task.resourceType}/${task.id}` ?? '',
      },
    ],
  }

  return statusProvenance
}

export function getOrderStatusText(labOrder: FhirLabOrderDetail): string {
  const statusName: string = ''
  switch (labOrder.serviceRequest.status) {
    case 'active':
      return 'Scheduled'
    case 'revoked':
      return 'Cancelled'

    default:
      break
  }
  return statusName
}

export function getOrderFinalStatusText(labOrder: FhirLabOrderDetail): string {
  let statusName: string = ''
  if (labOrder.task) {
    switch (labOrder.task.status) {
      case 'accepted':
        return 'Scheduled'
      case 'cancelled':
        return 'Cancelled'
      case 'rejected':
        if (labOrder.task.businessStatus) {
          if (labOrder.task.businessStatus.coding) {
            if (labOrder.task.businessStatus.coding[0].code === 'rejected')
              statusName = 'Sample(s) Rejected'
            else statusName = 'Pickup Aborted by Agent'
          }
        }
        return statusName

      case 'received':
        return 'Sample Received'
      case 'draft':
        return 'Processing Sample (s)'
      case 'in-progress':
        return 'Pickup in Progress'
      case 'completed':
        return 'Report Delivered'

      default:
        break
    }
  }
  return statusName
}

export function getOrderAgentStatusText(labOrder: FhirLabOrderDetail): string {
  const statusName: string = ''
  if (labOrder.homeServiceTask) {
    switch (labOrder.homeServiceTask.status) {
      case 'accepted':
        return 'Scheduled'
      case 'completed':
        return 'Completed'
      case 'rejected':
        return 'Aborted'
      case 'in-progress':
        return 'Pickup In Progress'

      default:
        break
    }
  }
  return statusName
}

export function getPartnerOrderStatusText(
  labOrder: FhirLabOrderDetail
): string {
  const statusName: string = ''
  if (labOrder.partnerLabTask) {
    switch (labOrder.partnerLabTask.status) {
      case 'accepted':
        return 'Scheduled'
      case 'received':
        return 'Sample Received'
      case 'in-progress':
        return 'Processing Sample'
      case 'ready':
        return 'Sample Processed'
      case 'completed':
        return 'Report Uploaded'
      default:
        break
    }
  }
  return statusName
}

export function getChargeItemForSelectedPlans(
  selectedPlans: R4.IChargeItemDefinition[],
  subject: R4.IPatient,
  serviceRequestRef: R4.IReference,
  deductibles?: R4.IChargeItemDefinition[],
  additions?: R4.IChargeItemDefinition[]
): R4.IChargeItem {
  let chargeDefinitionReferences: string[] = []

  if (selectedPlans.length > 0) {
    const planRefs: string[] = selectedPlans.map(
      (e) => `${e.resourceType}/${e.id}`
    )

    chargeDefinitionReferences = [...chargeDefinitionReferences, ...planRefs]
  }

  logger.info(chargeDefinitionReferences)
  if (deductibles && deductibles.length > 0) {
    chargeDefinitionReferences = [
      ...chargeDefinitionReferences,
      ...deductibles.map((e) => `${e.resourceType}/${e.id}`),
    ]
  }

  logger.info(chargeDefinitionReferences)

  if (additions && additions.length > 0) {
    chargeDefinitionReferences = [
      ...chargeDefinitionReferences,
      ...additions.map((e) => `${e.resourceType}/${e.id}`),
    ]
  }

  const chargeItem: R4.IChargeItem = {
    resourceType: 'ChargeItem',
    status: R4.ChargeItemStatusKind._billed,
    enterer: getCurrentUserPractitionerRoleRef(),
    definitionCanonical: chargeDefinitionReferences,
    enteredDate: new Date().toISOString(),
    supportingInformation: [serviceRequestRef],
    requestingOrganization: getCurrentUserUnitReference(),
    code: {},
    subject: {
      reference: `${subject?.resourceType}/${subject.id}`,
    },
  }

  return chargeItem
}

export function getPaymentReconciliationResource(
  taskRef: R4.IReference,
  amount: R4.IMoney,
  selectedPaymentOption: string,
  selectedServiceType: string
): R4.IPaymentReconciliation {
  const onlinePaymentExtension = {
    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentType',
    valueCoding: {
      code: 'prepaid',
      display: 'prepaid',
      system: 'http://wellopathy.com/fhir/india/core/CodeSystem/payment_type',
    },
  }
  const cashPaymentExtension = {
    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentType',
    valueCoding: {
      code: 'postPaid',
      display: 'postpaid',
      system: 'http://wellopathy.com/fhir/india/core/CodeSystem/payment_type',
    },
  }
  const paymentReconciliation: R4.IPaymentReconciliation = {
    resourceType: 'PaymentReconciliation',
    status: 'active',
    paymentAmount: amount,
    request: taskRef,
    requestor: getCurrentUserPractitionerRoleRef(),
    paymentIssuer: getCurrentUserUnitReference(),
    outcome: R4.PaymentReconciliationOutcomeKind._queued,
  }

  if (selectedPaymentOption === 'online') {
    paymentReconciliation.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentMode',
        valueCoding: {
          code: 'online',
          display: 'online',
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/payment_mode',
        },
      },
      onlinePaymentExtension,
    ]
  }

  if (
    selectedPaymentOption === 'cash' &&
    selectedServiceType === 'home_collection'
  ) {
    paymentReconciliation.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentMode',
        valueCoding: {
          code: 'cash',
          display: 'cash',
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/payment_mode',
        },
      },
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/payment-status',
        valueCoding: {
          code: 'pending',
          display: 'pending',
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/payment-status',
        },
      },
      cashPaymentExtension,
    ]
  }

  if (
    selectedPaymentOption === 'cash' &&
    selectedServiceType === 'onsite-collection'
  ) {
    paymentReconciliation.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentMode',
        valueCoding: {
          code: 'cash',
          display: 'cash',
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/payment_mode',
        },
      },
      onlinePaymentExtension,
    ]
  }

  return paymentReconciliation
}

export function getOrdersForAgent(
  responseSlots: R4.IBundle
): FhirLabOrderDetail[] {
  const convertedAppointments: FhirLabOrderDetail[] = []
  const serviceRequests: any = {}
  const slots: any = {}
  const tasks: any = {}
  const homeCollectionTask: any = {}
  const practitioners: any = {}
  const patients: any = {}
  const practitionerRoles: any = {}
  const planDefinitions: any = {}
  const provenances: R4.IProvenance[] = []
  const specimen: R4.ISpecimen[] = []
  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ServiceRequest':
                  serviceRequests[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break
                case 'Slot':
                  slots[value.resource.id] = value.resource as R4.ISlot
                  break
                case 'Practitioner':
                  practitioners[value.resource.id] =
                    value.resource as R4.IPractitioner
                  break
                case 'PractitionerRole':
                  practitionerRoles[value.resource.id] =
                    value.resource as R4.IPractitionerRole
                  break
                case 'Task':
                  if (value.resource.status !== R4.TaskStatusKind._cancelled) {
                    if (value.resource?.partOf === undefined) {
                      tasks[
                        value.resource.focus?.reference?.split('/')[1] ?? ''
                      ] = value.resource as R4.ITask
                    } else {
                      homeCollectionTask[
                        value.resource.focus?.reference?.split('/')[1] ?? ''
                      ] = value.resource as R4.ITask
                    }
                  }

                  break
                case 'Patient':
                  patients[value.resource.id] = value.resource as R4.IPatient
                  break

                case 'Provenance':
                  provenances.push(value.resource as R4.IProvenance)
                  break

                case 'Specimen':
                  specimen.push(value.resource as R4.ISpecimen)
                  break
                case 'PlanDefinition':
                  planDefinitions[value.resource.id] =
                    value.resource as R4.IPlanDefinition
                  break
                default:
                  break
              }
            }
          }
        })

        const labOfferings: LabOfferingDetail[] =
          getLabOfferingDetailsFromBundle(responseSlots, []) ?? []
        labOfferings.forEach((e) => {
          planDefinitions[e.id] = e
        })

        for (const key in serviceRequests) {
          if (key) {
            const currentAppointment: R4.IServiceRequest = serviceRequests[
              key
            ] as R4.IServiceRequest
            let practitionerId: string | undefined

            let practitionerRoleId: string | undefined

            let practitionerRoleIdAdmin: string | undefined

            const patientId: string | undefined =
              currentAppointment?.subject.reference?.split('/')[1]
            const currentPlanDefinitions: LabOfferingDetail[] | undefined = (
              serviceRequests[key] as R4.IServiceRequest
            ).instantiatesCanonical?.map((e) => {
              const id: string = e.split('/')[1]

              return planDefinitions[id] as LabOfferingDetail
            })

            let startTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
              ''
            let endTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
              ''
            const serviceType: R4.ICoding | undefined =
              getCodeOfSystemFromCodableConcept(
                (serviceRequests[key] as R4.IServiceRequest).code ?? {},
                'http://wellopathy.com/fhir/india/core/CodeSystem/lab-service-type'
              )

            if (serviceType?.code === 'home-sample-collection') {
              startTime =
                (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                  ?.start ?? ''
              endTime =
                (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                  ?.end ?? ''

              const owner1 = homeCollectionTask[key] as R4.ITask
              if (owner1) {
                practitionerRoleId = (
                  homeCollectionTask[key] as R4.ITask
                ).owner?.reference?.split('/')[1]
              }
              const owner2 = tasks[key] as R4.ITask
              if (owner2) {
                practitionerRoleIdAdmin = (
                  tasks[key] as R4.ITask
                ).owner?.reference?.split('/')[1]
              }

              if (practitionerRoleId) {
                const practRole: R4.IPractitionerRole = practitionerRoles[
                  practitionerRoleId
                ] as R4.IPractitionerRole
                if (practRole) {
                  practitionerId = (
                    practitionerRoles[
                      practitionerRoleId
                    ] as R4.IPractitionerRole
                  ).practitioner?.reference?.split('/')[1]
                }
              }
            }

            const status = provenances.filter((e) => {
              const res = e.target.findIndex((ref) => {
                if (ref.reference)
                  return ref.reference.split('/')[1] === tasks[key].id

                return false
              })
              return res > -1
            })
            status.sort((a, b) =>
              moment(a.occurredDateTime).diff(b.occurredDateTime)
            )

            const taskData: R4.ITask = tasks[key]
            const home = homeCollectionTask[key]
            if (taskData.status === 'in-progress') {
              convertedAppointments.push({
                start: startTime,
                end: endTime,
                performerDetail: {
                  practitioner: practitioners[practitionerId ?? ''],
                  practitionerRole: practitionerRoles[practitionerRoleId ?? ''],
                },
                homeServiceTask: homeCollectionTask[key],
                task: tasks[key],
                serviceRequest: serviceRequests[key],

                patient: patients[patientId ?? ''],
                tests: currentPlanDefinitions,
                statuses: status,
                isSelected: false,
                oldSlotRef: '',
              })
            }
          }
        }
      }
    }
  }

  return convertedAppointments
}

export function getPaymentType(
  responseSlots: R4.IBundle,
  orderDetil: FhirLabOrderDetail
): FhirLabOrderDetail {
  const convertedOrder: FhirLabOrderDetail = orderDetil
  const paymentReconciliation: any = {}

  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'PaymentReconciliation':
                  paymentReconciliation[value.resource.id] =
                    value.resource as R4.IPaymentReconciliation
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in paymentReconciliation) {
          if (key) {
            const currentAppointment: R4.IPaymentReconciliation =
              paymentReconciliation[key] as R4.IPaymentReconciliation
            const extension = getExtensionValueOfUrl(
              currentAppointment.extension,
              'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentType'
            )

            if (extension?.valueCoding?.display) {
              if (extension?.valueCoding?.display === 'Postpaid') {
                convertedOrder.paymentReconilation = currentAppointment
                convertedOrder.isPrepaid = false
              } else {
                convertedOrder.paymentReconilation = currentAppointment
                convertedOrder.isPrepaid = true
              }
            } else {
              convertedOrder.paymentReconilation = currentAppointment
              convertedOrder.isPrepaid = false
            }
          }
        }
      }
    }
  }

  return convertedOrder
}

export function getLabDiagnosticRequestFromBundle(
  responseSlots: R4.IBundle
): FhirLabDiagnosticRequest[] {
  const convertedAppointments: FhirLabDiagnosticRequest[] = []
  const serviceRequests: any = {}
  const patients: any = {}
  const planDefinitions: any = {}
  const provenances: R4.IProvenance[] = []
  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ServiceRequest':
                  serviceRequests[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break
                case 'Patient':
                  patients[value.resource.id] = value.resource as R4.IPatient
                  break
                case 'Provenance':
                  provenances.push(value.resource as R4.IProvenance)
                  break
                case 'PlanDefinition':
                  planDefinitions[value.resource.id] =
                    value.resource as R4.IPlanDefinition
                  break
                default:
                  break
              }
            }
          }
        })

        const labOfferings: LabOfferingDetail[] =
          getLabOfferingDetailsFromBundle(responseSlots, []) ?? []
        labOfferings.forEach((e) => {
          planDefinitions[e.id] = e
        })

        for (const key in serviceRequests) {
          if (key) {
            const currentAppointment: R4.IServiceRequest = serviceRequests[
              key
            ] as R4.IServiceRequest

            const patientId: string | undefined =
              currentAppointment?.subject.reference?.split('/')[1]
            const currentPlanDefinitions: LabOfferingDetail[] | undefined = (
              serviceRequests[key] as R4.IServiceRequest
            ).instantiatesCanonical?.map((e) => {
              const id: string = e.split('/')[1]

              return planDefinitions[id] as LabOfferingDetail
            })

            convertedAppointments.push({
              serviceRequest: serviceRequests[key],
              patient: patients[patientId ?? ''],
              tests: currentPlanDefinitions,
            })
          }
        }
      }
    }
  }

  return convertedAppointments
}

export function getLabOrderFullDetail(
  responseSlots: R4.IBundle
): FhirLabOrderFullDetail[] {
  const convertedLabOrders: FhirLabOrderFullDetail[] = []
  const serviceRequests: any = {}

  const observations: any = {}
  const practitioners: any = {}
  const patients: any = {}
  const practitionerRoles: any = {}
  const planDefinitions: any = {}
  const diagnosticReports: Map<string, R4.IDiagnosticReport[]> = new Map<
    string,
    R4.IDiagnosticReport[]
  >()

  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ServiceRequest':
                  serviceRequests[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break

                case 'Practitioner':
                  practitioners[value.resource.id] =
                    value.resource as R4.IPractitioner
                  break
                case 'PractitionerRole':
                  practitionerRoles[value.resource.id] =
                    value.resource as R4.IPractitionerRole
                  break

                case 'Observation':
                  observations[value.resource.id] =
                    value.resource as R4.IObservation
                  break

                case 'DiagnosticReport':
                  {
                    const index: number | undefined = (
                      value.resource as R4.IDiagnosticReport
                    ).basedOn?.findIndex((e) =>
                      e.reference?.includes('ServiceRequest')
                    )

                    if (index !== undefined && index > -1) {
                      const ref: R4.IReference = (
                        value.resource as R4.IDiagnosticReport
                      ).basedOn?.[index]!

                      const key: string = ref.reference?.split('/')[1] ?? ''

                      if (diagnosticReports.has(key)) {
                        diagnosticReports
                          .get(key)
                          ?.push(value.resource as R4.IDiagnosticReport)
                      } else {
                        diagnosticReports.set(
                          ref.reference?.split('/')[1] ?? '',
                          [value.resource as R4.IDiagnosticReport]
                        )
                      }
                    }
                    value.resource as R4.IDiagnosticReport
                  }

                  break

                case 'Patient':
                  patients[value.resource.id] = value.resource as R4.IPatient
                  break

                case 'PlanDefinition':
                  planDefinitions[value.resource.id] =
                    value.resource as R4.IPlanDefinition
                  break
                default:
                  break
              }
            }
          }
        })

        const labOfferings: LabOfferingDetail[] =
          getLabOfferingDetailsFromBundle(responseSlots, []) ?? []
        labOfferings.forEach((e) => {
          planDefinitions[e.id] = e
        })

        for (const key in serviceRequests) {
          if (key) {
            const currentRequest: R4.IServiceRequest = serviceRequests[
              key
            ] as R4.IServiceRequest
            let practitionerRoleId: string | undefined
            const patientId: string | undefined =
              currentRequest?.subject.reference?.split('/')[1]
            const currentPlanDefinitions: LabOfferingDetail[] | undefined = (
              serviceRequests[key] as R4.IServiceRequest
            ).instantiatesCanonical?.map((e) => {
              const id: string = e.split('/')[1]

              return planDefinitions[id] as LabOfferingDetail
            })

            let startTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
              ''
            let endTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
              ''
            const serviceType: R4.ICoding | undefined =
              getCodeOfSystemFromCodableConcept(
                (serviceRequests[key] as R4.IServiceRequest).code ?? {},
                'http://wellopathy.com/fhir/india/core/CodeSystem/lab-service-type'
              )

            if (serviceType?.code === 'home-sample-collection') {
              startTime =
                (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                  ?.start ?? ''
              endTime =
                (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                  ?.end ?? ''

              if (practitionerRoleId) {
                const practRole: R4.IPractitionerRole = practitionerRoles[
                  practitionerRoleId
                ] as R4.IPractitionerRole
              }
            }
            const testsAndResults: TestAndResult[] = []

            if (diagnosticReports.has(currentRequest.id!)) {
              if (diagnosticReports.get(currentRequest.id!)!.length > 0) {
                diagnosticReports.get(currentRequest.id ?? '')?.forEach((e) => {
                  const obs: R4.IObservation[] = []
                  if (e.result && e.result.length > 0) {
                    e.result!.forEach((res) => {
                      const obsId: string = res.reference?.split('/')[1]!
                      obs.push(observations[obsId])
                    })
                  }

                  testsAndResults.push({
                    diagnosticReport: e,
                    results: obs,
                  })
                })
              }
            }

            convertedLabOrders.push({
              start: startTime,
              end: endTime,
              testAndResults: testsAndResults,

              serviceRequest: serviceRequests[key],
              patient: patients[patientId ?? ''],
              tests: currentPlanDefinitions,
            })
          }
        }
      }
    }
  }

  return convertedLabOrders
}

export function getTransactionBodyResource(
  ServiceRequestId: string,
  PaymentReconciliationId: string,
  paymentType: string,
  tasks: string[],
  transactiontype: string
): R4.IParameters {
  const paramterResource: R4.IParameters = {
    resourceType: 'Parameters',
    parameter: [
      {
        name: 'order-type',
        valueString: transactiontype,
      },
      {
        name: 'order-id',
        valueString: ServiceRequestId,
      },
      {
        name: 'payment-type',
        valueString: paymentType === 'cash' ? 'offline' : 'online',
      },
      {
        name: 'razorpay-order-id',
        valueString: '',
      },
      {
        name: 'razorpay-payment-id',
        valueString: '',
      },
      {
        name: 'razorpay-sign',
        valueString: '',
      },
      {
        name: 'payment-reconciliation-id',
        valueString: PaymentReconciliationId,
      },
      {
        name: 'task-order-id',
        valueString: tasks.length >= 1 ? tasks[0] : '',
      },
      {
        name: 'task-agent-id',
        valueString: tasks.length > 1 ? tasks[1] : tasks[0],
      },
    ],
  }

  return paramterResource
}

export function getTransactionBodyResourceAppointment(
  ServiceRequestId: string,
  PaymentReconciliationId: string,
  paymentType: string,
  transactiontype: string
): R4.IParameters {
  const paramterResource: R4.IParameters = {
    resourceType: 'Parameters',
    parameter: [
      {
        name: 'order-type',
        valueString: transactiontype,
      },
      {
        name: 'order-id',
        valueString: ServiceRequestId,
      },
      {
        name: 'payment-type',
        valueString: paymentType === 'cash' ? 'offline' : 'online',
      },
      {
        name: 'razorpay-order-id',
        valueString: '',
      },
      {
        name: 'razorpay-payment-id',
        valueString: '',
      },
      {
        name: 'razorpay-sign',
        valueString: '',
      },
      {
        name: 'payment-reconciliation-id',
        valueString: PaymentReconciliationId,
      },
      {
        name: 'task-order-id',
        valueString: '',
      },
      {
        name: 'task-agent-id',
        valueString: '',
      },
    ],
  }

  return paramterResource
}

export function getTransactionBodyResourceAppointmentOnline(
  ServiceRequestId: string,
  PaymentReconciliationId: string,
  paymentType: string,
  transactiontype: string
): R4.IParameters {
  const paramterResource: R4.IParameters = {
    resourceType: 'Parameters',
    parameter: [
      {
        name: 'order-type',
        valueString: transactiontype,
      },
      {
        name: 'order-id',
        valueString: ServiceRequestId,
      },
      {
        name: 'payment-type',
        valueString: paymentType === 'cash' ? 'offline' : 'online',
      },
      {
        name: 'payment-reconciliation-id',
        valueString: PaymentReconciliationId,
      },
    ],
  }

  return paramterResource
}

export function getInvoicePdf(
  type: string,
  serviceRequestId: string,
  paymentReconciliationId: string
): R4.IParameters {
  const paramterResource: R4.IParameters = {
    resourceType: 'Parameters',
    parameter: [
      {
        name: 'order-reference',
        valueString: `${type}/${serviceRequestId}`,
      },
      {
        name: 'payment-reconciliation-ref',
        valueString: `PaymentReconciliation/${paymentReconciliationId}`,
      },
    ],
  }

  return paramterResource
}

export function getDateDifferceinDays(
  startDateUI: string,
  endDateUI: string
): number {
  const startDate = moment(startDateUI, 'DD-MM-YYYY')
  const endDate = moment(endDateUI, 'DD-MM-YYYY')
  const dayDiff = endDate.diff(startDate, 'days')

  return dayDiff
}

export function getTotalAmountFoRIpd(
  amount: number,
  noOfDays: number,
  discount: number
): number {
  const finalDays: number = noOfDays === 0 ? 1 : noOfDays
  let discountPrice: number = 0
  const finalAmount: number = amount * finalDays

  if (discount > 0) {
    const percentAsDecimal = discount / 100
    discountPrice = finalAmount * percentAsDecimal
  }
  return finalAmount - discountPrice
}

export function getTotalAmountFoRIpdWithoutDiscount(
  amount: number,
  noOfDays: number,
  discount: number
): number {
  const finalDays: number = noOfDays === 0 ? 1 : noOfDays

  const finalAmount: number = amount * finalDays
  return finalAmount
}

export function getDiscountAmount(
  amount: number,
  noOfDays: number,
  discount: number
): number {
  const finalDays: number = noOfDays === 0 ? 1 : noOfDays
  let discountPrice: number = 0
  const finalAmount: number = amount * finalDays

  if (discount > 0) {
    const percentAsDecimal = discount / 100
    discountPrice = finalAmount * percentAsDecimal
  }
  return discountPrice
}

export function isGenderFemale(gender: string): boolean {
  return gender === 'female'
}

export function isValueSelected(data?: R4.ICoding): boolean {
  if (data) return true
  return false
}

export function isPastOrFutureDate(date: string): boolean {
  const dateArr: string[] = date.split('-')
  const finalDate = `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`
  const currentDate = moment().format('YYYY-MM-DD')
  if (finalDate >= currentDate) return true
  return false
}

export async function getReferralResource(
  referralId: String
): Promise<R4.IServiceRequest | undefined> {
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response = await fhirApi.doGetResource(`/ServiceRequest/${referralId}`)
  return response as R4.IServiceRequest
}

export async function updateServiceRequest(serviceRequest: R4.IServiceRequest) {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirClient.doUpdateFHIRResourceRequest(
    `/ServiceRequest/${serviceRequest.id}` ?? '',
    serviceRequest,
    serviceRequest.meta?.versionId ?? ''
  )
  return response
}

export async function getRelatedPerson(
  relatedPersonId: string
): Promise<R4.IRelatedPerson | undefined> {
  const fhirApi: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirApi.doGetResource(`/${relatedPersonId}`)
  logger.info('Related Person Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IRelatedPerson> =
    R4.RTTI_RelatedPerson.decode(response)
  if (relatedFhirDecodeRes._tag === 'Right') {
    const orgResponse: R4.IRelatedPerson = relatedFhirDecodeRes.right
    return orgResponse
  }
  return undefined
}

export function getExportedData(orderDetails: IpdAdmission[]): Print {
  const convertedData: PrintFormat[] = []
  const unitLogo: string[] = []
  for (let i = 0; i < orderDetails.length; i++) {
    console.log(orderDetails[i].patientPhoto)
    const patientIdTypeCoding: R4.ICoding | undefined =
      getIdentifierValueBySystemTypeCoding(
        orderDetails[i].patient.identifier ?? [],
        'address-proof'
      )

    const attendantDetailsData = orderDetails[i].attendantDetails
    const attendantCoding: R4.ICoding | undefined = attendantDetailsData
      ? getIdentifierValueBySystemTypeCoding(
          attendantDetailsData.identifier ?? [],
          'related-address-proof'
        )
      : undefined
    unitLogo.push(orderDetails[i].unitId)
    convertedData.push({
      patientName:
        getMiddleNameOfPatient(orderDetails[i].patient).length > 0
          ? `${getNameOfPatient(orderDetails[i].patient)} ${
              getMiddleNameOfPatient(orderDetails[i].patient) ?? ''
            } ${getLastNameOfPatient(orderDetails[i].patient)}`
          : `${getNameOfPatient(
              orderDetails[i].patient
            )} ${getLastNameOfPatient(orderDetails[i].patient)}`,
      gender: '',
      age: `${getAgeOfPatientData(orderDetails[i].patient)} / ${
        orderDetails[i].patient.gender === R4.PatientGenderKind._male
          ? 'Male'
          : 'Female'
      }`,
      address:
        getAddressOfPatientForOPDAppointment(
          orderDetails[i].patient.address ?? []
        ) ?? '',
      nationality:
        getExtensionCodingOfUrlPatient(
          orderDetails[i].patient.extension,
          'http://wellopathy.com/fhir/india/core/CodeSystem/country'
        ).display ?? '',
      ipdNumber: 0,
      patientIdType: patientIdTypeCoding
        ? patientIdTypeCoding.display ?? ''
        : '',
      patientIdNumber: getIdentifierValueBySystem(
        orderDetails[i].patient.identifier ?? [],
        'address-proof'
      ),
      attendantName: orderDetails[i].attendantDetails
        ? `${
            getFirstNameOfRelatedPerson(orderDetails[i].attendantDetails) ?? ''
          } ${
            getLastNameOfRelatedPerson(orderDetails[i].attendantDetails) ?? ''
          }`
        : '',
      attendantAddressType: attendantCoding
        ? attendantCoding.display ?? ''
        : '',
      attendantId:
        attendantDetailsData &&
        getIdentifierValueBySystem(
          attendantDetailsData.identifier ?? [],
          'related-address-proof'
        )
          ? getIdentifierValueBySystem(
              attendantDetailsData.identifier ?? [],
              'related-address-proof'
            )
          : '',
      dateOfAdmission:
        getIpdAdmitTImeForOrg(orderDetails[i].serviceRequest) ?? '',
      dateOfDischarge: getIpdInTImeWithDate(
        orderDetails[i].serviceRequest.occurrencePeriod?.end ?? ''
      ),
      ipNumber:
        getIdentifierValueBySystem(
          orderDetails[i].serviceRequest.identifier ?? [],
          'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
        ).length > 0
          ? getIdentifierValueBySystem(
              orderDetails[i].serviceRequest.identifier ?? [],
              'http://wellopathy.com/fhir/india/core/Identifier/ipd-id'
            )
          : getIdentifierValueBySystem(
              orderDetails[i].serviceRequest.identifier ?? [],
              'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
            ),
      roomDetails: orderDetails[i].location
        ? getRoomNoForOrg(orderDetails[i].location!) ?? ''
        : '',
      passPortNumber:
        patientIdTypeCoding && patientIdTypeCoding.display === 'Passport'
          ? getIdentifierValueBySystem(
              orderDetails[i].patient.identifier ?? [],
              'address-proof'
            )
          : '',
      passportExpiry:
        patientIdTypeCoding && patientIdTypeCoding.display === 'Passport'
          ? moment(
              getPassportEndDate(
                orderDetails[i].patient.extension,
                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
              )
            ).format('DD-MM-YYYY')
          : '',
      passportStart: moment(
        getPassportStartDate(
          orderDetails[i].patient.extension,
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-passport-validity-period-ext'
        )
      ).format('DD-MM-YYYY'),
      visaDetailsStart:
        getExtensionCodingOfUrlPatient(
          orderDetails[i].patient.extension,
          'http://wellopathy.com/fhir/india/core/CodeSystem/country'
        ).display &&
        getExtensionCodingOfUrlPatient(
          orderDetails[i].patient.extension,
          'http://wellopathy.com/fhir/india/core/CodeSystem/country'
        ).code !== 'IN'
          ? moment(
              getPassportStartDate(
                orderDetails[i].patient.extension,
                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-validity-period-ext'
              )
            ).format('DD-MM-YYYY')
          : '',
      visaDetailsEnd:
        getExtensionCodingOfUrlPatient(
          orderDetails[i].patient.extension,
          'http://wellopathy.com/fhir/india/core/CodeSystem/country'
        ).display &&
        getExtensionCodingOfUrlPatient(
          orderDetails[i].patient.extension,
          'http://wellopathy.com/fhir/india/core/CodeSystem/country'
        ).code !== 'IN'
          ? moment(
              getPassportEndDate(
                orderDetails[i].patient.extension,
                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-validity-period-ext'
              )
            ).format('DD-MM-YYYY')
          : '',
      visaType: getDataFromCoding(
        orderDetails[i].patient.extension ?? [],
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-type-ext'
      ),
      visaCity: getVisaCity(
        orderDetails[i].patient.extension,
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-issued-country-ext'
      ),
      visaNumber:
        getExtensionCodingOfUrlPatient(
          orderDetails[i].patient.extension,
          'http://wellopathy.com/fhir/india/core/CodeSystem/country'
        ).display &&
        getExtensionCodingOfUrlPatient(
          orderDetails[i].patient.extension,
          'http://wellopathy.com/fhir/india/core/CodeSystem/country'
        ).code !== 'IN'
          ? getStringValueExtensionsOfUrl(
              orderDetails[i].patient.extension,
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-visa-number-ext'
            ) ?? ''
          : '',
      patientPhoto: orderDetails[i].patientPhoto
        ? orderDetails[i].patientPhoto.split(',')[1]
        : '',
      unitAddress: getAddress(getCurrentUserUnitDetails().address ?? []) ?? '',
      unitLogo: '',
      orgAdminEmail: getCurrentUserUnitDetails().contact
        ? getTelecomFromContactPoints(
            getCurrentUserUnitDetails().contact![0].telecom ?? [],
            ContactPointSystemKind._email
          ) ?? ''
        : '',
      orgAdminPhone: getCurrentUserUnitDetails().contact
        ? getTelecomFromContactPoints(
            getCurrentUserUnitDetails().contact![0].telecom ?? [],
            ContactPointSystemKind._phone
          ) ?? ''
        : '',
      exporterName: '',
    })
  }
  console.log(unitLogo[0])
  console.log(unitLogo[0].split(',')[1])
  const printData: Print = {
    unitLogo: unitLogo.length > 0 ? unitLogo[0].split(',')[1] : '',
    orgAdminPhone: isOrgAdmin()
      ? getPhoneOfOrgAdmin(getCurrentUserPractitionerDetails())
      : getCurrentUserUnitDetails().contact
      ? getTelecomFromContactPoints(
          getCurrentUserUnitDetails().contact![0].telecom ?? [],
          ContactPointSystemKind._phone
        ) ?? ''
      : '',
    orgAdminEmail: isOrgAdmin()
      ? getEmailOfPractitioner(getCurrentUserPractitionerDetails())
      : getCurrentUserUnitDetails().contact
      ? getTelecomFromContactPoints(
          getCurrentUserUnitDetails().contact![0].telecom ?? [],
          ContactPointSystemKind._email
        ) ?? ''
      : '',
    exporterName: getNameFromHumanName(
      getCurrentUserPractitionerDetails().name ?? []
    ),
    unitAddress: getAddress(getCurrentUserUnitDetails().address ?? []) ?? '',
    formattedData: convertedData,
  }

  return printData
}
