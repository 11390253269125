import { R4 } from '@ahryman40k/ts-fhir-types'
import { IOrganization } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import _, { uniqBy } from 'lodash'
import { LabReferralService } from 'models/labOfferDetail'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerRoleDetailsWithPractitioner,
  getCurrentUserUnitDetails,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import {
  getCarePlanByIdCurrentState,
  getCarePlanOfPatient,
} from 'utils/careplan_utils/careplan_utils'
import {
  getIdentifierValueBySystem,
  getNameFromHumanName,
  getNameOfPatient,
} from 'utils/fhirResourcesHelper'
import { getLoincCodeFromPlanDef } from 'utils/patientHelper/cds_helper'
import { LabTestsAdditionStatus } from './labTestsAdditionState'

const initialState: LabTestsAdditionStatus = {
  addingLabTests: false,
  additionSuccessful: false,
  noResultsAvailable: false,
  errorWhileAdding: false,
}

const labTestReferralAddition = createSlice({
  name: 'labTestReferralAddition',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<LabTestsAdditionStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.addingLabTests = action.payload.addingLabTests
      state.additionSuccessful = action.payload.additionSuccessful
      state.successMessage = action.payload.successMessage
      state.errorReason = action.payload.errorReason
      state.errorWhileAdding = action.payload.errorWhileAdding
    },
    resetMedicationsDetails(
      state,
      action: PayloadAction<LabTestsAdditionStatus>
    ) {
      state.addingLabTests = false
      state.additionSuccessful = false
      state.errorWhileAdding = false
      state.errorReason = undefined
      state.successMessage = undefined
    },
  },
})

export const resetLabReferralCPGState = () => (dispatch: AppDispatch) => {
  const resState: LabTestsAdditionStatus = {
    addingLabTests: false,
    errorWhileAdding: false,
    additionSuccessful: false,
    noResultsAvailable: false,
    errorReason: '',
  }
  dispatch(labTestReferralAddition.actions.resetMedicationsDetails(resState))
}

export const requestLabReferral =
  (
    patient: R4.IPatient,
    services: LabReferralService[],
    preAddedLabTest: R4.IPlanDefinition[],
    encounterId?: string,
    carePlanUrl?: string,
    carePlanId?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: LabTestsAdditionStatus = {
      ...initialState,
    }
    state.addingLabTests = true
    dispatch(labTestReferralAddition.actions.updatedStatus(state))
    try {
      const carePlanOfPatient: R4.ICarePlan | undefined =
        carePlanId !== undefined
          ? await getCarePlanByIdCurrentState(carePlanId)
          : await getCarePlanOfPatient(patient.id!, carePlanUrl ?? '', true)
      if (carePlanOfPatient && encounterId !== undefined) {
        if (carePlanUrl) {
          const finalService: LabReferralService[] = []
          let finalArray: R4.IPlanDefinition[] = []
          if (preAddedLabTest.length > 0) {
            console.log(preAddedLabTest)
            for (let i = 0; i < preAddedLabTest.length; i++) {
              if (preAddedLabTest[i] !== undefined) {
                finalArray.push(preAddedLabTest[i])
              }
            }

            finalArray = _.uniqBy(finalArray, (e) =>
              getIdentifierValueBySystem(e.identifier ?? [], 'http://loinc.org')
            )

            const plan: R4.IPlanDefinition[] = []
            for (let i = 0; i < finalArray.length; i++) {
              if (services.length > 0) {
                const data = services.filter(
                  (d: LabReferralService) =>
                    getIdentifierValueBySystem(
                      d.planDefinition.identifier ?? [],
                      'http://loinc.org'
                    ) ===
                    getIdentifierValueBySystem(
                      finalArray[i].identifier ?? [],
                      'http://loinc.org'
                    )
                )
                if (data.length === 0) {
                  plan.push(finalArray[i])
                }
              } else {
                plan.push(finalArray[i])
              }
            }

            if (services.length > 0) {
              for (let i = 0; i < services.length; i++) {
                if (services[i].healthService.id === undefined) {
                  plan.push(services[i].planDefinition)
                } else {
                  finalService.push(services[i])
                }
              }
            }
            if (plan.length > 0) {
              const bundleObject: R4.IBundle =
                createBundleObjectForLabWithoutReferral(
                  patient,
                  encounterId!,
                  plan,
                  '',
                  carePlanOfPatient.id
                )
              const fhirClient: FHIRApiClient = new FHIRApiClient()
              const responseForBundle =
                await fhirClient.doCreateFHIRTransaction('', bundleObject)

              const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
                R4.RTTI_Bundle.decode(responseForBundle)

              if (finalService.length === 0) {
                if (relatedFhirDecodeRes._tag === 'Right') {
                  if (relatedFhirDecodeRes.right) {
                    state.addingLabTests = false
                    state.additionSuccessful = true
                    state.errorWhileAdding = false
                    state.successMessage = 'Referrals created successfully'
                    state.errorReason = undefined
                    dispatch(
                      labTestReferralAddition.actions.updatedStatus(state)
                    )
                    dispatch(showSuccessAlert(state.successMessage!))

                    //   setTimeout(() => {
                    //     dispatch(getLabRequestsOfAppointment(appointment))
                    //   }, 500)
                  } else {
                    state.addingLabTests = false
                    state.additionSuccessful = false
                    state.errorWhileAdding = true
                    state.successMessage = undefined
                    state.errorReason =
                      'Error while adding lab tests recommendation. Try later'
                    dispatch(showErrorAlert(state.errorReason!))
                    dispatch(
                      labTestReferralAddition.actions.updatedStatus(state)
                    )
                  }
                } else {
                  state.addingLabTests = false
                  state.additionSuccessful = false
                  state.errorWhileAdding = true
                  state.successMessage = undefined
                  state.errorReason = 'User is not subscribed to Care Plan'
                  dispatch(showErrorAlert(state.errorReason!))
                  dispatch(labTestReferralAddition.actions.updatedStatus(state))
                }
              }
            }
          }

          //   for (let i = 0; i < preAddedLabTest.length; i++) {
          //     if (services && services.length > 0) {
          //       for (let j = 0; j < services.length; j++) {
          //         if (services[j].planDefinition.id !== preAddedLabTest[i].id) {
          //           remainLabReferral.push(preAddedLabTest[i])
          //         }
          //       }
          //     }
          //   }
          if (finalService.length > 0) {
            const response = await requestLabOrders(
              patient,
              carePlanOfPatient!.id!,
              encounterId!,
              finalService ?? []
            )
            const finalBundle = response as R4.IBundle

            const bundleObject: R4.IBundle = createBundleObjectForLabReferral(
              patient,
              encounterId!,
              finalService,
              ''
            )

            const fhirClient: FHIRApiClient = new FHIRApiClient()
            const responseForBundle = await fhirClient.doCreateFHIRTransaction(
              '',
              bundleObject
            )

            const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
              R4.RTTI_Bundle.decode(responseForBundle)

            if (relatedFhirDecodeRes._tag === 'Right') {
              if (relatedFhirDecodeRes.right) {
                state.addingLabTests = false
                state.additionSuccessful = true
                state.errorWhileAdding = false
                state.successMessage = 'Referrals created successfully'
                state.errorReason = undefined
                dispatch(labTestReferralAddition.actions.updatedStatus(state))
                dispatch(showSuccessAlert(state.successMessage!))

                // setTimeout(() => {
                //   dispatch(getLabRequestsOfAppointment(appointment))
                // }, 500)
              } else {
                state.addingLabTests = false
                state.additionSuccessful = false
                state.errorWhileAdding = true
                state.successMessage = undefined
                state.errorReason =
                  'Error while adding lab tests recommendation. Try later'
                dispatch(showErrorAlert(state.errorReason!))
                dispatch(labTestReferralAddition.actions.updatedStatus(state))
              }
            } else {
              state.addingLabTests = false
              state.additionSuccessful = false
              state.errorWhileAdding = true
              state.successMessage = undefined
              state.errorReason = 'User is not subscribed to Care Plan'
              dispatch(showErrorAlert(state.errorReason!))
              dispatch(labTestReferralAddition.actions.updatedStatus(state))
            }
          }
        }
      }
    } catch (error) {
      console.error(error)
      const resState: LabTestsAdditionStatus = {
        addingLabTests: false,
        errorWhileAdding: true,
        additionSuccessful: false,
        noResultsAvailable: false,
        errorReason: (error as AxiosError).response?.data,
      }
      dispatch(showErrorAlert(state.errorReason!))
      dispatch(labTestReferralAddition.actions.updatedStatus(resState))
    }
  }

export const requestLabOrders = async (
  patient: R4.IPatient,

  careplanId: string,
  encounterId: string,
  services: LabReferralService[]
): Promise<any> => {
  try {
    const serviceData: any[] = []

    const hvService: string[] = []
    for (let i = 0; i < services.length; i++) {
      hvService.push(services[i].healthService.id ?? '')
    }
    const uniqueArray = _.uniq(hvService)
    for (let i = 0; i < uniqueArray.length; i++) {
      const labTestArray: any[] = []
      for (let j = 0; j < services.length; j++) {
        if (uniqueArray[i] === services[j].healthService.id) {
          labTestArray.push({
            system: 'http://loinc.org',
            code: getLoincCodeFromPlanDef(services[j].planDefinition),
            display: services[j].planDefinition.title ?? '',
          })
        }
      }
      const body = {
        referralServiceId: uniqueArray[i],
        encounterId,
        labTests: labTestArray,
      }
      serviceData.push(body)
    }
    const body: any = {
      patientId: patient.id ?? '',

      labReferralDetails: serviceData,
      unitId: `Organization/${getCurrentUserUnitDetails().id!}`,
    }

    const enRolClient: EnrolCient = new EnrolCient()
    const response = await enRolClient.doCreateEnrolmentFlowRequest(
      'referral/lab-referral',
      body
    )

    return response
  } catch (error) {
    console.error('--------error in referring lab tests---------')
    console.error(error)
  }
  return undefined
}

export function createBundleObjectForLabReferral(
  appointment: R4.IPatient,
  encounter: string,
  labReferral: LabReferralService[],
  addInformation?: string
): R4.IBundle {
  const allergyDetailsFinal: R4.IServiceRequest[] = []
  const currentPract = getCurrentUserPractitionerRoleDetailsWithPractitioner()

  const bundleEntries: R4.IBundle_Entry[] = []
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }
  const encounterRef: R4.IReference = {
    reference: `Encounter/${encounter}`,
    type: 'Encounter',
  }

  const hvService: R4.IHealthcareService[] = []
  for (let i = 0; i < labReferral.length; i++) {
    hvService.push(labReferral[i].healthService ?? '')
  }
  const uniqueArray = uniqBy(hvService, (obj) => obj.id)
  let providerDetails: R4.IReference | undefined
  for (let k = 0; k < uniqueArray.length; k++) {
    const details: any[] = []
    const planDefDetails: string[] = []
    for (let i = 0; i < labReferral.length; i++) {
      if (uniqueArray[k].id === labReferral[i].healthService.id) {
        if (labReferral[i].healthService.providedBy) {
          providerDetails = labReferral[i].healthService.providedBy
        }
        details.push({
          coding: [
            {
              system: 'http://loinc.org',
              code: getLoincCodeFromPlanDef(labReferral[i].planDefinition),
              display: labReferral[i].planDefinition.title ?? '',
            },
          ],
        })
        planDefDetails.push(labReferral[i].planDefinition.title ?? '')
      }
    }

    const allergyDetails: R4.IServiceRequest = {
      resourceType: 'ServiceRequest',
      subject: {
        display: getNameOfPatient(appointment),
        reference: `${appointment.resourceType}/${appointment.id}`,
        id: appointment.id,
        type: appointment.resourceType,
      },

      asNeededBoolean: true,
      category: uniqueArray[k].category?.map((e) => ({
        coding: e.coding?.map((c) => ({
          ...c,
          display: 'Laboratory procedure',
        })),
      })),
      code: uniqueArray[k].type?.[0],
      performer: providerDetails !== undefined ? [providerDetails] : [],
      intent: 'proposal',
      status: 'active',
      encounter: encounterRef,
      orderDetail: details,
      authoredOn: new Date().toISOString(),
      requester: {
        display: getNameFromHumanName(
          currentPract?.practitionerObject?.name ?? []
        ),
        id: currentPract?.roleObject.id,
        reference: `${currentPract?.roleObject.resourceType}/${currentPract?.roleObject.id}`,
        type: currentPract?.roleObject.resourceType,
      },
      patientInstruction: planDefDetails.join(', '),
    }
    const newBundleEntry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: allergyDetails.resourceType,
      },
      resource: allergyDetails,
    }
    bundleEntries.push(newBundleEntry)
  }
  requestBundle.entry?.push(...bundleEntries)
  return requestBundle
}

export function createBundleObjectForLabWithoutReferral(
  appointment: R4.IPatient,
  encounter: string,
  labReferral: R4.IPlanDefinition[],
  addInformation?: string,
  carePlanId?: string
): R4.IBundle {
  const currentPract = getCurrentUserPractitionerRoleDetailsWithPractitioner()
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }
  const encounterRef: R4.IReference = {
    reference: `Encounter/${encounter}`,
    type: 'Encounter',
  }

  const finalService: R4.IServiceRequest[] = []
  const details: any[] = []
  const planDefDetails: string[] = []

  for (let i = 0; i < labReferral.length; i++) {
    details.push({
      coding: [
        {
          system: 'http://loinc.org',
          code: getLoincCodeFromPlanDef(labReferral[i]),
          display: labReferral[i].title ?? '',
        },
      ],
    })
    planDefDetails.push(labReferral[i].title ?? '')
  }

  let providerDetails: R4.IOrganization | undefined

  const allergyDetails: R4.IServiceRequest = {
    resourceType: 'ServiceRequest',
    subject: {
      display: getNameOfPatient(appointment),
      reference: `${appointment.resourceType}/${appointment.id}`,
      id: appointment.id,
      type: appointment.resourceType,
    },
    contained: providerDetails === undefined ? [] : [providerDetails],
    asNeededBoolean: true,

    performer: [getCurrentUserUnitReference()!],
    category: [
      {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '108252007',
            display: 'Laboratory procedure',
          },
        ],
      },
    ],
    code: {
      coding: [
        {
          system: 'http://snomed.info/sct',
          code: '266753000',
          display: 'Laboratory Tests',
        },
      ],
    },

    intent: 'proposal',
    status: 'active',
    encounter: encounterRef,
    orderDetail: details,
    authoredOn: new Date().toISOString(),
    requester: {
      display: getNameFromHumanName(
        currentPract?.practitionerObject?.name ?? []
      ),
      id: currentPract?.roleObject.id,
      reference: `${currentPract?.roleObject.resourceType}/${currentPract?.roleObject.id}`,
      type: currentPract?.roleObject.resourceType,
    },
    patientInstruction: planDefDetails.join(', '),
  }
  if (carePlanId) {
    allergyDetails.basedOn = [
      {
        reference: `CarePlan/${carePlanId}`,
        type: 'CarePlan',
      },
    ]
  }
  finalService.push(allergyDetails)
  const bundleEntries: R4.IBundle_Entry[] = finalService.map((e) => {
    const newBundleEntry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: e.resourceType,
      },
      resource: e,
    }
    return newBundleEntry
  })
  requestBundle.entry?.push(...bundleEntries)
  return requestBundle
}

export default labTestReferralAddition.reducer
