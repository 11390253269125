import MaterialTable, { Column, MTableToolbar } from '@material-table/core'
import {
  Box,
  Button,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme,
  IconButton,
  withStyles,
  Switch,
} from '@material-ui/core'
import {
  kBackgroundDefault,
  kDialogueBackground,
  kPrimaryLight,
} from 'configs/styles/muiThemes'
import { Info } from '@material-ui/icons'
import { cons } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import _ from 'lodash'
import { CPGForTable } from 'models/administration/cpgDetailsForTable'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isPremiumOffer } from 'utils/admisnistration/cpg/cpgListHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import OverflowTwoLineTypography from 'views/components/consultation/plan/ayurveda/overflowTwoLineTypography'
import OverflowTypography from 'views/components/consultation/plan/ayurveda/overflowTypography'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import {
  CareTeamOfCarePlanPopUp,
  CareTeamOfCarePlanPopUpProps,
} from '../crossFunctionalCare/careTeam/carePlanCareTeam/careTeamOfCarePlanPopUp'
import { SubscriptionOfferingsDetails } from './cpSubscriptionDetails'

const useStyles = makeStyles({
  table: {
    padding: '0px',
    margin: '0px',

    '& .MuiTableCell-root': {
      border: '2px solid rgba(0, 0, 0, 0.12)',
      padding: '2px',
      margin: '0px',
    },

    '& .MuiTableRow': {
      root: {
        '&:hover': {
          backgroundColor: 'rgba(33, 150, 243, 0.5)',
        },
      },
    },
  },
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 4,
      paddingRight: 0,
    },
    '& .MuiTablePagination-root': {
      backGroundColor: 'red',
    },
  },
})

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: '#9E9DDC',
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch)

interface CPGListTableProps {
  medicationsList: CPGForTable[]
  actionComponent?: React.ReactNode
  onOptForOfferClick?: (medication: CPGForTable) => void
  onRemoveFromOfferClick?: (medication: CPGForTable) => void
  onActionClick?: (medication: CPGForTable) => void
  showHover?: boolean
}

export const CPGListTable: React.FC<CPGListTableProps> = ({
  medicationsList,
  actionComponent,
  onOptForOfferClick,
  onRemoveFromOfferClick,
  onActionClick,
  showHover,
}: CPGListTableProps) => {
  const ref = React.useRef<null | HTMLDivElement>(null)

  const { height, width } = useWindowDimensions()
  const classes = useStyles()

  const [selectedRowId, setSelectedRowId] = React.useState('')
  const [selectedRow, setSelectedRow] = React.useState(false)

  const [showOfferingDetails, setShowOfferingDetails] = useState<{
    open: boolean
    cpg?: CPGForTable
  }>({
    open: false,
  })

  const columns: Column<CPGForTable>[] = [
    {
      title: (
        <Box minWidth='150px' minHeight='12px' maxHeight='12px' pl='24px'>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Care Plan
          </Typography>
        </Box>
      ),
      cellStyle: {
        paddingLeft: '10px',
        paddingRight: '0px',
        paddingBottom: '0px',
        paddingTop: '0px',
      },
      width: '30%',
      field: 'cpgName',
      customSort: (a: CPGForTable, b: CPGForTable) =>
        a.cpgName > b.cpgName ? 1 : b.cpgName > a.cpgName ? -1 : 0,

      hiddenByColumnsButton: false,
      //   defaultSort: 'asc',
      render: (rowData: CPGForTable) => (
        <Box display='flex' alignItems='center'>
          <img
            src={
              isPremiumOffer(rowData)
                ? `${process.env.PUBLIC_URL}/icons/icon_cps_premium.png`
                : `${process.env.PUBLIC_URL}/icons/icon_cps.ico`
            }
            alt={`img_${_.snakeCase(rowData.cpgName)}`}
            height='20px'
            style={{
              marginRight: '10px',
            }}
          />
          <OverflowTypography
            text={rowData.cpgName ?? ''}
            typographyProps={{
              variant: 'subtitle2',
              noWrap: true,
            }}
          />
        </Box>
      ),
      filtering: false,
    },

    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Description
          </Typography>
        </Box>
      ),
      cellStyle: {
        paddingLeft: '16px',
        paddingRight: '0px',
        paddingBottom: '0px',
        paddingTop: '0px',
      },
      field: 'cpgDescription',
      customSort: (a: CPGForTable, b: CPGForTable) =>
        (a.cpgDescription ?? '') > (b.cpgDescription ?? '')
          ? 1
          : (b.cpgDescription ?? '') > (a.cpgDescription ?? '')
          ? -1
          : 0,

      hiddenByColumnsButton: false,
      filtering: false,
      width: '50%',
      render: (rowData: CPGForTable) => (
        <Box display='flex' alignItems='center'>
          <OverflowTwoLineTypography
            text={rowData.cpgDescription ?? ''}
            typographyProps={{
              variant: 'subtitle2',
              noWrap: false,
            }}
          />
        </Box>
      ),
    },
  ]

  if (onOptForOfferClick || onRemoveFromOfferClick) {
    columns.push({
      title: (
        <Box display='flex' alignItems='center' width='100%'>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Subscription
          </Typography>
        </Box>
      ),
      cellStyle: {
        paddingLeft: '8px',
        paddingRight: '0px',
        paddingBottom: '0px',
        paddingTop: '0px',
      },
      width: '10%',
      field: 'cpgGoal',
      customSort: (a: CPGForTable, b: CPGForTable) =>
        (a.cpgGoal ?? '') > (b.cpgGoal ?? '')
          ? 1
          : (b.cpgGoal ?? '') > (a.cpgGoal ?? '')
          ? -1
          : 0,

      hiddenByColumnsButton: true,
      sorting: false,
      //   defaultSort: 'asc',
      render: (row: CPGForTable) => (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          paddingRight={3}
        >
          {!row.isOffering && onOptForOfferClick && (
            <AntSwitch
              onChange={(event) => {
                event.stopPropagation()
                onOptForOfferClick(row)
              }}
              onKeyDown={(e) => {
                console.log(e)
                if (e.code === 'Enter') {
                  e.stopPropagation()
                  onOptForOfferClick(row)
                }
              }}
              name=''
              checked={false}
            />
          )}
          {/* {!row.isOffering && onOptForOfferClick && (
            <Button
              variant='outlined'
              color='primary'
              style={{
                minWidth: '90px',
              }}
              tabIndex={0}
              size='small'
              onClick={(e) => {
                e.stopPropagation()

                onOptForOfferClick(row)
              }}
            >
              Subscribe
            </Button>
          )} */}

          {row.isOffering && onRemoveFromOfferClick && (
            <AntSwitch
              onChange={(event) => {
                event.stopPropagation()
                onRemoveFromOfferClick(row)
              }}
              onKeyDown={(e) => {
                console.log(e)
                if (e.code === 'Enter') {
                  e.stopPropagation()
                  onRemoveFromOfferClick(row)
                }
              }}
              name=''
              checked={true}
            />
          )}
        </Box>
      ),
      filtering: false,
    })
  }

  if (showHover) {
    columns.push({
      title: <Box />,
      field: 'cpgGoal',
      width: '8%',
      cellStyle: {
        paddingLeft: '0px',
        paddingRight: '0px',
        paddingBottom: '0px',
        paddingTop: '0px',
      },
      customSort: (a: CPGForTable, b: CPGForTable) =>
        (a.cpgGoal ?? '') > (b.cpgGoal ?? '')
          ? 1
          : (b.cpgGoal ?? '') > (a.cpgGoal ?? '')
          ? -1
          : 0,
      hiddenByColumnsButton: false,
      //   defaultSort: 'asc',
      render: (row: CPGForTable) => (
        <Box display='flex' flexDirection='row'>
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='center'
            marginRight={2}
          >
            <IconButton
              onClick={() => {}}
              color='primary'
              onMouseEnter={(e) => {
                setShowOfferingDetails({
                  open: true,
                  cpg: row,
                })
              }}
              onMouseOut={(e) => {
                setShowOfferingDetails({
                  open: false,
                  cpg: undefined,
                })
              }}
              // onMouseEnter={
              //   () => alert('15')
              //   //   setShowOfferingDetails({
              //   //     open: true,
              //   //     row,
              //   //   })
              // }
            >
              <Info />
            </IconButton>
          </Box>
        </Box>
      ),
      filtering: false,
    })
  }

  return (
    <Box id='list38' width='100%' height='100%'>
      <MaterialTable
        components={{
          Toolbar: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                color: '#333333',
                height: '0px',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),

          Container: (props) => <Paper {...props} elevation={0} />,

          Pagination: (props) => (
            <div
              className={classes.toolbarWrapper}
              style={{
                backgroundColor: '#ececec',
                display: 'flex',
                justifyContent: 'left',
                padding: '0px',
                height: '60px',
                color: '#333333',
              }}
            >
              <TablePagination
                {...props}
                on
                SelectProps={{
                  style: {
                    fontSize: 14,
                    backgroundColor: '#ececec',
                  },
                }}
              />
            </div>
          ),
        }}
        columns={columns}
        data={medicationsList}
        onOrderChange={(orderBy, orderDirection) => {
          const key = orderBy < 1 ? 0 : orderBy
        }}
        onRowClick={(event, rowData) => {
          /* if (rowData && onOrgSelected) {
            onOrgSelected(rowData.orgDetails, rowData.mainOrgId ?? '')
            setSelectedRowId(rowData ? rowData.orgName ?? '' : '')
          } */
        }}
        options={{
          toolbar: false,
          search: false,
          showTitle: false,
          columnResizable: false,
          paginationPosition: 'bottom',
          maxBodyHeight: `${height - 135}px`,
          minBodyHeight: `${height - 135}px`,
          padding: 'dense',
          searchFieldVariant: 'outlined',
          filtering: false,
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [10, 20, 30],
          //   columnsButton: true,
          // maxBodyHeight: `${height - 190}px`,
          //   minBodyHeight: `${height - 260}px`,

          sorting: true,
          thirdSortClick: false,

          headerStyle: {
            backgroundColor: kBackgroundDefault,
            width: 100,
            minHeight: '12px',
            maxHeight: '12px',
            position: 'sticky',
            top: 0,
            zIndex: 100,
            // height: 20,
          },
          selectionProps: (_rowData: any) => ({
            color: 'primary',
          }),
          actionsColumnIndex: -1,
          searchFieldAlignment: 'left',
          rowStyle: (_data: CPGForTable, index: number, _level: number) =>
            index % 2
              ? selectedRow && _data.cpgId === selectedRowId
                ? {
                    backgroundColor: '#9E9DDC',

                    '&:hover': { backgroundColor: 'red' },
                  }
                : {
                    backgroundColor: kDialogueBackground,
                    '&:hover': { backgroundColor: 'red' },
                  }
              : selectedRow && _data.cpgId === selectedRowId
              ? {
                  backgroundColor: '#9E9DDC',

                  '&:hover': { backgroundColor: 'red' },
                }
              : { '&:hover': { backgroundColor: 'red' } },
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
          },
          toolbar: {
            nRowsSelected: '{0} row(s) selected',
          },
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No records to display',
            filterRow: {
              filterTooltip: 'Filter',
            },
          },
        }}
      />
      {showOfferingDetails.open}
      {showOfferingDetails.open && showOfferingDetails.cpg && (
        <SubscriptionOfferingsDetails
          open={showOfferingDetails.open}
          offer={showOfferingDetails.cpg}
          key={showOfferingDetails.cpg?.cpgName ?? ''}
          onClose={() => {
            setShowOfferingDetails({ open: false })
          }}
        />
      )}
    </Box>
  )
}
